export abstract class TrakingBase {
  public abstract initialize(): Promise<boolean>;
  public abstract setUserIdx(user_idx: number): Promise<boolean>;

  public abstract setUserProperty(data: { key: string; value: any }): Promise<boolean>;
  public abstract appInit(data: { user_idx: number; user_device_idx: number; platform: string }): Promise<boolean>;
  public abstract appInstall(data: {
    platform: string;
    install_time: string;
    af_status: string;
    af_message: string;
    deep_link_value: string;
    user_idx: number;
    user_device_idx: number;
  }): Promise<boolean>;
  public abstract appOpen(data: {
    launch_source: string;
    url: string;
    platform: string;
    app_open_pid: string;
    app_open_campaign: string;
    app_open_adset: string;
    user_idx: number;
    user_device_idx: number;
  }): Promise<boolean>;
  public abstract logPageView(data: any): Promise<boolean>;
  public abstract logCompleteOrderTransaction(data: any): Promise<boolean>;
  public abstract logCompleteOrderItem(data: any): Promise<boolean>;
  public abstract logAddToCart(data: any): Promise<boolean>;
  public abstract logWishItem(data: any): Promise<boolean>;

  public abstract logItemImpression(data: any): Promise<boolean>;
  public abstract logItemClick(data: any): Promise<boolean>;
  public abstract logItemView(data: any): Promise<boolean>;

  public abstract logBannerView(data: any): Promise<boolean>;
  public abstract logBannerImpression(data: any): Promise<boolean>;

  public abstract logShopSectionView(data: any): Promise<boolean>;
  public abstract logShopSectionImpression(data: any): Promise<boolean>;

  public abstract logNoticeDialogView(data: any): Promise<boolean>;
  public abstract logNoticeDialogImpression(data: any): Promise<boolean>;

  public abstract logEvent(data: any): Promise<boolean>;
  public abstract login(data: any): Promise<boolean>;

  public abstract logPostImpression(data: any): Promise<boolean>;
  public abstract logPostClick(data: any): Promise<boolean>;
  public abstract logPostView(data: any): Promise<boolean>;

  public abstract logPostLike(data: any): Promise<boolean>;
  public abstract logPostReply(data: any): Promise<boolean>;

  public abstract logSearchItem(data: any): Promise<boolean>;
  public abstract logSearchPost(data: any): Promise<boolean>;

  public abstract logUserCheckIn(data: any): Promise<boolean>;
  public abstract logUserCheckOut(data: any): Promise<boolean>;

  public abstract logFumistImpression(data: any): Promise<boolean>;
  public abstract logFumistView(data: any): Promise<boolean>;
  public abstract logFumistJoin(data: any): Promise<boolean>;
  public abstract logFumistClick(data: any): Promise<boolean>;
}
