<ion-app>
  <div class="center-container">
    <div>
      <div class="center-content">
        <ion-spinner></ion-spinner>
      </div>
    </div>
  </div>

  <ion-router-outlet></ion-router-outlet>

  <ion-modal cssClass="fullscreen" mode="md" [animated]="app.splash.splash_anim" (didDismiss)="onDidDismiss()" [isOpen]="app.splash.show_splash">
    <ng-template>
      <ion-content fullscreen>
        <div class="parent root-bg">
          <div class="child-bottom">
            <div class="center-text child-animation">
              <div class="bg-image"></div>
            </div>
          </div>
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>
</ion-app>
