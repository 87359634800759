import { Apollo } from 'apollo-angular';
import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Platform, LoadingController, ToastController, NavController, ModalController, AlertController, IonRouterOutlet, AlertOptions } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { HttpClient } from '@angular/common/http';
import { throwError, Subject, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Post, User, ConfigService, OrderTransaction, height_options, bottom_size, top_size, Store } from './graphql/models';
import { Location } from '@angular/common';
import { EventManager } from '@angular/platform-browser';

import { DataProviderService } from './graphql/data-provider.service';

import { AppsFlyer } from 'appsflyer-capacitor-plugin';
import { AmplitudeService } from './amplitude.service';
import { ConstantService } from './constants.service';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
// import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { AppUpdateService } from './app-update.service';

import { LoginModalComponent } from '../components/fumi-common/login-modal/login-modal.component';
import { UtilService } from './util.service';
import { Storage } from '@ionic/storage-angular';
import { AppsflyerService } from './appsflyer.service';
import { OnesignalService } from './onesignal.service';
import { FirebaseUserService } from './firebase-user.service';
import { TrackerService } from './tracker/tracker.service';
import { LoginPage } from '../pages/user/login/login.component';
import { CommonModalComponent } from '../components/common-modal/common-modal.component';
import { NoticeService } from './notice.service';
import Portals, { PortalMessage } from '@ionic/portals';
// import { TrackerService } from './tracker/tracker.service';
// import { Filesystem, Plugins } from '@capacitor/core';

export interface BlobFileFormat {
  file: Blob | File;
  filename: string;
  ext: string;
}

const endpoint = environment.api_server_url;
const httpOptions = {
  // headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept-Encoding': 'gzip' }),
};
@Injectable({
  providedIn: 'root'
})
export class AppService {
  public debug_img = '/assets/img/empty.png';
  public default_fumist_banner = environment.default_fumist_banner;
  public no_image = environment.no_img_url;

  public splash = {
    start_time: 0,
    show_splash: true,
    splash_anim: false
  };

  public trackBy = {
    ItemIdx(index: number, el: any): number {
      return el.item_idx;
    },
    PostIdx(index: number, el: any): number {
      return el.post_idx;
    },
    PostTvIdx(index: number, el: any): number {
      return el.post_tv_idx;
    },
    ReplyIdx(index: number, el: any): number {
      return el.reply_idx;
    },
    UserIdx(index: number, el: any): number {
      return el.user_idx;
    },
    BannerIdx(index: number, el: any): number {
      return el.banner_idx;
    },
    NoticeIdx(index: number, el: any): number {
      return el.notice_idx;
    },
    Number(index: number, el: any): number {
      return el;
    },
    ClassRoomRank(index: number, el: any): number {
      return el.current_rank;
    },

    StoreIdx(index: number, el: any): number {
      return el.store_idx;
    },
    CategoryIdx(index: number, el: any): number {
      return el.category_idx;
    },
    ShopSectionIdx(index: number, el: any): number {
      return el.shop_section_idx;
    },
    ItemOptionIdx(index: number, el: any): number {
      return el.item_option_idx;
    },
    ShopSectionGroupIdx(index: number, el: any): number {
      return el.shop_section_group_idx;
    },
    PostResourceIdx(index: number, el: any): number {
      return el.post_resource_idx;
    }
  };

  public segment_data = {
    main_bottom_list: [
      {
        icon: 'home-outline',
        target: 'home',
        text: '홈',
        url: '/app/home'
      },
      {
        icon: 'time-outline',
        target: 'recent',
        text: '최근본',
        url: '/app/recent'
      },
      {
        icon: 'newspaper-outline',
        target: 'style',
        text: '커뮤니티',
        url: '/app/style'
      },
      {
        icon: 'cart-outline',
        target: 'cart',
        text: '장바구니',
        url: '/app/cart'
      },
      {
        icon: 'person-outline',
        target: 'my',
        text: '내정보',
        url: '/app/my'
      }
    ],
    home_segement_list: [
      {
        target: 'recommend',
        text: '추천',
        url: `app/home/recommend`
      },
      // {
      //   target: 'live',
      //   text: '라이브',
      //   url: `app/home/live`
      // },
      {
        target: 'hot-sell',
        text: '급상승',
        url: `app/home/hot-sell`
      }
      // {
      //   target: 'home-special',
      //   text: '🐯 설선물',
      //   url: `app/home/home-special`
      // }
    ],
    fumist_segement_list: [
      {
        target: 'fumi',
        text: '푸미'
      },
      {
        target: 'chungoong',
        text: '천궁'
      }
    ],
    recent_segement_list: [
      {
        target: 'recent',
        text: '최근 본 상품'
      },
      {
        target: 'wish',
        text: '찜한상품'
      }
    ]
  };

  public app_version = '!!app_version!!';
  public snapshot = '!!snapshot!!';

  public errorCount = {
    count: 0
  };

  public databasePost: { [key: number]: Post } = {};
  public databaseLike: { [key: number]: boolean } = {};
  public databaseFollow: { [key: number]: boolean } = {};
  public databaseCartCount: number = 0;

  // public user: any = {};

  public pendingOrder;

  public routerOutlet: IonRouterOutlet;

  public component_property = {
    ion_refresher: {
      pull_factor: 0.8,
      pull_min: 100,
      pull_max: 350,
      snapback_duration: '800ms'
    }
  };

  public banner = {
    home: {
      list: []
    },
    shop: {
      list: []
    },
    best: {
      list: []
    },
    hotdeal: {
      list: []
    },
    community: {
      list: []
    },
    live: {
      list: []
    }
  };
  public banner_length = {
    home: 0,
    shop: 0,
    best: 0,
    hotdeal: 0,
    live: 0
  };

  public show_event = true;

  private sizeSubject = new BehaviorSubject({});

  private homeScrollSubject = new BehaviorSubject(0);
  private homeScrollToSubject = new BehaviorSubject(0);
  private aiRefreshSubject = new BehaviorSubject(null);

  public is_production = true;

  public size = {
    width: 1440,
    height: 2560,
    header_height: 600,
    max_width: 800,

    full_image_w_1: 800,
    full_image_w_1_1: 700,
    full_image_w_1_1_px: 700,
    full_image_w_2: 400,
    full_image_w_2_1: 350,
    full_image_w_2_1_px: 350,
    full_image_w_3: 300,
    full_image_w_4: 200,
    full_image_w_6: 100,

    image_tail: '800x800',
    image_tail_2: '400x400',
    image_tail_2_1: '350x350',
    image_tail_4: '200x200',
    image_tail_w: '800x',
    image_slide: '500x',
    image_post_list: '400x',
    status_bar_margin: 0,

    banner_width: 1080,
    banner_height: 420,
    banner_ratio: 0.388,
    banner_shop_ratio: 0.481,
    // banner_shop_ratio: 1.157,

    shop_image_main_normal: 400,
    shop_image_main_normal_px: 160,
    image_ratio: 1,
    shop_image_page_normal_height: ((800 - 32 - 12) / 2) * 1,
    shop_image_hotdeal_height: (800 - 32) * 1,
    shop_recommend_height: 400,

    shop_image_main_user: 400,
    shop_image_main_user_px: 320,
    shop_image_page_normal: 500,

    featured_content_img_ratio: 0.5626,
    event_banner_img_ratio: 0.4548,
    home_best_post_img_ratio: 1
  };

  public delivery_company_hash = {
    CJ대한통운: 'kr.cjlogistics',
    CU편의점택배: 'kr.cupost',
    우체국택배: 'kr.epost',
    한진택배: 'kr.hanjin',
    로젠택배: 'kr.logen',
    롯데택배: 'kr.lotte',
    GOP당일택배: 'kr.gop'
  };

  hideTabBarPages = [
    'item-detail',
    'reply',
    'pay-order',
    'pay-callback',
    'profile-edit',
    'setting',
    'upload',
    'upload-content',
    'address-list',
    'address-add',
    'item-detail',
    'top7-vote',
    'reply-detail',
    'reply-change',
    'shop-feed',
    'app-version',
    'order-list',
    'alarm',
    'cart',
    'policy',
    'review-upload',
    'review-edit',
    'point-list',
    'pay-card-add',
    'user-info',
    'featured-content-detail',
    'coupon',
    'coupon-add',
    'brand-list',
    'live-tv-detail'
  ];

  routeParamPages: string[] = [];

  homeUrl = '/main/home/home';
  page_stack = {
    home: [],
    shop: [],
    style: [],
    'fumi-tv': [],
    my: [],
    recent: [],
    cart: [],
    live: []
  };

  public assist_word = ['도움이돼요🙏', '모델같아요👏', '센스만점💯', '아름다워요🌸', '반가워요👋', '감사합니다💙', '소통하며지내요👭', '좋은하루되세요🍀'];

  public height_options = height_options;
  public top_size = top_size;
  public bottom_size = bottom_size;

  public upload_list = [];

  public show_download_bar = false;
  public recent_image_url: string;

  private tabClickSubject = new BehaviorSubject(null);

  constructor(
    public db: DataProviderService,
    public route: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router,
    private eventManager: EventManager,
    private navCtrl: NavController,
    private http: HttpClient,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private modalController: ModalController,
    private amplitude: AmplitudeService,
    public constant: ConstantService,
    private cookieService: CookieService,
    public alertController: AlertController,

    private utils: UtilService,
    private storage: Storage,
    private platform: Platform,
    private appsflyer_service: AppsflyerService,
    private onesignal_service: OnesignalService,
    private user_service: FirebaseUserService,
    private notice_service: NoticeService,
    private tracker: TrackerService,
    private update: AppUpdateService
  ) {
    this.storage.create();
    this.changeWindowSize();
    this.is_production = environment.production;
    if (!this.is_production) {
      this.debug_img = '/assets/img/empty_debug.png';
    }
  }

  async init() {
    if (!environment.production) {
      console.log('[!production] Core service init');
    }

    this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
    this.changeWindowSize();

    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        if (!environment.production) {
          console.log('[!production]', e);
        }

        if (this.splash.show_splash) {
          const now = new Date().getTime();
          let already = now - this.splash.start_time;
          let delay = 600 - already;
          if (delay < 0) {
            delay = 0;
          }

          setTimeout(() => {
            this.splash.splash_anim = true;
            this.splash.show_splash = false;
          }, delay);
        }

        //웹으로 최초진입
        if (e.id == 1) {
          if (Capacitor.getPlatform() == 'web') {
            if (this.haveMarketingUrl(e.url)) {
              this.logAppOpen('web', e.url);
            }
          }
        }

        this.tracker.logPageView(e.url);
        this.user_service.updateCurrentUrl(e.url);
      }
    });

    //유저 정보 변경
    this.user_service.getUserDataChangeListner().subscribe(async (data) => {
      if (data) {
        //원시그널에 정보 갱신
        this.onesignal_service.setExternalUserId(`${data.user_idx}`);
      }
    });

    //앱스 플라이어 이벤트 처리
    this.appsflyer_service.getPushAlarmObjserver().subscribe(async (data) => {
      if (data) {
        await this.processAppsflyerEvent(data);
      }
    });

    //원시그널 푸시 이벤트 처리
    this.onesignal_service.getPushAlarmObjserver().subscribe(async (data) => {
      if (data) {
        await this.processOnesignalEvent(data);
      }
    });

    //new portal 업데이트 체크
    this.update.getNewPortalListener().subscribe((observer) => {
      if (observer == true) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    });

    // 앱일 경우 앱 초기화
    if (Capacitor.getPlatform() !== 'web') {
      // 종료페이지 지정하기
      this.platform.backButton.subscribeWithPriority(-1, async () => {
        if (this.router.url.startsWith(`/temp/login`)) {
          const check = await this.getCurrentUser();
          if (check.login_type == 'kakao' || check.login_type == 'apple') {
            this.justGo('/', {}, false);
          } else {
            App.exitApp();
          }
        } else {
          if (this.router.url.startsWith(`/app/home`)) {
            this.clickTab('back');
          } else {
            if (!this.routerOutlet.canGoBack()) {
              this.justGo('/', {}, false);
            }
          }
        }
      });
    }

    //user service 초기화
    this.user_service.userServiceInit();

    //one signal 초기화
    this.onesignal_service.onesignalInit();

    //appsflyer 초기화
    this.appsflyer_service.appsflyerInit();

    //tracker 서비스 초기화
    this.tracker.initialize();
  }

  //---------리펙토링-------//

  getTabClickListener() {
    return this.tabClickSubject.asObservable();
  }

  clickTab(target: string) {
    this.tabClickSubject.next(target);
  }

  getUserDataChangeListner() {
    return this.user_service.getUserDataChangeListner();
  }
  async getUserIdx() {
    return await this.user_service.getUserIdx();
  }

  async getCurrentUser(refresh: boolean = false) {
    if (refresh) {
      return await this.user_service.refreshUserData();
    } else {
      return await this.user_service.getCurrentUser();
    }
  }

  async getDeviceIdx() {
    return await this.user_service.getUserDeviceIdx();
  }

  async loginSocial(login_type: string, social_access_token: string) {
    return await this.user_service.loginSocial(login_type, social_access_token);
  }

  async logout() {
    return await this.user_service.logout();
  }

  //임시 사용자인지 체크
  async isLogin() {
    const check = await this.getCurrentUser();
    return check.login_type == 'kakao' || check.login_type == 'apple';
  }

  async goUrlAndLogAppOpen(url: string, pid: string) {
    this.goUrl(url);
    this.logAppOpen(pid, url);
  }

  async processAppsflyerEvent(res: any) {
    if ('app_open' == res.event) {
      const url = this.extractUrl(res);

      this.logAppOpen('appsflyer', url);
      // alert('app_open:' + url);
      console.log('app_open:' + url);
      this.goUrl(url);
    } else if ('app_open_url' == res.event) {
      const url = res.data;

      console.log('app_open_url:' + url);
      this.logAppOpen('open_url', url);
      this.goUrl(url);
      // alert('open_url:' + url);
    } else if ('app_install' == res.event) {
      // alert('res:' + JSON.stringify(res));
      // const url = this.extractUrl(res);

      console.log('app_install_data:' + JSON.stringify(res.data));
      let url = '/';
      if (res.data && res.data.deep_link_value) {
        url = res.data.deep_link_value;
      }

      console.log('app_install_url:' + url);
      // alert('app_install:' + url);
      this.logAppInstall(res.data);
      this.logAppOpen('appsflyer', url);

      if (url != '/') {
        let onelink_idx = 0;
        try {
          const temp = this.decodeDeeplink(url);
          if (temp && temp.query && temp.query.onelink_idx) {
            onelink_idx = temp.query.onelink_idx;
          }
        } catch (error) {}

        const check_key = `app_install_${moment().format('YYYY_MM_DD')}_${onelink_idx}`;
        const have_data = await this.getLocalData(check_key);

        //여러번 호출되기 때문에 한번만 이동시킨다
        if (!have_data) {
          await this.setLocalData(check_key, true);
          this.goUrl(url);
        }
      }
    }
  }

  async logAppInstall(data: any) {
    let marketing: any = {};
    if (data.deep_link_value) {
      const result = this.decodeDeeplink(data.deep_link_value);
      if (result && result.query && result.query.app_open_pid) {
        marketing = {
          app_open_pid: result.query.app_open_pid,
          app_open_campaign: result.query.app_open_campaign,
          app_open_adset: result.query.app_open_adset,
          regist_datetime: new Date(),
          onelink_idx: result.query.onelink_idx ? Number(result.query.onelink_idx) : null
        };
      }
    }

    await this.tracker.appInstall({
      ...data,
      ...marketing,
      deep_link_value: data.deep_link_value ? data.deep_link_value : 'none'
    });
    return true;
  }

  async logAppOpen(launch_source: string, url: any) {
    const result = this.decodeDeeplink(url);
    if (result) {
      //마케팅 소스 저장
      if (result && result.query && result.query.onelink_idx) {
        const marketing = {
          app_open_pid: result.query.app_open_pid,
          app_open_campaign: result.query.app_open_campaign,
          app_open_adset: result.query.app_open_adset,
          regist_datetime: new Date(),
          onelink_idx: result.query.onelink_idx ? Number(result.query.onelink_idx) : null
        };
        await this.setMarketingData(marketing);
      }

      await this.tracker.appOpen({
        url,
        launch_source,
        app_open_pid: result.query.app_open_pid,
        app_open_campaign: result.query.app_open_campaign,
        app_open_adset: result.query.app_open_adset,
        app_open_datetime: new Date().toISOString(),
        ref_idx: result.query.ref_idx,
        ref_table: result.query.ref_table,
        onelink_idx: result.query.onelink_idx
      });
    }
    return true;
  }

  haveMarketingUrl(url) {
    const result = this.decodeDeeplink(url);
    if (result && result.query && result.query.app_open_pid) {
      return true;
    } else {
      return false;
    }
  }

  goUrl(url: string) {
    if (url) {
      const result = this.decodeDeeplink(url);
      this.justGo(result.url, result.query);
    }
  }

  //최근 캠페인 등록
  async setMarketingData(data: { app_open_pid: string; app_open_campaign: string; app_open_adset: string; regist_datetime: Date; onelink_idx: number }) {
    await this.storage.set('campaign', data);
  }

  //최근 캠페인 정보 가져오기
  async getMarketingData(
    route: ActivatedRoute
  ): Promise<{ arrival_source: string; app_open_pid: string; app_open_campaign: string; app_open_adset: string; onelink_idx: number; ref_idx: number; ref_table: string }> {
    const all_query = this.getAllQueryParam(route);

    let arrival_source = all_query.arrival_source;
    let ref_idx = all_query.ref_idx ? Number(all_query.ref_idx) : null;
    let ref_table = all_query.ref_table;
    let onelink_idx = all_query.onelink_idx ? Number(all_query.onelink_idx) : null;

    let router_value = {
      arrival_source,
      ref_idx,
      ref_table,
      app_open_pid: all_query.app_open_pid,
      app_open_campaign: all_query.app_open_campaign,
      app_open_adset: all_query.app_open_adset,
      onelink_idx
    };

    if (router_value.onelink_idx) {
      return router_value;
    } else {
      //저장된 값 가져오기
      const data = await this.storage.get('campaign');
      if (!data) {
        return router_value;
      } else {
        if (new Date().getTime() - data.regist_datetime.getTime() > 1000 * 60 * 60 * 24 * 3) {
          await this.storage.remove('campaign');
          return router_value;
        } else {
          return {
            ...data,
            arrival_source,
            ref_idx,
            ref_table
          };
        }
      }
    }
  }

  safeDecode(str: string) {
    if (str && this.isEncoded(str)) {
      return decodeURIComponent(str);
    } else {
      return str;
    }
  }
  isEncoded(str: string) {
    return typeof str == 'string' && decodeURIComponent(str) !== str;
  }

  //url 추출하기
  extractUrl(res: any) {
    if (res && res.data) {
      if (res.data.deepLink && res.data.deepLink.deep_link_value) {
        const deepLinkValue = res.data.deepLink.deep_link_value;
        if (deepLinkValue) {
          return deepLinkValue;
        }
      } else {
        if (res.data.url) {
          let param: any = this.extractQueryParam(res.data.url);
          if (param && param.deep_link_value) {
            const deep_link_value = decodeURIComponent(param && param.deep_link_value);
            return decodeURIComponent(deep_link_value);
          }
        }
      }
    }
    return null;
  }

  async processOnesignalEvent(data: any) {
    // alert('ONESIGNAL:' + JSON.stringify(data));
    // console.log('')
    if (data.launchURL) {
      this.goUrl(data.launchURL);
      this.logAppOpen('onesignal', data.launchURL);
    }
  }

  //---------리펙토링-------//

  getSizeChangeObjserver(): Observable<any> {
    return this.sizeSubject.asObservable();
  }

  public async goForward(url) {
    return await this.navCtrl.navigateForward(url);
  }

  private onResize(event: UIEvent) {
    this.changeWindowSize();
  }

  public getParam(route: ActivatedRoute, key: string) {
    return route.snapshot.params[key];
  }

  public getQueryParam(route: ActivatedRoute, key: string) {
    return route.snapshot.queryParamMap.get(key);
  }

  public getAllQueryParam(route: ActivatedRoute) {
    const temp: any = route.snapshot.queryParamMap;
    if (temp.params) {
      return JSON.parse(JSON.stringify(temp.params));
    } else {
      return {};
    }
  }

  public updateQueryParam(route: ActivatedRoute, data: any, merge = true) {
    if (merge) {
      const temp = this.getAllQueryParam(route);
      this.router.navigate([], {
        queryParams: {
          ...temp,
          ...data
        }
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          ...data
        }
      });
    }
  }

  public async getAppVersion() {
    try {
      const portal = `${this.getFullHost()}`.replace('.fumi.co.kr', '').replace('https://', '');
      const platform = Capacitor.getPlatform();
      const build_time = environment.package_app_version;
      const api_server_url = `${environment.api_server_url}`.replace('.fumi.co.kr', '').replace('https://', '');
      const common = {
        portal,
        platform,
        build_time,
        api_server_url
      };
      if (this.isApp()) {
        const info = await App.getInfo();
        const native_app = await this.update.getAppVersion();
        return {
          app_name: info.name,
          package_name: info.id,
          version_code: info.version,
          version_number: info.build,
          ...common,
          ...native_app
        };
      } else {
        return {
          ...common
        };
      }
    } catch (error) {
      // alert('cannot get native version version')
      return {
        app_name: 'need_update',
        package_name: 'need_update',
        version_code: 'need_update',
        version_number: 'need_update'
      };
    }
  }

  //윈도우 사이즈 변경 이벤트
  public changeWindowSize() {
    this.size.width = window.innerWidth;
    this.size.height = window.innerHeight;
    this.size.max_width = this.size.width;
    if (this.size.width > 800) {
      this.size.max_width = 800;
    }
    this.size.header_height = 56; // this.size.height * 0.05;
    this.size.full_image_w_1 = (Math.floor(this.size.max_width / 100) + 1) * 100 + 100;
    this.size.full_image_w_1_1 = (Math.floor((this.size.max_width * 0.7) / 100) + 1) * 100;
    this.size.full_image_w_1_1_px = this.size.max_width * 0.7;

    this.size.full_image_w_2 = (Math.floor(this.size.max_width / 2 / 100) + 1) * 100;
    this.size.full_image_w_2_1 = (Math.floor((this.size.max_width * 0.44) / 100) + 1) * 100;
    this.size.shop_image_page_normal = (Math.floor(this.size.max_width / 100) + 1) * 100 + 100;

    this.size.full_image_w_2_1_px = this.size.max_width * 0.44;

    this.size.full_image_w_3 = (Math.floor(this.size.max_width / 3 / 100) + 1) * 100;

    this.size.full_image_w_4 = (Math.floor(this.size.max_width / 4 / 100) + 1) * 100;
    this.size.full_image_w_6 = (Math.floor(this.size.max_width / 6 / 50) + 1) * 50;
    this.size.image_tail = `${this.size.full_image_w_1}`;
    this.size.image_tail_2 = `${this.size.full_image_w_2}`;
    this.size.image_tail_2_1 = `${this.size.full_image_w_2_1}`;
    this.size.image_tail_4 = `${this.size.full_image_w_4}x${this.size.full_image_w_4}`;

    this.size.image_tail_w = `${this.size.full_image_w_1}x`;
    if (this.size.full_image_w_1 > 800) {
      this.size.image_slide = '800x';
    }
    /**
     * 배너관련 임시
     */
    this.size.banner_width = this.size.full_image_w_1;
    this.size.banner_height = this.size.banner_width * this.size.banner_ratio;

    this.size.status_bar_margin = 0;
    // if (Capacitor.getPlatform() == 'ios') {
    //   this.size.status_bar_margin = 100;
    // }

    this.size.shop_image_page_normal_height = ((this.size.max_width - 32 - 8) / 2) * this.size.image_ratio;
    this.size.shop_recommend_height = (this.size.max_width - 32) * this.size.image_ratio;
    this.size.shop_image_hotdeal_height = (this.size.max_width - 32) * this.size.image_ratio;

    this.sizeSubject.next(this.size);
  }

  public async is_on_apple_review() {
    // if (Capacitor.getPlatform() == 'ios') {
    //   const temp: ConfigService = await this.db.get_config_service('is_on_apple_review');
    //   return temp.key_value == 'yes';
    // }
    return false;
  }

  public async refreshBanner(type) {
    // if (await this.is_on_apple_review()) {
    //   this.banner.home.list = [];
    //   this.banner.shop.list = [];
    // } else {
    //   const temp = await this.db.select_banner_list_by_type({ skip: 0, take: 100 }, type);
    //   this.banner[type].list = temp.rows;
    //   this.banner_length[type] = temp.rows.length;
    // }
  }

  public reorderList(array: Array<any>, indexA: number, indexB: number) {
    if (indexB >= array.length) {
      indexB = array.length - 1;
    }
    let tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
    return array;
  }

  public getParentUrl() {
    const temp = this.router.url.split('/');
    return temp[1] + '/' + temp[2];
  }

  public extractQueryParam(source) {
    if (!source) {
      return null;
    }
    const queryParams = {};
    const temp = source.split('?');
    if (temp.length > 1) {
      source = temp[1];
    }
    if (!source) {
      return null;
    }

    const list = source.split('&');
    if (!list) {
      return null;
    }
    for (let i = 0; i < list.length; i++) {
      const check = list[i].split('=');
      if (check.length == 2) {
        queryParams[check[0]] = check[1];
      }
    }
    return queryParams;
  }

  public clickStyleSearch() {
    this.justGo('style/search', {}, false);
  }

  public clickAlarm() {
    this.justGo('user/alarm', {});
  }
  public clickSetting() {
    this.justGo('user/setting', {});
  }

  public clickCart() {
    this.justGo('store/cart', { tab: 'cart' });
  }

  goDownload() {
    this.goExternalLocal(environment.download_onelink);
    // if (Capacitor.getPlatform() == 'ios') {
    // } else {
    //   this.goExternal(environment.android_install);
    // }
  }

  public clickGoUpdate() {
    this.update.openAppStore();
  }

  public clickStoreSearch(event = null) {
    if (event) {
      event.stopPropagation();
    }
    this.justGo('style/search-text', {}, false);
  }

  public routingError(return_url: string) {
    this.errorCount.count++;
    this.justGo('/temp/login-proxy', { return_url: encodeURIComponent(return_url) }, false, false);
  }
  public routingGoLogin(return_url: string) {
    console.log('routingGoLogin', return_url);
    this.justGo('/temp/login', { return_url: encodeURIComponent(return_url) }, false, false);
  }

  public parseUrlAndjustGo(url) {
    let param = this.extractQueryParam(url);
    if (!param) {
      param = {};
    }

    this.justGo(url, param, false, false);
  }

  public justGo(url, queryParams: any = {}, animated: boolean = true, mergeParam: boolean = true) {
    // this.routerOutlet.nativeEl.animated = animated;

    const currentParam = this.getAllQueryParam(this.route);
    let allParam = null;
    if (mergeParam) {
      allParam = this.utils.filterObject({
        ...currentParam,
        ...queryParams
      });
    } else {
      allParam = queryParams;
    }

    if (environment.is_native_portal) {
      this.toNativeGo(url, allParam);
    } else {
      this.navCtrl.navigateForward(url, {
        queryParams: allParam,
        animated
      });
    }
  }

  public toNativeGo(url, queryParams: any = {}) {
    let url_param = '';

    const keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      url_param = `${keys[i]}=${encodeURIComponent(queryParams[keys[i]])}`;
    }

    let data = url;
    if (keys.length > 0) {
      data = `${url}?${url_param}`;
    }

    const message: PortalMessage = {
      topic: 'justGo',
      data
    };

    console.log('justGoNative', message);
    Portals.publish(message);
  }

  public nativeGo(url, queryParams: any = {}, animated: boolean = true, mergeParam: boolean = true) {
    const currentParam = this.getAllQueryParam(this.route);
    let allParam = null;
    if (mergeParam) {
      allParam = this.utils.filterObject({
        ...currentParam,
        ...queryParams
      });
    } else {
      allParam = queryParams;
    }

    console.log('gogogogogogo', url, allParam);
    this.navCtrl.navigateForward(url, {
      queryParams: allParam,
      animated: false
    });
  }
  public messageGo(url, queryParams: any = {}) {
    // this.routerOutlet.nativeEl.animated = false;
    this.navCtrl.navigateForward(url, {
      queryParams,
      animated: false
    });
  }

  public delayJustGo(url, queryParams: any = {}, animated: boolean = true) {
    setTimeout(() => {
      this.justGo(url, queryParams, animated);
    }, 100);
  }

  public goReplace(url, queryParams?) {
    this.navCtrl.navigateRoot(url, {
      queryParams,
      animation: null,
      animated: false,
      replaceUrl: true
    });

    // this.router.navigate([url], { queryParams });
  }

  // public goReplace(url) {
  //   this.clearHistory();
  //   this.go(url);
  // }

  // public goSubpage(path, queryParams) {
  //   const temp = this.router.url.split('/');
  //   const url = temp[1] + '/' + temp[2] + '/' + path;
  //   this.go(url, queryParams);
  // }

  public async updatePageQueryParam(paramToUpdate) {
    try {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: paramToUpdate,
        queryParamsHandling: 'merge'
      });
      const temp = this.router.url.split('/');
      const url = temp[1] + '/' + temp[2] + '/' + temp[3].split('?')[0];
      const newParam = {
        ...this.route.snapshot.queryParams,
        ...paramToUpdate
      };

      this.updateAmplitudeWhere(url, newParam);
    } catch (e) {
      console.log(e);
    }
  }

  // public goSubPageUrl(path) {
  //   const temp = this.router.url.split('/');
  //   const queryTemp = path.split('?');
  //   const queryParams = {};
  //   if (queryTemp.length > 1) {
  //     const right = queryTemp[1];
  //     const list = right.split('&');
  //     for (let i = 0; i < list.length; i++) {
  //       const check = list[i].split('=');
  //       queryParams[check[0]] = check[1];
  //     }
  //   }
  //   const url = temp[1] + '/' + temp[2] + '/' + queryTemp[0];
  //   // this.addHistory(url, queryParams);
  //   this.go(url, queryParams);
  // }

  // public goWithQuery(url, queryParams) {
  //   this.go(url, queryParams);
  //   // this.router.navigate([url], { queryParams });
  // }

  public async goUrlDirect(path, addParams: any = {}, ignore_campaign_save = false) {
    const queryTemp = path.split('?');
    let queryParams = {};
    if (queryTemp.length > 1) {
      const right = queryTemp[1];
      const list = right.split('&');
      for (let i = 0; i < list.length; i++) {
        const check = list[i].split('=');
        queryParams[check[0]] = check[1];
      }
    }
    let target_url = queryTemp[0];
    if (target_url.startsWith('fumi://')) {
      target_url = queryTemp[0].replace('fumi://', '');
    }
    if (target_url.startsWith('https://')) {
      target_url = queryTemp[0].replace('https://', '');
    }

    if (target_url.startsWith('fumi.co.kr')) {
      target_url = target_url.replace('fumi.co.kr', '');
    }

    queryParams = { ...queryParams, ...addParams };

    //check campaign data
    if (queryParams['app_open_pid'] && queryParams['app_open_campaign'] && queryParams['app_open_adset']) {
      const campaign_data = {
        app_open_pid: queryParams['app_open_pid'],
        app_open_campaign: queryParams['app_open_campaign'],
        app_open_adset: queryParams['app_open_adset'],
        app_open_datetime: new Date()
      };

      if (!ignore_campaign_save) {
        await this.storage.set('app_open_campaign', campaign_data);
      }
    }

    this.router.navigate([target_url], { queryParams: { ...queryParams } });
  }

  checkDeeplink(data) {
    try {
      const url = this.safeDecode(data);
      if (url) {
        this.goUrlDirect(url);
      }
    } catch (e) {
      console.error('checkDeepLink error', e);
    }
  }

  decodeDeeplink(data) {
    try {
      if (!data) {
        throw Error('no data');
      } else {
        const url = decodeURIComponent(data);
        if (!url) {
          throw Error('no data');
        }
        const queryTemp = url.split('?');
        const queryParams: any = {};
        if (queryTemp.length > 1) {
          const right = queryTemp[1];
          const list = right.split('&');
          for (let i = 0; i < list.length; i++) {
            const check = list[i].split('=');
            queryParams[check[0]] = check[1];
          }
        }
        let target_url = queryTemp[0];
        if (target_url.startsWith('fumi://')) {
          target_url = queryTemp[0].replace('fumi://', '');
        }
        if (target_url.startsWith('https://')) {
          target_url = queryTemp[0].replace('https://', '');
        }
        if (target_url.startsWith('http://')) {
          target_url = queryTemp[0].replace('http://', '');
        }
        if (target_url.startsWith('fumi.co.kr')) {
          target_url = target_url.replace('fumi.co.kr', '');
        }

        if (target_url.startsWith('localhost:8100')) {
          target_url = target_url.replace('localhost:8100', '');
        }

        if (target_url.startsWith('mainactivity')) {
          target_url = target_url.replace('mainactivity', '');
        }
        return { url: target_url, query: queryParams };
      }
    } catch (e) {
      console.error('decodeDeeplink error', e);
      return null;
    }
  }

  public goExternalLocal(fullUrl) {
    window.location.replace(fullUrl);
  }

  public goExternal(fullUrl) {
    window.open(fullUrl, '_blank');
  }

  public goKakaoChat() {
    this.goExternal('https://pf.kakao.com/_zndLxb/chat');
  }

  public async goBack(animated = true) {
    this.routerOutlet.nativeEl.animated = animated;
    try {
      if (this.routerOutlet.canGoBack()) {
        await this.navCtrl.pop();
      } else {
        this.navCtrl.navigateBack('/', { animated });
      }
    } catch (error) {
      console.log('!!!!!!!error navi back', error);
    }
  }

  public goBackNav() {
    this.navCtrl.pop();
  }

  public goRootHome() {
    // const temp = this.router.url.split('/');
    // let routerUrl: string = '/app/home/recommend';
    // if (temp.length > 2) {
    //   routerUrl = `${temp[1]}/${temp[2]}`;
    // } else {
    //   routerUrl = '/';
    // }
    // this.addHistory(
    //   `main/${this.current_tab}/${this.current_tab}`,
    //   {},
    // );

    // this.router.navigate([routerUrl], { queryParams: {} });

    this.navCtrl.navigateRoot('/', { replaceUrl: true });

    this.updateAmplitudeWhere('/', {});
  }

  public updateAmplitudeWhere(url: string, queryParams: any = {}) {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }

    const segments = url.split('/');
    if (!isNaN(Number(segments[segments.length - 1]))) {
      queryParams.idx = segments[segments.length - 1];
      segments[segments.length - 1] = null;
      url = segments.join('/');
    }

    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    url = url.replace(/\/\//g, '/');

    this.amplitude.updateWhere(url, queryParams);
  }

  public async showLoading(title: string) {
    const loading = await this.loadingController.create({
      message: title
    });
    await loading.present();

    return loading;
  }

  public async showAlert(header: string, message: string, buttons: any[]) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: buttons
    });

    await alert.present();

    // const { role } = await alert.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
    return await alert.onDidDismiss();
  }

  public async showLoginDialog(from: 'item-detail' | 'upload' | 'post-read' | 'event-coupon' | 'function') {
    const modal = await this.modalController.create({
      component: LoginModalComponent,
      componentProps: {
        from
      },
      cssClass: 'option-modal',
      backdropDismiss: true
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data == true;
  }

  public async showLoginFullDialog() {
    const modal = await this.modalController.create({
      component: LoginPage,
      componentProps: {},
      animated: false
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data == true;
  }

  public async checkAndLogin() {
    let check = await this.getCurrentUser();

    if (!(check.login_type == 'kakao' || check.login_type == 'apple')) {
      return await this.showLoginDialog('function');
    } else {
      return true;
    }
  }

  public async checkAndLoginFullPage() {
    let check = await this.getCurrentUser();
    if (!(check.login_type == 'kakao' || check.login_type == 'apple')) {
      return await this.showLoginFullDialog();
    } else {
      return true;
    }
  }

  public async get_onelink(url: string, landing_type: string, app_open_pid: string, app_open_campaign: string, app_open_adset: string, show_loading = true) {
    let loading;

    if (show_loading) {
      loading = await this.showLoading('잠시만기다려주십시요');
    }
    let temp = null;
    try {
      temp = await this.db.insert_onelink({
        landing_type,
        app_open_pid,
        app_open_campaign,
        app_open_adset,
        deep_link_value: url
      });
    } catch (error) {}

    if (loading) {
      await loading.dismiss();
    }

    // let result = temp.short_link;

    return temp;
  }

  public async showToast(message: string, duration: number = 1000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      cssClass: 'toast-black'
    });
    toast.present();
  }

  // 브라우저 환경 이면 false - 네이티브 환경이면 true
  public getPlatform() {
    return Capacitor.getPlatform();
  }

  /**
   * 웹인지 앱인지 구분
   */
  public isApp() {
    return this.getPlatform() !== 'web' && !environment.is_native_portal;
  }

  /**
   * 환경 변수 가져오기
   */
  public getEnv() {
    return environment;
  }

  /**
   * 카카오 인앱 브라우져인지 확인
   */
  public isKakao() {
    const temp = JSON.stringify(navigator.userAgent).toLowerCase();
    return temp.includes('kakao');
    // return true;
  }

  getFullHost() {
    // if (Capacitor.getPlatform() !== 'web') {
    //   const href = window.location.href;
    //   const index1 = href.indexOf('#');
    //   const index2 = href.substr(0, index1).lastIndexOf('/');
    //   return href.substr(0, index2 + 1);
    // }
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  private handleError(error) {
    if (error && error.error instanceof ErrorEvent) {
      alert('서버오류 : ' + error.message);
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // alert("코드: " + error.error_number + ", 메세지: " + error.error_msg);
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('error number = ' + error.error_number);

      if (error.error_number == '001') {
        console.log('error gogo');
      }
    }
    console.log(error);
    // return an observable with a user-facing error message
    return throwError(error);
  }

  private extractData(res: any) {
    //error
    if (res.status === 0) {
      throw res.error;
    }
    let body = res.data;
    return body || {};
  }

  async registUser(param) {
    const url = endpoint + '/regist';
    return this.http.post(url, param, httpOptions).toPromise();
  }

  async loginUser(param) {
    const url = endpoint + '/login';
    return this.http.post(url, param, httpOptions).toPromise();
  }

  async isDuplicatedEmail(param) {
    const url = endpoint + '/isDuplicatedEmail';
    return this.http.post(url, param, httpOptions).toPromise();
  }

  post(uri, param) {
    const url = endpoint + uri;
    return this.http.post(url, param, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  //파일 업로드
  async insert_contest_join(file_list, data, container) {
    return await this.insert_contest_join_process('/api/file/insert_contest_join', file_list, data, container);
  }
  async insert_post(file_list, data, container) {
    return await this.insert_post_process('/api/file/insert_post', file_list, data, container);
  }

  insert_post_process(uri, urls, data, container: string) {
    const self = this;
    return new Promise<any>(async (resolve, reject) => {
      // 업로드 api
      const token = await this.getLocalData('token');
      var uploadUrl = endpoint + uri;
      var formData = new FormData();
      console.log('선택한 파일 개수 :' + urls.length);
      var fileCount = urls.length;

      const file_data_list = [];
      for (var i = 0; i < fileCount; i++) {
        var _file = urls[i].file;
        formData.append('files', _file, _file.name);
        // file_data_list.push({
        //   name: urls[i].name,
        //   // width: urls[i].width,
        //   // height: urls[i].height
        // })
      }

      formData.append('container', container);
      formData.append('data', JSON.stringify(data));
      // formData.append('file_data_list', JSON.stringify(file_data_list));

      file_data_list;
      fetch(uploadUrl, {
        method: 'POST',
        headers: new Headers({
          token: token
        }),
        body: formData
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('completed=', result);

          if (result.status === 0) {
            reject(result.error);
          } else {
            const body = result.data;
            resolve(body || {});
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    });
  }
  insert_contest_join_process(uri, urls, data, container: string) {
    const self = this;
    return new Promise<any>(async (resolve, reject) => {
      // 업로드 api
      const token = await this.getLocalData('token');
      var uploadUrl = endpoint + uri;
      var formData = new FormData();
      console.log('선택한 파일 개수 :' + urls.length);
      var fileCount = urls.length;

      const file_data_list = [];
      for (var i = 0; i < fileCount; i++) {
        var _file = urls[i].file;
        formData.append('files', _file, _file.name);
        file_data_list.push({
          name: urls[i].name
          // width: urls[i].width,
          // height: urls[i].height
        });
      }

      formData.append('container', container);
      formData.append('data', JSON.stringify(data));
      formData.append('file_data_list', JSON.stringify(file_data_list));

      file_data_list;
      fetch(uploadUrl, {
        method: 'POST',
        headers: new Headers({
          token: token
        }),
        body: formData
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('completed=', result);

          if (result.status === 0) {
            reject(result.error);
          } else {
            const body = result.data;
            resolve(body || {});
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    });
  }

  async upload_file(file: BlobFileFormat, container: string) {
    console.log('container', container);
    const result = await this.upload_file_list([file], container);

    return {
      upload_file_url: result.upload_file_url[0]
    };
  }

  async update_user_profile(data) {
    return await this.post('/api/mobile-catalog/update_user_profile', data).toPromise();
  }

  async upload_file_with_data(file: BlobFileFormat, data: any) {
    const result = await this.upload_file_list([file], data);
    return {
      upload_file_url: result.upload_file_url[0]
    };
  }

  async upload_file_list(files: Array<BlobFileFormat>, data: any) {
    console.log('upload_file_list data', data);
    return await this.uploadFile('/api/file/upload_file', files, data);
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  uploadFile(uri, files: Array<BlobFileFormat>, data) {
    return new Promise<any>(async (resolve, reject) => {
      // 업로드 api
      const token = await this.getLocalData('token');
      var uploadUrl = endpoint + uri;
      var formData = new FormData();
      console.log('선택한 파일 개수 :' + files.length);
      var fileCount = files.length;

      for (var i = 0; i < fileCount; i++) {
        var _file = files[i].file;
        formData.append('files', _file, files[i].filename);
      }

      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        formData.append(keys[i], data[keys[i]]);
      }

      fetch(uploadUrl, {
        method: 'POST',
        headers: new Headers({
          token: token
        }),
        body: formData
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('completed=', result);

          if (result.status === 0) {
            reject(result.error);
          } else {
            const body = result.data;
            resolve(body || {});
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    });
  }

  public getExt(filename) {
    const temp = filename.split('.');
    return temp[temp.length - 1];
  }

  raw_get(url, param) {
    return this.http.get(url).pipe(map(this.extractData), catchError(this.handleError));
  }

  raw_post(url, param) {
    return this.http.post(url, param, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  logAppsFlyerEvent(eventName, eventValue) {
    if (Capacitor.getPlatform() != 'web') {
      AppsFlyer.logEvent({ eventName, eventValue });
    }
  }

  logAppsFlyerPurchase(order_transaction) {
    if (Capacitor.getPlatform() != 'web') {
      const eventName = 'af_purchase';
      const eventValues = {
        af_revenue: order_transaction.total_sell_price,
        af_content_id: order_transaction.order_transaction_idx,
        af_currency: 'KRW',
        payment_type: order_transaction.payment_type
      };
      this.logAppsFlyerEvent(eventName, eventValues);
    }
  }

  // async loginNativeSocial(kind: string, is_app: boolean, access_token: string) {
  //   const temp = await this.db.login_social_with_status(kind, is_app, this.getTempUserIdx(), access_token);
  //   this.show_download_bar = false;
  //   let eventName, eventValues;
  //   if (temp.status === 'login') {
  //     eventName = 'af_signup';
  //     this.amplitude.sendEvent(this.constant.amplitude_new.complete_login, {});
  //   } else {
  //     eventName = 'af_complete_registration';
  //     eventValues = {
  //       af_registration_method: Capacitor.getPlatform()
  //     };
  //     this.amplitude.sendEvent(this.constant.amplitude_new.complete_signup, {});
  //   }
  //   this.logAppsFlyerEvent(eventName, eventValues);
  //   return temp;
  // }

  async select_order_for_pay(order_transaction_idx: number) {
    return await this.post('/api/mobile-catalog/select_order_for_pay', {
      order_transaction_idx
    }).toPromise();
  }

  async select_my_list(offset, limit) {
    return await this.post('/api/mobile-catalog/select_my_list', {
      offset,
      limit
    }).toPromise();
  }

  async select_my_data() {
    return await this.post('/api/mobile-catalog/select_my_data', {}).toPromise();
  }

  async select_user_post_list(target_user_idx, offset, limit) {
    return await this.post('/api/mobile-catalog/select_user_post_list', {
      offset,
      limit,
      target_user_idx
    }).toPromise();
  }

  async select_detail_list(post_idx, offset, limit) {
    return await this.post('/api/mobile-catalog/select_detail_list', {
      offset,
      limit,
      post_idx
    }).toPromise();
  }

  async select_reply(post_idx, offset, limit) {
    return await this.post('/api/mobile-catalog/select_reply', {
      offset,
      limit,
      post_idx
    }).toPromise();
  }

  async select_address_book_list() {
    return await this.post('/api/mobile-catalog/select_address_book_list', {}).toPromise();
  }

  async delete_address_book(address_book_idx) {
    return await this.post('/api/mobile-catalog/delete_address_book', {
      address_book_idx
    }).toPromise();
  }

  async update_address_book(data) {
    return await this.post('/api/mobile-catalog/update_address_book', {
      data
    }).toPromise();
  }

  async insert_address_book(data) {
    return await this.post('/api/mobile-catalog/insert_address_book', {
      data
    }).toPromise();
  }

  // async is_like(post_idx: number) {
  //   // console.log('!!!!!!!is_like =',post_idx);
  //   if (!post_idx) {
  //     return false;
  //   }

  //   if (this.databaseLike[post_idx] == true || this.databaseLike[post_idx] == false) {
  //     return this.databaseLike[post_idx];
  //   } else {
  //     // const result = await this.post('/api/mobile-catalog/is_like', {
  //     //   post_idx
  //     // }).toPromise();

  //     const user = this.currentUser();
  //     let result = false;
  //     if (user) {
  //       result = await this.db.is_like(post_idx);
  //     }

  //     // const result = await this.db.is_like(post_idx);
  //     // console.log('no value will result server =' ,result);
  //     this.databaseLike[post_idx] = result;
  //   }
  //   return this.databaseLike[post_idx];
  // }

  // async insert_like(post_idx) {
  //   if (this.databaseLike[post_idx] == true) {
  //     return;
  //   }
  //   this.databaseLike[post_idx] = true;
  //   if (this.databasePost[post_idx]) {
  //     this.databasePost[post_idx].like_count++;
  //   }
  //   return await this.post('/api/mobile-catalog/insert_like', {
  //     post_idx
  //   }).toPromise();
  // }

  // async delete_like(post_idx) {
  //   if (this.databaseLike[post_idx] == false) {
  //     return;
  //   }

  //   this.databaseLike[post_idx] = false;
  //   if (this.databasePost[post_idx]) {
  //     this.databasePost[post_idx].like_count--;
  //   }

  //   return await this.post('/api/mobile-catalog/delete_like', {
  //     post_idx
  //   }).toPromise();
  // }

  async is_follow(to_user_idx) {
    if (this.databaseFollow[to_user_idx] == true || this.databaseFollow[to_user_idx] == false) {
      // console.log('have value =' + to_user_idx + ", ", this.databaseFollow[to_user_idx]);
      return this.databaseFollow[to_user_idx];
    } else {
      // console.log('no value will call server =' + to_user_idx);
      // const result = await this.post('/api/mobile-catalog/is_follow', {
      //   to_user_idx
      // }).toPromise();
      // console.log(result)

      const result = await this.db.is_follow(to_user_idx);
      this.databaseFollow[to_user_idx] = result;
    }
    return this.databaseFollow[to_user_idx];
  }

  async insert_follow(to_user_idx) {
    this.amplitude.sendEvent(this.constant.amplitude.click_follow, {
      user_idx: to_user_idx
    });
    if (this.databaseFollow[to_user_idx] == true) {
      return;
    }
    this.databaseFollow[to_user_idx] = true;
    return await this.post('/api/mobile-catalog/insert_follow', {
      to_user_idx
    }).toPromise();
  }

  async delete_follow(to_user_idx) {
    this.amplitude.sendEvent(this.constant.amplitude.click_unfollow, {
      user_idx: to_user_idx
    });
    if (this.databaseFollow[to_user_idx] == false) {
      return;
    }
    this.databaseFollow[to_user_idx] = false;
    return await this.post('/api/mobile-catalog/delete_follow', {
      to_user_idx
    }).toPromise();
  }

  async delete_post(post_idx) {
    return await this.post('/api/mobile-catalog/delete_post', {
      post_idx
    }).toPromise();
  }

  async insert_reply(data) {
    return await this.post('/api/mobile-catalog/insert_reply', {
      data
    }).toPromise();
  }

  async delete_reply(reply_idx) {
    return await this.post('/api/mobile-catalog/delete_reply', {
      reply_idx
    }).toPromise();
  }

  // async check_pay_result(imp_uid, merchant_uid) {
  //   return await this.post('/api/mobile-catalog/check_pay_result', {
  //     imp_uid, merchant_uid
  //   }).toPromise();
  // }

  async kakao_for_sdk({ user_info, access_token }) {
    return await this.post('/auth/kakao_for_sdk', {
      user_info,
      access_token
    }).toPromise();
  }

  async apple_login(user_info) {
    return await this.post('/auth/apple_login', {
      user_info
    }).toPromise();
  }

  async unlink_kakao() {
    return await this.post('/api/user/unlink_kakao', {}).toPromise();
  }

  //자기 정보 가져오기
  async select_my() {
    return await this.post('/api/user/select_my', {}).toPromise();
  }

  //   native_kakao_login() {
  //     return new Promise((resolve, reject) => {

  //       console.log('kakao login');
  //       let message = '';

  //       let values = {
  //         authTypes: [AuthTypes.AuthTypeTalk]
  //       };
  //       // values.authTypes = [AuthTypes.AuthTypeTalk];

  // const user = this._kakaoCordovaSDK
  //         .login(values)
  //         .then(
  //           res => {

  //             message = JSON.stringify(res);
  //             console.log(message);
  //             resolve(res);
  //           },
  //           err => {
  //             console.log(JSON.stringify(err));

  //             reject(err);
  //           }
  //         )
  //         .catch(err => {

  //           console.log(JSON.stringify(err));
  //           reject(err);
  //         });

  //     });
  //   }

  // async native_kakao_login() {
  //   console.log('1');
  //   try {
  //     const values = {
  //       authTypes: [AuthTypes.AuthTypeTalk],
  //     };

  //     console.log('2');
  //     const user = await this._kakaoCordovaSDK.login(values);
  //     console.log(JSON.stringify(user));
  //     console.log('3');
  //     return user.accessToken;
  //   } catch (error) {
  //     console.log('4');
  //   }
  // }

  // access_token: "IkFHPf8SzCeseb0QldYehZq4dde0WHT4GQDFuAorDSAAAAF0ulM9HQ"
  // expires_in: 7199
  // refresh_token: "gAVVJrBISmKKA6tDI4SawsuWgQ2y2biHRuT1_worDSAAAAF0ulM9Gw"
  // refresh_token_expires_in: 5183999
  // scope: "age_range birthday account_email gender birthyear profile phone_number"
  // token_type: "bearer"

  async login_using_proxy(return_url: string) {
    const callback_url = encodeURIComponent(`${this.getFullHost()}/user/login-callback`);
    const url = `https://fumi.co.kr/user/login-proxy?callback_url=${callback_url}&return_url=${encodeURIComponent(return_url)}&login_type=kakao`;
    this.goExternalLocal(url);
  }

  // async login_process(login_type: string, where) {
  //   this.amplitude.sendEvent(this.constant.amplitude_new.request_login, {
  //     where: where
  //   });

  //   let result = false;
  //   if (login_type == 'kakao') {
  //     result = await this.login_kakao_process();
  //   } else {
  //     result = await this.login_apple_process();
  //   }

  //   if (result) {
  //     this.db.insert_access_history({
  //       device: Capacitor.getPlatform(),
  //       temp_user_idx: this.getTempUserIdx()
  //     });

  //     this.utils.create_meta_tag('user_id', `${this.currentUser().user_idx}`);
  //   }

  //   //로그인 후 처리해야할 데이터들
  //   await this.refreshAfterSessionChanged();

  //   return result;
  // }

  //로그인 후 처리해야할 데이터들
  async refreshAfterSessionChanged() {
    // await this.likeService.refreshData();
    // await this.wishService.refreshData();
    // await this.followService.refreshData();
  }

  //apple login
  // async login_apple_process() {
  //   const token = await this.get_access_token_apple();
  //   if (!token) {
  //     return false;
  //   }

  //   const user = await this.login_with_access_token(token + '');
  //   if (!user) {
  //     return false;
  //   }
  //   return true;
  // }

  async get_access_token_apple() {
    const sample = {
      response: {
        email: 'foo@bar.com',
        identityToken: 'importantToken',
        familyName: 'Grimm',
        user: 'AppleUserId',
        givenName: 'Simon',
        authorizationCode: 'authCode'
      }
    };

    const self = this;

    return new Promise((resolve, reject) => {
      SignInWithApple.authorize()
        .then(async (res) => {
          if (res.response && res.response.identityToken) {
            console.log(res.response);
            let result = await self.apple_login(res.response);
            // console.log(result);
            // this.tracker.logFirebaseLogin(result.status);
            resolve(result.token);
          } else {
            console.log(JSON.stringify(res));
            alert(JSON.stringify(res));
            resolve(null);
          }
        })
        .catch((response) => {
          alert(JSON.stringify(response));
          resolve(null);
        });
    });
  }

  // async get_social_access_token(login_type: string) {
  //   let social_access_token = null;
  //   if ('kakao' == login_type) {
  //     social_access_token = await this.kakao.kakao_login();
  //   } else if ('apple' == login_type) {
  //     social_access_token = await this.get_access_token_apple();
  //   }

  //   return { login_type, social_access_token };
  // }

  //로그인 처리하기
  // async login_kakao_process() {
  //   //using social login
  //   let social_access_token = await this.kakao.kakao_login();
  //   if (!social_access_token) {
  //     return false;
  //   }

  //   const is_app = this.isApp();
  //   //login
  //   const { token, status } = await this.loginNativeSocial('kakao', is_app, social_access_token);

  //   const user = await this.login_with_access_token(token);
  //   if (!user) {
  //     return false;
  //   }

  //   this.tracker.logFirebaseLogin(status);
  //   return true;
  // }

  // async login_with_access_token(token: string) {
  //   //get user infor
  //   await this.login(token);
  //   const user = await this.currentUser();
  //   if (!user) {
  //     return null;
  //   }

  //   //onesignal init
  //   this.oneSignalSync({
  //     user_idx: user.user_idx,
  //     push_alarm_onoff: user.push_alarm_onoff
  //   });
  //   return user;
  // }

  //kakao sdk login
  // kakao_javascript_login() {
  //   return new Promise((resolve, reject) => {
  //     Kakao.Auth.login({
  //       success: function (authObj) {
  //         let { access_token } = authObj;
  //         resolve(access_token);
  //       },
  //       fail: function (err) {
  //         alert(JSON.stringify(err));
  //         reject(err);
  //       },
  //     });
  //   });
  // }

  setLocal(key, value) {
    if (value) {
      window.localStorage.setItem(key, value);
    } else {
      this.removeLocal(key);
    }
    return true;
  }

  removeLocal(key) {
    window.localStorage.removeItem(key);
    return true;
  }

  getLocal(key) {
    return window.localStorage.getItem(key);
  }

  async setLocalData(key: string, value: any) {
    return await this.storage.set(key, value);
  }

  async updateLocalData(key: string, value: any) {
    let pre_data = await this.getLocalData(key, true);
    const result = {
      ...pre_data,
      ...value
    };
    return await this.setLocalData(key, result);
  }

  async getLocalData(key: string, force_value: boolean = false) {
    let result = await this.storage.get(key);
    if (force_value && !result) {
      result = {};
    }
    return result;
  }

  async removeLocalData(key: string) {
    return await this.storage.remove(key);
  }

  // async login(token) {
  //   window.localStorage.setItem('token', token);
  //   await this.fetch();
  // }

  isPushOn() {
    if (!this.getLocal('push')) {
      this.setLocal('push', 'yes');
      return true;
    } else {
      if (this.getLocal('push') == 'no') {
        return false;
      }
      return true;
    }
  }

  // async share_kakao(title: string, description: string, imageUrl: string, image_link_url: string, button_title: string) {
  //   try {
  //     await this.kakao.share(title, description, imageUrl, image_link_url, button_title);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  // async share_kakao_post(data: Post) {
  //   this.amplitude.sendEvent(this.constant.amplitude_new.share_post, {
  //     post_idx: data.post_idx,
  //     post_type: data.post_type,
  //     user_idx: data.user.user_idx,
  //     user_name: data.user.user_name
  //   });

  //   return await this.share_kakao(data.title, data.description, data.preview_img_url + '/300x', environment.user_endpoint + '/main/style/post-detail/' + data.post_idx, '자세히보기');
  // }

  // async share_kakao_store(data: Store) {
  //   this.amplitude.sendEvent(this.constant.amplitude_new.share_store, this.amplitude.getStoreProps(data));

  //   return await this.share_kakao(data.name, data.description, data.thumbnail_url, environment.user_endpoint + '/main/shop/store_profile/' + data.store_idx, '상점 구경하기');
  // }

  // async logout() {
  //   try {
  //     const result: any = await this.apollo
  //       .mutate({
  //         mutation: logout
  //       })
  //       .toPromise();
  //     console.log(result);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   try {
  //     this.oneSignalLogout();
  //   } catch (error) {}

  //   //server logout

  //   //local logout
  //   this.logoutTokenOnly();
  //   this.databaseCartCount = 0;

  //   await this.refreshAfterSessionChanged();

  //   return true;
  // }

  //토큰이 유효하지 않으면 logout mutation을 호출할 수 없음
  // logoutTokenOnly() {
  //   window.localStorage.removeItem('token');
  //   window.localStorage.removeItem('user');
  //   this.amplitude.setTempUserId(this.getTempUserIdx());
  //   // this.user = null;
  //   // this.kakao.logout();
  // }

  // logEnable
  // public async logEnable() {
  //   try {
  //     await this.checkInitFirebaseAnalytics();

  //     await FirebaseAnalytics.setCollectionEnabled({
  //       enabled: false
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // //analytics log 남기기
  // public async logSetUserId() {
  //   try {
  //     if (this.isLogin()) {
  //       const user = this.currentUser();
  //       await FirebaseAnalytics.setUserId({
  //         userId: `${user.user_idx}`
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // //screen log
  // public async logSetScreen(page: string, pageUrl: string) {
  //   try {
  //     if (Capacitor.getPlatform() != 'web') {
  //       await FirebaseAnalytics.setScreenName({
  //         screenName: page,
  //         nameOverride: pageUrl
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async fetch() {
  //   try {
  //     const result = await this.select_my();
  //     if (!result) {
  //       throw 'no user_data';
  //     }
  //     window.localStorage.setItem('user', JSON.stringify(result));
  //     this.user = result;

  //     this.amplitude.setUserId(this.user);

  //     return this.user;
  //   } catch (error) {
  //     this.user = null;
  //     try {
  //       await this.logoutTokenOnly();
  //     } catch (e) {}
  //     return null;
  //   }
  // }

  // currentUser(): User {
  //   if (this.user && this.user.user_idx) {
  //     return this.user;
  //   }
  //   const temp = window.localStorage.getItem('user');
  //   if (temp) {
  //     this.user = JSON.parse(temp);
  //     return this.user;
  //   }
  //   return null;
  // }

  // async isManager() {
  //   if (this.isLogin()) {
  //     await this.fetch();
  //     const temp: any = await this.currentUser();
  //     if (!temp) return false;
  //     const role = temp.role;
  //     return role.includes('manager');
  //   } else {
  //     return false;
  //   }
  // }

  // async getCartCount() {
  //   if (this.user) {
  //     let temp = await this.db.shop_select_count_user_cart_list();
  //     this.databaseCartCount = temp;
  //   }
  // }

  // async firebaseInit() {
  //   if (Capacitor.getPlatform() === 'web') {
  //     await FirebaseAnalytics.initializeFirebase(environment.firebase);
  //   }
  // }

  // async checkInitFirebaseAnalytics() {
  //   if (Capacitor.getPlatform() == 'web' && !window.firebase) {
  //     await this.firebaseInit();
  //   }
  // }

  async sendPaymentError(error) {
    await this.db.user_insert_error_log(error, 'payment_fail');
  }

  getDiffDays(from_date) {
    const now = new Date().getTime();
    const before_date = new Date(from_date).getTime();
    const diffTime = Math.abs(now - before_date);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
  }

  async showModal(targetComponent: any, param: any) {
    const modal = await this.modalController.create({
      component: targetComponent,
      componentProps: param
    });
    return await modal.present();
  }

  // checkDownloadBarShowing() {
  //   if (this.isLogin()) {
  //     this.show_download_bar = false;
  //     return;
  //   }
  //   if (this.isDesktop()) {
  //     this.show_download_bar = false;
  //     return;
  //   }
  //   const before_download_date = this.getLocal('download_bar_date');
  //   if (this.getDiffDays(before_download_date) < 3) {
  //     this.show_download_bar = false;
  //     return;
  //   }
  //   this.show_download_bar = true;
  //   this.amplitude.sendEvent(this.constant.amplitude_new.enter_app_download, { type: this.constant.amplitude_new.component_type.top_banner });
  //   return;
  // }

  // hideDownloadBar() {
  //   this.show_download_bar = false;
  //   this.amplitude.sendEvent(this.constant.amplitude_new.close_app_download, {});
  // }

  decodeKoreanURL(keyword) {
    let decoded_keyword = decodeURI(keyword);
    decoded_keyword = keyword.replace('%23', '#');
    return decoded_keyword;
  }

  processLinks(e) {
    let element: HTMLElement = e.target;
    while (element && element.nodeName !== 'ION-TABS') {
      if (element.nodeName === 'A') {
        e.preventDefault();
        const link = element.getAttribute('href');

        let acceptable_url, event_idx, section_idx;
        if (this.router.url.includes('event-detail')) {
          acceptable_url = 'event-detail';
          event_idx = Number(this.route.snapshot.queryParamMap.get('event_idx'));
        } else if (this.router.url.includes('shop-item-page')) {
          acceptable_url = 'shop-item-page';
          section_idx = Number(this.route.snapshot.queryParamMap.get('section_idx'));
        }

        if (acceptable_url) {
          if (link.includes('item-detail')) {
            const item_idx = Number(this.router.parseUrl(link).queryParamMap.get('item_idx'));

            this.amplitude.sendEvent(this.constant.amplitude_new.click_item, {
              item_idx: item_idx,
              where: {
                path: acceptable_url,
                query: {
                  event_idx,
                  section_idx,
                  item_idx: item_idx
                }
              }
            });
          }
        }

        if (link.includes('http') || link.includes('https') || link.includes('mailto')) {
          // 외부링크냐
          window.open(link, '_system');
        } else {
          // 아니면 다 내부링크
          this.goUrlDirect(link);
        }
      }
      element = element.parentElement;
    }
    e.preventDefault();

    return;
  }

  setRecentImage(url: string) {
    this.recent_image_url = url;
  }

  getDatarizeUserId() {
    return new Promise((resolve, reject) => {
      const check = this.cookieService.getAll();

      const keys = Object.keys(check);

      let cookieId = null;

      for (let i = 0; i < keys.length; i++) {
        if (keys[i].indexOf('_pk_id') > -1) {
          cookieId = check[keys[i]].split('.')[0];
          break;
        }
      }
      resolve(cookieId);
    });
  }

  fix_post_tv_img(item: any) {
    const now = new Date();
    //예고
    if (new Date(item.live_start_datetime).getTime() > now.getTime()) {
      item.post_tv_img_url = item.post_tv_img_url_before;
    }
    //진행
    else if (new Date(item.live_end_datetime).getTime() > now.getTime()) {
      item.post_tv_img_url = item.post_tv_img_url;
    }
    //종료
    else {
      item.post_tv_img_url = item.post_tv_img_url_after;
    }
  }

  getHomeScrollListener() {
    return this.homeScrollSubject.asObservable();
  }

  getAiRefreshListener() {
    return this.aiRefreshSubject.asObservable();
  }

  postHomeScroll(top: number) {
    this.homeScrollSubject.next(top);
  }

  postAiRefresh(target: string) {
    this.aiRefreshSubject.next(target);
  }

  getHomeScrollToListener() {
    return this.homeScrollToSubject.asObservable();
  }

  postHomeScrollTo(top: number) {
    this.homeScrollToSubject.next(top);
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  dynamicSort(property: string, direction = 'asc') {
    var sortOrder = 1;
    if (direction !== 'asc') {
      sortOrder = -1;
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  async hasReadPermission() {
    // export declare type PermissionState = 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied' | 'limited';
    const havePersmission = await Camera.checkPermissions();
    return havePersmission && ('granted' == havePersmission.photos || 'limited' == havePersmission.photos);
  }

  async requestReadPermission() {
    const requestResult = await Camera.requestPermissions({ permissions: ['photos'] });
    if (requestResult) {
      return requestResult.photos;
    } else {
      return false;
    }
  }

  async image_read_permission_process() {
    let has_permission;
    try {
      has_permission = await this.hasReadPermission();

      console.log('has permission', has_permission);
    } catch (e) {
      alert('사진권한을 확인하는데 실패했습니다.');
      console.log('has read permission err : ', e);
      return false;
    }
    if (!has_permission) {
      let result;
      try {
        result = await this.requestReadPermission();
        console.log('native test 222 done', result);
      } catch (e) {
        alert('사진권한을 요청하는데 실패했습니다.');
        console.log('request read permission err : ', JSON.stringify(e));
        return false;
      }
      if (result === false) {
        return false;
      } else if (typeof result == 'string' && (<string>result).includes('denied')) {
        alert('앨범 접근이 거부되어있습니다. 설정 > 기프티스타 > 사진에서 읽기 및 쓰기를 허용해주세요.');
        return false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // async clearCache() {
  //   const fileEntries = await Filesystem.readdir({
  //     directory: FilesystemDirectory.Cache,
  //     path: 'CACHED-IMG'
  //   });

  //   fileEntries.files.map(async (f) => {
  //     console.log('Delete: ', f);

  //     await Filesystem.deleteFile({
  //       directory: FilesystemDirectory.Cache,
  //       path: `CACHED-IMG/${f}`
  //     });
  //   });
  // }
}
