<ion-content tappable (click)="clickCancel()"> </ion-content>
<ion-footer class="login-modal-footer">
  <ion-item class="ion-no-border ion-no-margin ion-no-padding login-modal-footer-header" lines="none">
    <ion-buttons slot="end" class="ion-no-margin ion-no-padding" style="margin-left: 12px; margin-top: 12px">
      <ion-button (click)="clickCancel()" class="ion-no-margin ion-no-padding" style="--padding-end: 0; --padding-start: 0">
        <img src="assets/icon/icon_close_no_back.svg" style="height: 24px" />
      </ion-button>
    </ion-buttons>
  </ion-item>

  <div class="contents-wrapper" style="background: #fff; padding-bottom: 13px">
    <div class="login-modal-text">{{ this.current_modal_text }} <br /></div>
    <div class="login-modal-text" style="margin-top: 12px">
      <span class="bold">지금 가입하면 </span>
      <span style="color: var(--ion-color-scuba-1); font-weight: bold">{{ point_amount | number }}원 쿠폰팩 </span>
      <span class="bold">을 드립니다</span>
    </div>
    <ion-button style="margin-bottom: 10px; margin-top: 24px" class="ion-no-margin login-modal-button" color="warning" expand="block" size="large" (click)="clickLogin('kakao')">
      <ion-icon slot="start" class="login-button-icon" src="assets/img/login/kakaotalk_logo.svg"></ion-icon>
      <span>카카오로 1초만에 시작하기</span>
    </ion-button>
    <ion-button
      [hidden]="!showAppleSignIn"
      style="--background: black; height: 48px; --border-radius: 4px"
      class="ion-no-margin login-modal-button"
      expand="block"
      size="large"
      (click)="clickLogin('apple')"
    >
      <ion-icon slot="start" class="login-button-icon" src="assets/img/login/apple_logo.svg"></ion-icon>
      <span>Apple로 계속하기</span>
    </ion-button>

    <div class="safe-area"></div>
  </div>
</ion-footer>
