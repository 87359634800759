import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';

import { AppService } from '../services/app.service';
import { ChatService } from '../services/chat.service';

@Injectable({
  providedIn: 'root'
})
export class FBAuthGuard implements CanActivate {
  constructor(private auth: Auth, private router: Router, private toastCtrl: ToastController, private app: AppService, private chat_service: ChatService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async (user) => {
        if (user) {
          resolve(true);
        } else {
          const user = await this.app.getCurrentUser();
          if (user) {
            // const result = await this.chat_service.login();
            // if (result) {
            //   resolve(true);
            // } else {
            //   this.app.justGo('user/login', {
            //     return_url: state.url
            //   });
            //   reject(false);
            // }
            resolve(true);
          } else {
            this.app.justGo('user/login', { return_url: state.url });
            reject(false);
          }
        }
      });
    });
  }
}
