import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  bypassToken = {
    youtube: 'www.googleapis.com'
  };

  constructor(
    private app: AppService,
    private storage: Storage,

    private router: Router,
    public toastController: ToastController,
    public alertController: AlertController
  ) {}

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    // if your getAuthToken() function declared as "async getAuthToken() {}"
    const token = await this.storage.get('token');
    const requestUrl = request.url.includes('http') ? new URL(request.url) : null;

    if (requestUrl && !Object.values(this.bypassToken).includes(requestUrl.host) && token) {
      request = request.clone({
        setHeaders: {
          token: token
        }
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next
      .handle(request)
      .pipe(
        flatMap(async (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.errors) {
              let message = event.body.errors[0].message ? event.body.errors[0].message : '알 수 없는 에러가 발생했습니다!';
              if (message === `Access denied! You don't have permission for this action!`) {
                this.app.showToast(message, 300);
                await this.app.logout();
                this.app.routingError(this.router.url);
              } else {
                this.app.showToast(message, 300);
              }
            }
          }
          return event;
        })
      )
      .toPromise();

    // return next.handle(request).pipe(
    //   flatMap(async (event: HttpEvent<any>) => {
    //     if (event instanceof HttpResponse) {
    //       if (event.body.errors) {
    //         console.log('catchError interceptor error', event.body.errors[0].message);
    //         let message = event.body.errors[0].message ? event.body.errors[0].message : '알 수 없는 에러가 발생했습니다!';
    //         console.log('message', message);
    //         if (message === `Access denied! You don't have permission for this action!`) {
    //           message = '앗! 로그인이 필요합니다. 다시 로그인해 주세요🙂';
    //           this.app.showToast(message, 300);

    //           this.app.logoutTokenOnly();
    //           this.app.justGo('user/login', {
    //             redirect_url: this.router.url
    //           });
    //         } else {
    //           this.app.showToast(message, 300);
    //         }
    //       }
    //     }
    //     return event;
    //   }),
    //   catchError(async (error: HttpErrorResponse) => {
    //     console.log('catchError interceptor error');
    //     if (error.status === 401) {
    //       this.app.justGo('/');
    //     }
    //     let message = error.error.errors ? error.error.errors[0].message : '알 수 없는 에러가 발생했습니다!';
    //     if (message === `Access denied! You don't have permission for this action!`) {
    //       message = '앗! 로그인이 필요합니다. 다시 로그인해 주세요🙂';
    //       this.app.logoutTokenOnly();
    //       this.app.showToast(message, 300);
    //       this.app.justGo('user/login', {
    //         redirect_url: this.router.url
    //       });
    //     } else {
    //       this.app.showToast(message, 300);
    //     }

    //     return {
    //       error: error.error.data,
    //       messsage: error.error.errors ? error.error.errors[0].message : null,
    //       type: null
    //     };
    //   })
    // );
    // Important: Note the .toPromise()
    // return next.handle(authReq).toPromise();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }
}
