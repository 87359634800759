import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonModalComponent } from './common-modal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [CommonModalComponent],
  imports: [CommonModule, IonicModule],
  exports: [CommonModalComponent]
})
export class CommonModalModule {}
