import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { AppUpdate, AppUpdateAvailability } from '@robingenz/capacitor-app-update';

import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { HttpClient } from '@angular/common/http';
import { AdId } from 'capacitor-adid-idfa';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { collectionData, doc, docData, Firestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  private newPortal = new BehaviorSubject(false);
  private newNative = new BehaviorSubject(false);

  need_check = false;
  check_new_portal_count = 0;

  is_initialized = false;
  constructor(public alertController: AlertController, private http: HttpClient, private fbstore: Firestore, private router: Router) {}

  // {"currentVersion":"2","availableVersion":"0","updateAvailability":1,"updatePriority":0,"immediateUpdateAllowed":false,"flexibleUpdateAllowed":false}

  getNewPortalListener() {
    this.init();
    return this.newPortal.asObservable();
  }
  getNewNativeListener() {
    this.init();
    return this.newNative.asObservable();
  }

  async init() {
    if (!this.is_initialized) {
      this.is_initialized = true;
      const platform = Capacitor.getPlatform();
      if (platform !== 'web') {
        docData(doc(this.fbstore, `config_service/portal_version`)).subscribe(async (observer) => {
          if (observer && observer.key_value) {
            const portal_version = `${observer.key_value}`.trim();
            const local_version = `${environment.package_app_version}`.trim();
            if (local_version != portal_version) {
              this.need_check = true;
              this.checkNewPortal();
            }
          }
        });

        docData(doc(this.fbstore, `config_service/${platform}_min_version`)).subscribe(async (observer) => {
          if (observer && observer.key_value) {
            const min_version = Number(observer.key_value);
            this.checkNativeVersion(min_version);
          }
        });

        this.router.events.subscribe(async (e) => {
          if (e instanceof NavigationEnd) {
            if (this.need_check) {
              if (this.check_new_portal_count % 10 == 5) {
                this.checkNewPortal();
              }
              this.check_new_portal_count++;
            }
          }
        });
      }
    }
  }

  checkNewPortal() {
    this.http.get(`/assets/portal_version?time=${new Date().getTime()}`, { responseType: 'text' }).subscribe((res) => {
      if (`${environment.package_app_version}`.trim() !== `${res}`.trim()) {
        this.newPortal.next(true);
      }
    });
  }

  async checkNativeVersion(min_version: number) {
    // const result = await AppUpdate.getAppUpdateInfo();
    const info = await App.getInfo();
    if (info && info.build) {
      const current_version = Number(info.build);
      if (min_version > current_version) {
        this.newNative.next(true);
      } else {
        this.newNative.next(false);
      }
    }

    // if (result && result.currentVersion) {
    //   alert('current_version:' + result.currentVersion);
    //   const current_version = Number(result.currentVersion);

    //   if (min_version > current_version) {
    //     alert('current_version:' + current_version + ', min_version:' + min_version);

    //     this.newNative.next(true);
    //     // this.showUpdateButton = true;
    //     // await this.app.showAlert('스토어업데이트', '최신버전으로 업데이트 해주세요', ['확인']);
    //     // this.app.clickGoUpdate();
    //   }
    // }
  }

  async getAppVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    return result;
  }

  async checkAndUpdateApp() {
    const result = await this.getAppVersion();
    if (result && result.updateAvailability == AppUpdateAvailability.UPDATE_AVAILABLE) {
      this.nativeUpdateAlert();
    }
  }

  async updateProcess() {
    const result = await this.getAppVersion();
    if (Capacitor.getPlatform() == 'android') {
      if (result.flexibleUpdateAllowed) {
        this.startFlexibleUpdate();
      } else {
        if (result.immediateUpdateAllowed) {
          this.performImmediateUpdate();
        } else {
          this.openAppStore();
        }
      }
      this.openAppStore();
    } else {
      this.openAppStore();
    }
  }

  async nativeUpdateAlert() {
    const alert = await this.alertController.create({
      header: '새 버전 출시',
      message: '새로운 버전으로 업데이트해야 사용이 가능합니다',
      backdropDismiss: false,
      buttons: [
        {
          text: '네',
          handler: () => {
            this.updateProcess();
          }
        },
        {
          text: '다음에'
        }
      ]
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

  async getAvailableAppVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    console.log('result', JSON.stringify(result));
    return result.availableVersion;
  }

  async openAppStore() {
    await AppUpdate.openAppStore();
  }

  async performImmediateUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
  }

  async startFlexibleUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
  }

  async completeFlexibleUpdate() {
    await AppUpdate.completeFlexibleUpdate();
  }
}
