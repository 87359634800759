import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppGuard } from './guards/app.guard';
import { AuthGuard } from './guards/auth.guard';
import { FBAuthGuard } from './guards/fbauth.guard';
import { LoginGuard } from './guards/login.guard';
import { TempAuthGuard } from './guards/temp_auth.guard';

import { LinkifyComponent } from './pages/user/linkify/linkify.component';

const legacy_redirect = [
  {
    path: 'store/item/item-detail',
    redirectTo: 'store/item-detail',
    pathMatch: 'full'
  },
  {
    path: 'main/home/item-detail',
    redirectTo: 'store/item-detail',
    pathMatch: 'full'
  },
  {
    path: 'main/home/shop-item-page',
    redirectTo: 'store/shop-item-page',
    pathMatch: 'full'
  },
  {
    path: 'order-list',
    redirectTo: 'store/order-list',
    pathMatch: 'full'
  },
  {
    path: 'main/style/post-detail',
    redirectTo: 'style/post-detail',
    pathMatch: 'full'
  },
  {
    path: 'main/style/post-detail/:post_idx',
    redirectTo: 'style/post-detail/:post_idx',
    pathMatch: 'full'
  },
  {
    path: 'store/shop/shop-item-page',
    redirectTo: 'store/shop-item-page',
    pathMatch: 'full'
  },
  {
    path: 'main/my/review-list',
    redirectTo: 'ad/1061',
    pathMatch: 'full'
  }
];

const routes: Routes = [
  ...legacy_redirect,

  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then((m) => m.SplashPageModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'live-tv',
    loadChildren: () => import('./pages/live-tv/live-tv.module').then((m) => m.LiveTvPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.module').then((m) => m.StoreModule),
    canActivate: [TempAuthGuard]
  },
  {
    path: 'style',
    loadChildren: () => import('./pages/style/style.module').then((m) => m.StyleModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then((m) => m.UserPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
    canActivate: [AppGuard, AuthGuard, FBAuthGuard]
  },
  {
    path: 'main/:tab/:target/:index',
    loadChildren: () => import('./pages/legacy-redirect/legacy-redirect.module').then((m) => m.LegacyRedirectPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'main/:tab/:target',
    loadChildren: () => import('./pages/legacy-redirect/legacy-redirect.module').then((m) => m.LegacyRedirectPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'policy/:key_name',
    loadChildren: () => import('./pages/user/policy/policy.module').then((m) => m.PolicyModule)
  },
  {
    path: 'temp/login-proxy',
    loadChildren: () => import('./pages/user/login-proxy/login-proxy.module').then((m) => m.LoginProxyPageModule)
  },
  {
    path: 'temp/login',
    loadChildren: () => import('./pages/user/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'linkify',
    component: LinkifyComponent
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/store/home-tabs/home-tabs.module').then((m) => m.HomeTabsPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'recent',
    loadChildren: () => import('./pages/store/recent/recent.module').then((m) => m.RecentModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'store/cart',
    loadChildren: () => import('./pages/store/cart/cart.module').then((m) => m.CartModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'my',
    loadChildren: () => import('./pages/user/my/my.module').then((m) => m.MyModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'ad/:onelink_idx',
    loadChildren: () => import('./pages/user/ad/ad.module').then((m) => m.AdPageModule)
  },

  {
    path: '',
    redirectTo: 'app/home',
    pathMatch: 'full'
  },
  {
    path: 'beta',
    loadChildren: () => import('./pages/tabs-beta/tabs-beta.module').then((m) => m.TabsBetaPageModule),
    canActivate: [AppGuard, TempAuthGuard]
  },
  {
    path: 'component-list',
    loadChildren: () => import('./pages/design/component-list/component-list.module').then((m) => m.ComponentListPageModule)
  },

  {
    path: 'fumist',
    loadChildren: () => import('./pages/fumist/fumist.module').then((m) => m.FumistPageModule),
    canActivate: [AppGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
