import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

import { AlertController, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginPage implements OnInit {
  showAppleSignIn = false;
  return_url: string;
  loading;
  show_login_proxy = false;
  processing = false;
  showNoLogin = false;

  constructor(public app: AppService, public modalCtrl: ModalController, private login: LoginService, private alertController: AlertController, public route: ActivatedRoute, public router: Router) {}

  async ngOnInit() {
    this.show_login_proxy = environment.show_login_proxy;
    const check = this.app.getQueryParam(this.route, 'return_url');
    if (check) {
      this.return_url = decodeURIComponent(check);
    }

    if (!this.return_url) {
      this.return_url = '/';
    }
    if (this.return_url.startsWith('/temp/login') || this.return_url.startsWith('temp/login')) {
      this.return_url = '/';
    }
    this.initData();
  }

  async initData() {
    this.showAppleSignIn = Capacitor.getPlatform() == 'ios';
    console.log('currentUserIdx', await this.app.getUserIdx());
    // this.showNoLogin = Capacitor.getPlatform() == 'web' || !environment.production;
  }

  async clickLogout() {
    // await this.app.logout();
  }

  async clickLogin(login_type: 'kakao' | 'apple') {
    this.processing = true;
    let social_access_token = null;
    try {
      social_access_token = await this.login.login(login_type);
    } catch (error) {
      alert('로그인 실패');
    }

    let login_result = false;
    if (social_access_token) {
      const temp = await this.app.showLoading('로그인중입니다');
      try {
        login_result = await this.app.loginSocial(login_type, social_access_token);
        if (login_result) {
          // this.app.broadcast.next({
          //   type: this.app.broadcast_message.REFRESH_USER_DATA,
          //   payload: null
          // });
          // this.app.getCartCount();
        }
      } catch (error) {
        alert('푸미 로그인 실패');
      }
      temp.dismiss();
    }

    this.processing = false;

    if (login_result) {
      this.app.parseUrlAndjustGo(this.return_url);
    }
  }

  async clickNotLogin() {
    this.app.justGo('/');
  }

  clickTest() {
    this.app.justGo('store/shop-item-page/917');
  }
}
