import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  doc,
  Firestore,
  collection,
  collectionData,
  updateDoc,
  docData,
  FieldValue,
  serverTimestamp as fbstoreServerTimestamp,
  setDoc,
  getDocFromCache,
  getDocFromServer
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { signInWithCustomToken, signOut } from '@firebase/auth';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
import { DataProviderService } from './graphql/data-provider.service';
import { DeviceService } from './device.service';
import { OnesignalService } from './onesignal.service';
import { onValue, Database, onDisconnect, ref, serverTimestamp as fbdbServerTimestamp, set, update } from '@angular/fire/database';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilService } from './util.service';
import { switchMap } from 'rxjs/operators';
import { Capacitor3KakaoAd } from 'capacitor3-kakao-ad';
@Injectable({
  providedIn: 'root'
})
export class FirebaseUserService {
  user;
  loginHolder = [];
  loginProcessing = false;

  deviceIdxListener = [];
  deviceUploadingProcessing = false;

  private userSubject = new BehaviorSubject(null);

  private deviceSubject = new BehaviorSubject(null);

  private authSubject$: BehaviorSubject<string | null>;

  constructor(
    private fbauth: Auth,
    private onesignal_service: OnesignalService,
    private db: DataProviderService,
    private fns: Functions,
    private fbstore: Firestore,
    private fbdb: Database,
    private router: Router,
    private storage: Storage,
    private utils: UtilService,
    private device_service: DeviceService
  ) {
    this.authSubject$ = new BehaviorSubject(null);

    this.fbauth.onAuthStateChanged(async (auth_user) => {
      if (auth_user) {
        this.authSubject$.next(auth_user.uid);
      } else {
        this.user = null;
        this.authSubject$.next(null);
      }
    });

    this.authSubject$
      .pipe(
        switchMap((uid) => {
          return docData(doc(this.fbstore, `user/${uid}`));
        })
      )
      .subscribe(async (observer) => {
        if (observer) {
          this.user = observer;
          this.userSubject.next(this.user);
          this.registOnlineStatus(this.user.user_idx);
        } else {
          this.user = null;
          this.userSubject.next(null);
        }
      });
  }

  public async refreshUserData() {
    if (await this.getUserIdx()) {
      const user_data: any = await this.getUserFromData(`${this.user.user_idx}`);
      this.user = user_data;
      this.userSubject.next(user_data);
      return this.user;
    } else {
      return await this.getCurrentUser();
    }
  }

  public getUserDataChangeListner() {
    return this.userSubject.asObservable();
  }
  public getUserDeviceDataChangeLister() {
    return this.deviceSubject.asObservable();
  }

  public async getUserIdx() {
    return (await this.getCurrentUser()).user_idx;
  }

  public async resetUserDeviceIdx() {
    await this.storage.remove('user_device_idx');
    await this.getUserDeviceIdx();
  }

  public getUserDeviceIdx(): Promise<number> {
    return new Promise(async (resolve, reject) => {
      //디바이스 등록된 정보가 있으면 바로 리턴

      //디바이스 스크린 정보 없는 사용자 정보 등록을 위해
      const check_device_screen = await this.storage.get('check_device_screen');
      if (!check_device_screen) {
        await this.storage.set('check_device_screen', `${new Date().getTime()}`);
        await this.storage.remove('user_device_idx');
      }

      let user_device_idx = await this.storage.get('user_device_idx');

      if (user_device_idx) {
        resolve(user_device_idx);
      } else {
        this.deviceIdxListener.push(resolve);
        if (!this.deviceUploadingProcessing) {
          this.deviceUploadingProcessing = true;
          await this.upsertDeviceInfo();
          user_device_idx = await this.storage.get('user_device_idx');

          for (let i = 0; i < this.deviceIdxListener.length; i++) {
            this.deviceIdxListener[i](user_device_idx);
          }
          this.deviceUploadingProcessing = false;
          this.deviceIdxListener = [];
        }
      }
    });
  }

  public async upsertDeviceInfo() {
    const user_idx = await this.getUserIdx();
    const device = await this.device_service.getDeviceInfo();
    const player_id = await this.onesignal_service.getPlayerId();
    const portal_version = environment.package_app_version;

    const screen_width = window.innerWidth;
    const screen_height = window.innerHeight;
    const pixel_ratio = window.devicePixelRatio;

    const device_data = {
      user_idx,
      player_id,
      uuid: device.uuid,
      platform: device.platform,
      os: device.os,
      name: device.name,
      app_build: device.app_build,
      app_version: device.app_version,
      adid: device.adid,
      idfa: device.idfa,
      portal_version,
      screen_width,
      screen_height,
      pixel_ratio
    };

    const result = await this.db.insert_user_device(device_data);
    const user_device_idx = result.user_device_idx;

    this.deviceSubject.next({ ...device_data, user_device_idx });
    await this.storage.set('user_device_idx', user_device_idx);
    return user_device_idx;
  }

  //유저 서비스 초기화
  async userServiceInit() {
    return await this.getUserIdx();
  }

  /**
   * 유저 정보를 무조건 가져온다 없으면 임시 유저 생성
   * @returns
   */
  getCurrentUser(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.user) {
        resolve(this.user);
      } else {
        this.loginHolder.push(resolve);
        if (!this.loginProcessing) {
          this.loginProcessing = true;

          let user_data = await this.getUserDataFromFirestore();

          //토큰도 체크해야함
          const token = await this.storage.get('token');

          if (!user_data || !token) {
            //로그인 사용자 정보가 없으므로 임시 로그인 한다
            await this.loginTempUser();
            user_data = await this.getUserDataFromFirestore();
          }

          if (!user_data) {
            reject('no user date created');
          }

          for (let i = 0; i < this.loginHolder.length; i++) {
            this.loginHolder[i](user_data);
          }

          this.loginProcessing = false;
          this.loginHolder = [];
        }
      }
    });
  }

  /**
   * 카카카오 로그인
   * @param login_type
   * @param social_access_token
   * @returns
   */
  async loginSocial(login_type: string, social_access_token: string) {
    const result: any = await this.loginTempUserToUser(login_type, social_access_token);
    return result;
  }

  /**
   * 임시 로그인
   * social social_access_token 이 있으면 로그인 없으면 회원가입
   * @returns
   */
  async loginTempUser() {
    try {
      let result = await this.db.api(`/api/user/signup_temp_user`, { login_type: 'temp' });
      // console.log('loginTempUser', result);
      // if (result && result.data && result.data.token) {
      //   await this.storage.set('social_access_token', social_access_token);
      // }
      // const temp = await this.db.login_temp_user('temp', social_access_token);

      if (result && result.data && result.data.token) {
        await this.loginLegacy(result.data.token);
      }

      let { web_token } = result.data;
      // console.log('will login web_token!!!', web_token);
      await this.loginCustomToken(web_token);

      //카카오 ad 임시 확인
      if (Capacitor.getPlatform() !== 'web') {
        Capacitor3KakaoAd.signUp({});
      }

      return result;
    } catch (error) {
      alert('login system have problems:' + JSON.stringify(error));
      await this.logout();
      return null;
    }
  }

  async resetTempUser() {
    try {
      await this.logout();
      await this.loginTempUser();
    } catch (error) {
      console.error('resetTempUserError', JSON.stringify(error));
    }
  }

  /**
   * 정식 로그인
   * @param login_type
   * @param social_access_token
   * @returns
   */
  async loginTempUserToUser(login_type: string, social_access_token: string) {
    // const callable = httpsCallable(this.fns, 'login_temp_user_to_user');
    // const result = await callable({ login_type, social_access_token });
    // return result.data;

    try {
      //오프라인 상태로 처리
      let check_user = await this.getCurrentUser();
      await this.makeOfflineStatus(check_user.user_idx);

      if (check_user.login_type !== 'temp') {
        await this.logout();
        check_user = await this.getCurrentUser();
      }
      this.user = null;
      const temp = await this.db.login_temp_user_to_user(login_type, social_access_token);
      if (!(temp.web_token && temp.token)) {
        throw Error('no loginTempUserToUser uid');
      }
      //회원가입
      if (temp.status == 'sign_up') {
      }
      //로그인
      else if (temp.status == 'login') {
      }

      await this.loginLegacy(temp.token);
      await this.loginCustomToken(temp.web_token);
      check_user = await this.getUserDataFromFirestore();
      this.user = check_user;

      if (!check_user) {
        throw Error('no user data');
      }

      if (check_user && check_user.login_type == 'temp') {
        throw Error('socal login error login_type:' + check_user.login_temp);
      }

      return true;
    } catch (error) {
      alert('login error!' + JSON.stringify(error));
      await this.resetTempUser();
      return false;
    }
  }

  //현재 로그인 정보로 사용자 정보 가져오기
  getUserDataFromFirestore() {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.fbauth.onAuthStateChanged(async (user: any) => {
        unsubscribe();

        if (user && user.uid) {
          const data = await this.getUserFromData(user.uid);
          resolve(data);
        } else {
          resolve(null);
        }
      });
    });
  }

  /**
   *
   * user table 에 있는 유저 정보 가져오기
   * @param uid
   * @returns
   */
  getUserFromData(uid: string) {
    return new Promise(async (resolve, reject) => {
      const serverData = await getDocFromServer(doc(this.fbstore, `user/${uid}`));
      let user_data = null;
      if (serverData) {
        user_data = serverData.data();
        resolve(user_data);
      }
      if (!user_data) {
        const unsubscribe = docData(doc(this.fbstore, `user/${uid}`)).subscribe((observer) => {
          unsubscribe.unsubscribe();
          resolve(observer);
        });
      }
    });
  }

  async loginCustomToken(token: string) {
    try {
      const userCrediention = await signInWithCustomToken(this.fbauth, token);
      if (!userCrediention.user) {
        throw Error('no_user_data:' + JSON.stringify(userCrediention));
      }

      console.log('userCrediention', userCrediention);

      this.resetUserDeviceIdx();

      return userCrediention.user;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 로그 아웃은 임시 유저로 변경하는 역할
   */
  async logout() {
    let check_user = await this.getCurrentUser();
    if (check_user) {
      try {
        await this.makeOfflineStatus(check_user.user_idx);
      } catch (error) {
        console.error(error);
      }
    }

    this.user = null;
    await this.storage.remove('social_access_token');
    await this.storage.remove('token');

    await this.logoutLegacy();
    try {
      await this.fbauth.signOut();
    } catch (error) {
      console.log('sign out error', error);
    }

    return true;
  }

  /**
   * legacy 서버 통신을 위한 토큰 저장
   * @param token
   * @returns
   */
  async loginLegacy(token: string) {
    try {
      await this.storage.set('token', token);
      // window.localStorage.setItem('token', token);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async logoutLegacy() {
    try {
      await this.storage.remove('token');
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async updateCurrentUrl(current_url: string) {
    try {
      if (!current_url) {
        current_url = '/';
      }

      let user_idx = await this.getUserIdx();
      const userStatusFirestoreRef = doc(this.fbstore, `/status/${user_idx}`);
      const host = current_url.split('?')[0];
      let data = { host, current_url, status: 'online', last_changed: new Date() };
      data = this.utils.filterFbObject(data);
      await setDoc(userStatusFirestoreRef, data, { merge: true });
    } catch (error) {
      console.error('updateCurrentUrl error', error);
    }
  }

  private async makeOfflineStatus(user_idx) {
    const userStatusDatabaseRef = ref(this.fbdb, `/status/${user_idx}`);
    const userStatusFirestoreRef = doc(this.fbstore, `/status/${user_idx}`);
    const isOfflineForFirestore = {
      status: 'offline',
      last_changed: fbstoreServerTimestamp()
    };
    const isOfflineForDatabase = {
      status: 'offline',
      last_changed: fbdbServerTimestamp()
    };

    try {
      await setDoc(userStatusFirestoreRef, isOfflineForFirestore, { merge: true });
      await update(userStatusDatabaseRef, isOfflineForDatabase);
    } catch (error) {
      console.error(error);
    }
  }

  private registOnlineStatus(user_idx: number) {
    const userStatusDatabaseRef = ref(this.fbdb, `/status/${user_idx}`);
    const userStatusFirestoreRef = doc(this.fbstore, `/status/${user_idx}`);
    const platform = Capacitor.getPlatform();
    const isOfflineForFirestore = {
      ...this.user,
      status: 'offline',
      platform,
      last_changed: fbstoreServerTimestamp()
    };
    const isOnlineForFirestore = {
      ...this.user,
      status: 'online',
      platform,
      last_changed: fbstoreServerTimestamp()
    };
    const isOfflineForDatabase = {
      ...this.user,
      status: 'offline',
      platform,
      last_changed: fbdbServerTimestamp()
    };
    const isOnlineForDatabase = {
      ...this.user,
      status: 'online',
      platform,
      last_changed: fbdbServerTimestamp()
    };
    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    onValue(ref(this.fbdb, `.info/connected`), (snapshot) => {
      if (snapshot.val() == false) {
        setDoc(userStatusFirestoreRef, isOfflineForFirestore, { merge: true });
        return;
      }
      onDisconnect(userStatusDatabaseRef)
        .set(isOfflineForDatabase)
        .then(function () {
          setDoc(userStatusFirestoreRef, isOnlineForFirestore, { merge: true });
          set(userStatusDatabaseRef, isOnlineForDatabase);
        });
    });
  }
}
