import { gql } from 'apollo-angular';

export const chatRoomFragment = gql`
  fragment chatRoomFragment on ChatRoom {
    chat_room_idx
    master_user_idx
    chat_room_key
    chat_room_type
    chat_room_title
    chat_room_img_url
    recent_message
    join_user_count
    ref_idx
    ref_table
    regist_datetime
    update_datetime
  }
`;

export const chatJoinFragment = gql`
  fragment chatJoinFragment on ChatJoin {
    chat_join_idx
    user_idx
    chat_room_idx
    join_status
    is_on
    new_message_count
    push_alarm_status
    heart_beat_datetime
    reject_message
    regist_datetime
    update_datetime
  }
`;

export const chatJoinWithRoomFragment = gql`
  fragment chatJoinWithRoomFragment on ChatJoin {
    ...chatJoinFragment
    chat_room {
      ...chatRoomFragment
    }
  }
  ${chatJoinFragment}
  ${chatRoomFragment}
`;

export const itemFragment = gql`
  fragment itemFragment on Item {
    item_idx
    title
    item_origin_price
    item_sell_price
    is_open
    item_image_url
    main_item_resource_idx
    store {
      store_idx
      name
      type
    }
    item_category {
      category_name
    }
    regist_datetime
  }
`;

export const itemThumFragment = gql`
  fragment itemThumFragment on Item {
    item_idx
    title
    item_origin_price
    item_sell_price
    item_image_url
  }
`;

export const couponFragment = gql`
  fragment couponFragment on CouponRule {
    coupon_rule_idx
    coupon_rule_group_idx
    title
    description
    type
    limit_count
    amount
    min_order_amount
    use_ref_table
    use_ref_idx_string
    valid_day
    expire_datetime
    code
    start_datetime
    end_datetime
    regist_datetime
    update_datetime
    issued
  }
`;

export const simplePostFragment = gql`
  fragment simplePostFragment on Post {
    post_idx
    user_idx
    title
    description
    preview_img_url
    reply_count
    like_count
    view_count
    admin_check_status
    open_status
    post_type
    regist_datetime
    update_datetime
  }
`;

export const postFragment = gql`
  fragment postFragment on Post {
    post_idx
    user_idx
    title
    description
    preview_img_url
    reply_count
    like_count
    admin_check_status
    open_status
    post_type
    regist_datetime
    update_datetime
    user {
      user_idx
      user_name
      user_gender
      user_img_url
      user_profile
    }
    post_item_list {
      post_idx
      item_idx
      item {
        item_image_url
        item_origin_price
        item_sell_price
        item_idx
        title
      }
    }
    post_resource_list {
      post_resource_idx
      post_resource_url
      post_resource_type
      width
      height
      color
    }
    like_list {
      user_idx
    }
    reply_list {
      content
      regist_datetime
      user_idx
      user {
        user_idx
        user_name
        user_img_url
      }
    }
    review {
      item {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const select_push_alarm_list = gql`
  query select_push_alarm_list($state: State) {
    select_push_alarm_list(state: $state) {
      count
      rows {
        user_idx
        push_alarm_idx
        push_ref_table
        push_ref_idx
        alarm_type
        alarm_url
        alarm_title
        alarm_message
        user_display_onoff
        alarm_img_url
        alarm_open_datetime
        push_alarm_status
        regist_datetime
      }
    }
  }
`;

export const select_banner_list_by_type = gql`
  query select_banner_list_by_type($state: State, $banner_type: String) {
    select_banner_list_by_type(state: $state, banner_type: $banner_type) {
      count
      rows {
        banner_idx
        banner_title
        banner_location
        banner_link_type
        banner_img_url
        banner_click_count
        banner_onoff
        banner_link_url
        regist_datetime
      }
    }
  }
`;

export const select_notice_list = gql`
  query select_notice_list($state: State!) {
    select_notice_list(state: $state) {
      count
      rows {
        notice_idx
        title
        content
        main_img_url
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_notice_list_title_only = gql`
  query select_notice_list_title_only($state: State!) {
    select_notice_list_title_only(state: $state) {
      count
      rows {
        notice_idx
        title
      }
    }
  }
`;

export const select_tag_list = gql`
  query select_tag_list($state: State!) {
    select_tag_list(state: $state) {
      count
      rows {
        tag_string
        tag_content_count
      }
    }
  }
`;

export const select_search_recommend_list = gql`
  query select_search_recommend_list($state: State!) {
    select_search_recommend_list(state: $state) {
      tag {
        count
        rows {
          tag_string
        }
      }
      post {
        count
        rows {
          post_idx
          preview_img_url
        }
      }
    }
  }
`;

export const select_tag_post_list = gql`
  query select_tag_post_list($state: State!, $tag_string: String!) {
    select_tag_post_list(state: $state, tag_string: $tag_string) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const get_item = gql`
  query get_item($item_idx: Int!) {
    get_item(item_idx: $item_idx) {
      ...itemFragment
    }
  }
  ${itemFragment}
`;

export const get_available_item_coupon_max_amount = gql`
  query get_available_item_coupon_max_amount($item_idx: Int!, $item_sell_price: Int!) {
    get_available_item_coupon_max_amount(item_idx: $item_idx, item_sell_price: $item_sell_price)
  }
`;

export const shop_select_item = gql`
  query shop_select_item($item_idx: Int!, $ref_post_idx: Int) {
    shop_select_item(item_idx: $item_idx, ref_post_idx: $ref_post_idx) {
      item_idx
      title
      description
      item_status
      item_sell_price
      item_origin_price
      item_delivery_fee
      total_stock
      item_status
      is_open
      regist_datetime
      update_datetime
      company_idx
      main_image_resource_idx
      main_video_resource_idx
      store {
        store_idx
        thumbnail_url
        name
      }
      item_category {
        category_name
      }
      main_item_resource {
        item_resource_idx
        item_resource_url
        width
        height
      }
      main_image_resource {
        item_resource_idx
        item_resource_url
        width
        height
      }
      main_video_resource {
        item_resource_idx
        item_resource_url
        width
        height
      }
      item_option_list {
        item_option_idx
        item_option_code
        item_option_barcode
        item_option_name
        item_option_stock
        item_option_plus_sell_price
        item_option_plus_buy_price
      }

      item_resource_list {
        item_resource_link_idx
        item_resource {
          item_resource_url
          width
          height
        }
      }
      review_list {
        review_idx
        user_idx
        item_idx
        user {
          user_idx
          user_name
          user_img_url
        }
        post {
          post_idx
          title
          description
          post_resource_list {
            post_resource_idx
            post_resource_url
            post_resource_type
            width
            height
            color
          }
        }
        rate
        regist_datetime
        update_datetime
      }
      shop_wish {
        shop_wish_idx
        item_idx
      }
      average_rate
      review_count
      max_coupon_amount
    }
  }
`;

export const select_user_list = gql`
  query select_user_list($state: State) {
    select_user_list(state: $state) {
      count
      rows {
        user_idx
        total_following_count
        total_follower_count
        total_post_count
        total_like_count
        user_profile
        source
        user_id
        user_name
        user_img_url
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_follow_list = gql`
  query select_follow_list($type: String!, $user_idx: Int!, $state: State!) {
    select_follow_list(type: $type, user_idx: $user_idx, state: $state) {
      rows {
        from_user_idx
        to_user_idx
        user {
          user_idx
          user_profile
          user_name
          user_img_url
        }
      }
      error
      count
      user {
        user_idx
        user_phone
        login_type
        user_id
        total_post_count
        total_follower_count
        total_following_count
        user_name
        user_img_url
      }
    }
  }
`;

export const select_like_list = gql`
  query select_like_list($state: State, $post_idx: Int!) {
    select_like_list(state: $state, post_idx: $post_idx) {
      count
      rows {
        post_idx
        user_idx
        user {
          user_name
          user_idx
          user_img_url
        }
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_reply_list = gql`
  query select_reply_list($state: State, $post_idx: Int!) {
    select_reply_list(state: $state, post_idx: $post_idx) {
      count
      rows {
        reply_idx
        content
        post_idx
        user_idx
        regist_datetime
        update_datetime
        user {
          user_name
          user_idx
          user_img_url
        }
      }
    }
  }
`;

export const select_reply_list_all = gql`
  query select_reply_list_all($post_idx: Int!) {
    select_reply_list_all(post_idx: $post_idx) {
      count
      rows {
        reply_idx
        content
        post_idx
        user_idx
        parent_reply_idx
        regist_datetime
        update_datetime
        reply_list {
          reply_idx
          content
          post_idx
          user_idx
          parent_reply_idx
          regist_datetime
          update_datetime
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        user {
          user_name
          user_idx
          user_img_url
        }
      }
    }
  }
`;

export const insert_reply = gql`
  mutation insert_reply($data: InputReply!) {
    insert_reply(data: $data) {
      reply_idx
      user_idx
      post_idx
      parent_reply_idx
      content
      admin_check_status
    }
  }
`;

export const update_reply = gql`
  mutation update_reply($data: InputReply!) {
    update_reply(data: $data)
  }
`;

export const search_post_list = gql`
  query search_post_list($state: State) {
    search_post_list(state: $state) {
      count
      rows {
        ...simplePostFragment
      }
    }
  }
  ${simplePostFragment}
`;

export const select_home_post_list = gql`
  query select_home_post_list($state: State, $type: String) {
    select_home_post_list(state: $state, type: $type) {
      count
      rows {
        ...postFragment
      }
    }
  }
  ${postFragment}
`;

export const select_home_post_list_v3 = gql`
  query select_home_post_list_v3($state: State, $type: String) {
    select_home_post_list_v3(state: $state, type: $type) {
      count
      rows {
        ...postFragment
      }
    }
  }
  ${postFragment}
`;

export const select_home_post_list_v4 = gql`
  query select_home_post_list_v4($state: State, $type: String, $last_post_idx: Float) {
    select_home_post_list_v4(state: $state, type: $type, last_post_idx: $last_post_idx) {
      count
      rows {
        ...postFragment
      }
    }
  }
  ${postFragment}
`;

export const add_user = gql`
  mutation add_user($data: InputUser!) {
    add_user(data: $data) {
      user_idx
    }
  }
`;

export const update_user = gql`
  mutation update_user($user_idx: Int!, $data: InputUser!) {
    update_user(user_idx: $user_idx, data: $data) {
      user_idx
    }
  }
`;

export const delete_user = gql`
  mutation delete_user {
    delete_user
  }
`;

export const update_post_open_status = gql`
  mutation update_post_open_status($data: InputPost!) {
    update_post_open_status(data: $data) {
      open_status
    }
  }
`;

export const logout = gql`
  mutation logout {
    logout
  }
`;

export const delete_contest_join = gql`
  mutation delete_contest_join($contest_idx: Int!) {
    delete_contest_join(contest_idx: $contest_idx)
  }
`;

export const insert_access_history = gql`
  mutation insert_access_history($data: InputAccessHistory!) {
    insert_access_history(data: $data)
  }
`;

export const insert_report = gql`
  mutation insert_report($data: InputReport!) {
    insert_report(data: $data) {
      report_idx
    }
  }
`;

export const get_post = gql`
  query get_post($post_idx: Int!) {
    get_post(post_idx: $post_idx) {
      title
      description
      preview_img_url
      open_status
      post_idx
      user_idx
      post_resource_list {
        post_resource_idx
        post_resource_url
        post_resource_type
        width
        height
        color
      }
    }
  }
`;

export const update_post = gql`
  mutation update_post($data: InputPost!, $post_resource_list: [InputPostResource!]!) {
    update_post(data: $data, post_resource_list: $post_resource_list) {
      post_idx
    }
  }
`;

export const get_contest = gql`
  query get_contest($contest_idx: Int!) {
    get_contest(contest_idx: $contest_idx) {
      contest_idx
      contest_title
      content_description
      contest_banner_img_url
      contest_full_img_url
      conest_video_url
      contest_html
      contest_is_open
      contest_start_datetime
      contest_end_datetime
      regist_datetime
      update_datetime
    }
  }
`;

export const get_config_service = gql`
  query get_config_service($key_name: String!) {
    get_config_service(key_name: $key_name) {
      key_value
      key_name
      explain_key
    }
  }
`;

export const get_contest_join = gql`
  query get_contest_join($contest_idx: Int!) {
    get_contest_join(contest_idx: $contest_idx) {
      contest_join_idx
      contest_idx
      user_idx
      post_idx
      join_user_name
      join_user_phone
      agree_policy
      agree_datetime
      regist_datetime
      update_datetime
      post {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        regist_datetime
        update_datetime

        contest_review {
          contest_review_idx
          comment
          final_result
          sms_send_datetime
          push_send_datetime
        }
        user {
          user_name
          user_gender
          user_img_url
          user_profile
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        post_item_list {
          item {
            item_idx
            title

            main_item_resource {
              item_resource_url
              width
              height
              color
            }
          }
        }
        reply_list {
          content
          regist_datetime
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

// address
export const add_address = gql`
  mutation add_address($data: InputAddressBook!) {
    add_address(data: $data) {
      address_book_idx
    }
  }
`;

export const delete_address = gql`
  mutation delete_address($address_book_idx: Int!) {
    delete_address(address_book_idx: $address_book_idx)
  }
`;

export const update_address = gql`
  mutation update_address($data: InputAddressBook!) {
    update_address(data: $data)
  }
`;

export const insert_order_call = gql`
  mutation insert_order_call($item_idx: Int, $ref_post_idx: Int) {
    insert_order_call(item_idx: $item_idx, ref_post_idx: $ref_post_idx) {
      order_call_idx
    }
  }
`;

export const insert_order_cancel = gql`
  mutation insert_order_cancel($data: InputOrderCancel!) {
    insert_order_cancel(data: $data) {
      order_cancel_idx
    }
  }
`;

export const insert_order_cancel_all = gql`
  mutation insert_order_cancel_all($data: InputOrderCancel!) {
    insert_order_cancel_all(data: $data) {
      order_cancel_idx
    }
  }
`;

export const select_recent_topic = gql`
  query select_recent_topic {
    select_recent_topic {
      topic_idx
      topic_title
      topic_img_url
      topic_detail
      topic_onoff
      topic_tag
      topic_start_datetime
      topic_end_datetime
      topic_click_count
      regist_datetime
      update_datetime
    }
  }
`;

export const event_select_event_list_all = gql`
  query event_select_event_list_all($state: State!) {
    event_select_event_list_all(state: $state) {
      count
      rows {
        event_idx
        title
        description
        banner_img_url
        full_img_url
        video_url
        is_open
        html
        status
        start_datetime
        end_datetime
        type
        badge_url
        update_user_idx
        update_datetime
        regist_datetime
      }
    }
  }
`;

export const is_follow = gql`
  query is_follow($to_user_idx: Int!) {
    is_follow(to_user_idx: $to_user_idx)
  }
`;
export const is_like = gql`
  query is_like($post_idx: Int!) {
    is_like(post_idx: $post_idx)
  }
`;

export const insert_ad_proxy = gql`
  mutation insert_ad_proxy($data: InputAdProxy!) {
    insert_ad_proxy(data: $data)
  }
`;

export const select_user_address_book_list = gql`
  query select_user_address_book_list($state: State!) {
    select_user_address_book_list(state: $state) {
      count
      rows {
        address_book_idx
        user_idx
        address_title
        address
        address_detail
        post_code
        receiver_name
        receiver_phone
        receiver_phone_backup
        is_base
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_payment_type_list = gql`
  query select_payment_type_list($state: State!) {
    select_payment_type_list(state: $state) {
      count
      rows {
        payment_type
        is_use
        regist_datetime
        update_datetime
        text
        display_order
      }
    }
  }
`;

export const insert_user_order_transaction_v2 = gql`
  mutation insert_user_order_transaction_v2($data: InputUserOrder!) {
    insert_user_order_transaction_v2(data: $data) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      sales_channel_item_code_idx
      sales_channel_request_number
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      total_count
      total_origin_price
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_use_point
      total_add_point
      total_payment_price
      total_delivery_fee
      total_company_delivery_fee
      payment_type
      payment_idx
      order_status
      payment_status
      payment_confirm_datetime
      order_transaction_memo
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      invoice_delivery_company_name
      invoice_num
      order_num
      delivery_request_datetime
      delivery_regist_invoice_datetime
      ref_idx
      ref_table
    }
  }
`;

export const insert_user_order_transaction_v3 = gql`
  mutation insert_user_order_transaction_v3($data: InputUserOrder!) {
    insert_user_order_transaction_v3(data: $data) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      sales_channel_item_code_idx
      sales_channel_request_number
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      total_count
      total_origin_price
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_use_point
      total_add_point
      total_payment_price
      total_delivery_fee
      total_company_delivery_fee
      payment_type
      payment_idx
      order_status
      payment_status
      payment_confirm_datetime
      order_transaction_memo
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      invoice_delivery_company_name
      invoice_num
      order_num
      delivery_request_datetime
      delivery_regist_invoice_datetime
      ref_idx
      ref_table

      order_item_list {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        order_count
        item_option_text
        sell_price
        origin_price
        buy_price
        admin_plus_price
        user_pay_price
        external_plus_price
        platform_fee
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        invoice
        invoice_num
        invoice_delivery_company_name
        invoice_check_datetime
        order_num
        order_turn
        regist_datetime
        update_datetime
        create_user_idx
        update_user_idx
        manager_user_idx
        delivery_regist_invoice_datetime
        apply_stock
        adjustment_idx
        arrival_source
        app_open_pid
        app_open_campaign
        app_open_adset
        onelink_idx
        ref_idx
        ref_table
        point_ready_idx
        reword_check_datetime
        reword_status
        shop_cart_idx
      }
    }
  }
`;

export const check_pay_result = gql`
  mutation check_pay_result($data: InputUserPaymentCallback!) {
    check_pay_result(data: $data) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      total_count
      total_origin_price
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_payment_fee
      total_use_point
      total_add_point
      total_payment_price
      total_delivery_fee
      total_company_delivery_fee
      total_use_coupon_price
      payment_type
      payment_idx
      order_status
      payment_status
      payment_confirm_datetime
      order_transaction_memo
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      order_num
      order_item_list {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        order_count
        item_option_text
        sell_price
        origin_price
        buy_price
        admin_plus_price
        user_pay_price
        external_plus_price
        platform_fee
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        invoice
        invoice_num
        invoice_delivery_company_name
        invoice_check_datetime
        order_num
        order_turn
        regist_datetime
        update_datetime
        create_user_idx
        update_user_idx
        manager_user_idx
        delivery_regist_invoice_datetime
        apply_stock
        adjustment_idx
        arrival_source
        app_open_pid
        app_open_campaign
        app_open_adset
        onelink_idx
        ref_idx
        ref_table
        point_ready_idx
        reword_check_datetime
        reword_status
        shop_cart_idx
        item {
          category
          category_idx
          is_recommend
          is_open
          size
          store {
            store_idx
            name
            type
          }
        }
        item_option {
          item_option_name
        }
      }
      payment {
        payment_status
        payment_method
        payment_type
        payment_account
      }
      coupon {
        coupon_rule_idx
        coupon_rule {
          ...couponFragment
        }
      }
    }
  }
  ${couponFragment}
`;

export const order_select_order_transaction = gql`
  query order_select_order_transaction($data: InputUserPaymentCallback!) {
    order_select_order_transaction(data: $data) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      total_count
      total_origin_price
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_payment_fee
      total_use_coupon_price
      total_use_point
      total_add_point
      total_payment_price
      total_delivery_fee
      total_company_delivery_fee
      payment_type
      payment_idx
      order_status
      payment_status
      payment_confirm_datetime
      order_transaction_memo
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      order_num
      order_item_list {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        item_option_text
        sell_price
        external_plus_price
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        invoice
        invoice_num
        invoice_delivery_company_name
        regist_datetime
        update_datetime
        item_option {
          item_option_name
        }
      }
      payment {
        payment_status
        payment_method
        payment_type
        payment_account
      }
    }
  }
`;

export const select_user_order_transaction_list = gql`
  query select_user_order_transaction_list($state: State) {
    select_user_order_transaction_list(state: $state) {
      count
      rows {
        order_transaction_idx
        order_object_id
        user_idx
        sales_channel_idx
        sales_channel_order_number
        sales_channel_order_number2
        sales_channel_item_code_idx
        sales_channel_request_number
        ref_user_idx
        ref_post_idx
        address
        address_detail
        post_code
        request
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        order_transaction_title
        order_transaction_img_url
        total_count
        total_origin_price
        total_sell_price
        total_admin_plus_price
        total_external_plus_price
        total_user_pay_price
        total_buy_price
        total_platform_fee
        total_payment_fee
        total_use_point
        total_add_point
        total_payment_price
        total_delivery_fee
        total_company_delivery_fee
        payment_type
        payment_idx
        order_status
        payment_status
        payment_confirm_datetime
        order_transaction_memo
        delivery_status
        auto_order_complete_sms
        auto_payment_complete_sms
        regist_datetime
        update_datetime
        create_user_idx
        update_user_idx
        manager_user_idx
        invoice_delivery_company_name
        invoice_num
        order_num
        delivery_request_datetime
        delivery_regist_invoice_datetime
        ref_idx
        ref_table
      }
    }
  }
`;

export const shop_select_all_shop_section = gql`
  query shop_select_all_shop_section($state: State!) {
    shop_select_all_shop_section(state: $state) {
      count
      rows {
        shop_section_idx
        shop_section_title
        is_open
        display_order
        shop_section_type
        shop_section_items {
          shop_section_item_idx
          shop_section_idx
          item_idx
          post_idx
          display_order
          is_open
          img_url
          item {
            ...itemFragment
          }
          post {
            post_idx
            user_idx
            open_status
          }
        }
      }
    }
  }
  ${itemFragment}
`;

export const shop_select_shop_section = gql`
  query shop_select_shop_section($shop_section_idx: Int!) {
    shop_select_shop_section(shop_section_idx: $shop_section_idx) {
      shop_section_idx
      shop_section_title
      shop_section_img_url
      is_open
      display_order
      shop_section_type
      description
      start_datetime
      end_datetime
      shop_section_items {
        shop_section_item_idx
        shop_section_idx
        item_idx
        post_idx
        display_order
        is_open
        img_url
        item {
          ...itemFragment
        }
        post {
          post_idx
          user_idx
          open_status
        }
      }
    }
  }
  ${itemFragment}
`;

export const shop_select_user_cart_list = gql`
  query shop_select_user_cart_list($state: State!) {
    shop_select_user_cart_list(state: $state) {
      count
      rows {
        shop_cart_idx
        user_idx
        item_option_idx
        total_count
        ref_idx
        ref_table
        arrival_source
        app_open_pid
        app_open_campaign
        app_open_adset
        onelink_idx
        ref_idx
        ref_table
        item_option {
          item_option_idx
          item_option_barcode
          item_idx
          item_option_name
          need_text_option
          text_option_title
          item_option_code
          item_resource_idx
          item_option_stock
          item_option_plus_sell_price
          manager_user_idx
          item {
            ...itemFragment
          }
        }
        regist_datetime
        update_datetime
      }
    }
  }
  ${itemFragment}
`;

export const shop_select_user_cart_list_v2 = gql`
  query shop_select_user_cart_list_v2($state: State!) {
    shop_select_user_cart_list_v2(state: $state) {
      count
      rows {
        shop_cart_idx
        user_idx
        item_option_idx
        total_count
        ref_idx
        ref_table
        arrival_source
        app_open_pid
        app_open_campaign
        app_open_adset
        onelink_idx
        ref_idx
        ref_table
        item_option {
          item_option_idx
          item_option_barcode
          item_idx
          item_option_name
          need_text_option
          text_option_title
          item_option_code
          item_resource_idx
          item_option_stock
          item_option_plus_sell_price
          manager_user_idx
          item {
            ...itemFragment
          }
        }
        regist_datetime
        update_datetime
      }
    }
  }
  ${itemFragment}
`;

export const shop_insert_user_cart = gql`
  mutation shop_insert_user_cart($data: [InputShopCart!]!) {
    shop_insert_user_cart(data: $data)
  }
`;

export const shop_insert_user_cart_v2 = gql`
  mutation shop_insert_user_cart_v2($data: [InputShopCart!]!) {
    shop_insert_user_cart_v2(data: $data) {
      count
      rows {
        shop_cart_idx
      }
    }
  }
`;

export const select_order_transaction_pending = gql`
  query select_order_transaction_pending($state: State!) {
    select_order_transaction_pending(state: $state) {
      count
      rows {
        order_transaction_pending_idx
        title
        user_idx
        order_transaction_idx
      }
    }
  }
`;

export const shop_update_user_cart = gql`
  mutation shop_update_user_cart($data: InputShopCart!) {
    shop_update_user_cart(data: $data) {
      shop_cart_idx
      user_idx
      item_option_idx
      total_count
    }
  }
`;

export const shop_delete_user_cart = gql`
  mutation shop_delete_user_cart($data: [InputShopCart!]!) {
    shop_delete_user_cart(data: $data)
  }
`;

export const shop_delete_user_cart_v2 = gql`
  mutation shop_delete_user_cart_v2($data: [InputShopCart!]!) {
    shop_delete_user_cart_v2(data: $data)
  }
`;

export const shop_select_count_user_cart_list = gql`
  query shop_select_count_user_cart_list {
    shop_select_count_user_cart_list
  }
`;

export const login_social = gql`
  mutation login_social($login_type: String!, $social_access_token: String!) {
    login_social(login_type: $login_type, social_access_token: $social_access_token)
  }
`;

export const login_temp_user = gql`
  mutation login_temp_user($login_type: String!, $social_access_token: String!) {
    login_temp_user(login_type: $login_type, social_access_token: $social_access_token) {
      app_token
      web_token
      token
    }
  }
`;

export const login_temp_user_to_user = gql`
  mutation login_temp_user_to_user($login_type: String!, $social_access_token: String!) {
    login_temp_user_to_user(login_type: $login_type, social_access_token: $social_access_token) {
      app_token
      web_token
      token
      status
    }
  }
`;

export const login_legacy_user_to_user = gql`
  mutation login_legacy_user_to_user {
    login_legacy_user_to_user {
      app_token
      web_token
      token
    }
  }
`;

export const login_social_with_status_v3 = gql`
  mutation login_social_with_status_v3($login_type: String!, $is_app: Boolean!, $temp_user_idx: Float!, $social_access_token: String!) {
    login_social_with_status_v3(login_type: $login_type, is_app: $is_app, temp_user_idx: $temp_user_idx, social_access_token: $social_access_token) {
      token
      status
      point_amount
    }
  }
`;

export const user_insert_error_log = gql`
  mutation user_insert_error_log($data: String!, $type: String!) {
    user_insert_error_log(data: $data, type: $type)
  }
`;

export const user_select_my_data_v2 = gql`
  query user_select_my_data_v2 {
    user_select_my_data_v2 {
      user {
        user_idx
        user_object_id
        login_type
        source
        user_id
        push_count
        user_profile
        total_following_count
        total_follower_count
        social_id
        access_token
        social_access_token
        user_name
        user_phone
        push_alarm_onoff
        user_img_url
        purchase_count
        last_login_datetime
        accept_policy_datetime
        is_black_user
        point_balance
        regist_datetime
        update_datetime
        post_list {
          ...simplePostFragment
        }
        order_item_list {
          order_item_idx
          item_idx
          order_item_payment_status
          order_item_delivery_status
          item {
            ...itemFragment
          }
          order_transaction {
            order_transaction_idx
          }
        }
      }
      review_count
      select_coupon_info {
        count
        is_read
      }
    }
  }
  ${itemFragment}
  ${simplePostFragment}
`;

export const user_select_order_transaction_list = gql`
  query user_select_order_transaction_list($state: State!) {
    user_select_order_transaction_list(state: $state) {
      count
      rows {
        order_transaction_idx
        order_object_id
        user_idx
        sales_channel_request_number
        ref_user_idx
        ref_post_idx
        address
        address_detail
        post_code
        request
        order_user_name
        order_user_phone
        receiver_name
        receiver_phone
        receiver_phone_backup
        order_transaction_title
        order_transaction_img_url
        total_count
        total_sell_price
        total_admin_plus_price
        total_external_plus_price
        total_user_pay_price
        total_buy_price
        total_use_coupon_price
        total_payment_fee
        total_use_point
        total_add_point
        total_payment_price
        total_delivery_fee
        total_company_delivery_fee
        payment_type
        payment_idx
        order_status
        payment_status
        payment_confirm_datetime
        delivery_status
        auto_order_complete_sms
        auto_payment_complete_sms
        regist_datetime
        update_datetime
        invoice_delivery_company_name
        invoice_num
        order_num
        delivery_request_datetime
        delivery_regist_invoice_datetime
        order_item_list {
          order_item_idx
          item_idx
          user_pay_price
          order_item_delivery_status
          order_item_payment_status
          order_count
          invoice
          invoice_num
          invoice_delivery_company_name
          item {
            ...itemThumFragment
          }
          item_option {
            item_option_idx
            item_idx
            item_option_name
          }
        }
      }
    }
  }
  ${itemThumFragment}
`;

export const user_select_order_transaction = gql`
  query user_select_order_transaction($order_transaction_idx: Int!) {
    user_select_order_transaction(order_transaction_idx: $order_transaction_idx) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      sales_channel_item_code_idx
      sales_channel_request_number
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      order_transaction_memo
      total_count
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_use_point
      total_add_point
      total_payment_price
      total_use_coupon_price
      total_delivery_fee
      total_company_delivery_fee
      payment_type
      payment_idx
      order_status
      payment_status
      payment_confirm_datetime
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      invoice_delivery_company_name
      invoice_num
      order_num
      delivery_request_datetime
      delivery_regist_invoice_datetime
      order_item_list {
        order_transaction_idx
        order_item_idx
        item_idx
        user_pay_price
        order_item_delivery_status
        order_item_payment_status
        order_count
        invoice
        invoice_num
        invoice_delivery_company_name
        item {
          ...itemFragment
        }
        item_option {
          item_option_idx
          item_idx
          item_option_name
        }
        review {
          review_idx
        }
      }
      payment {
        payment_status
        payment_method
        payment_type
        payment_account
      }
    }
  }
  ${itemFragment}
`;

export const order_select_pending_order_transaction = gql`
  query order_select_pending_order_transaction($order_transaction_idx: Int!) {
    order_select_pending_order_transaction(order_transaction_idx: $order_transaction_idx) {
      order_transaction_idx
      order_object_id
      user_idx
      sales_channel_idx
      sales_channel_order_number
      sales_channel_order_number2
      sales_channel_item_code_idx
      sales_channel_request_number
      ref_user_idx
      ref_post_idx
      address
      address_detail
      post_code
      request
      order_user_name
      order_user_phone
      receiver_name
      receiver_phone
      receiver_phone_backup
      order_transaction_title
      order_transaction_img_url
      total_count
      total_origin_price
      total_sell_price
      total_admin_plus_price
      total_external_plus_price
      total_user_pay_price
      total_buy_price
      total_platform_fee
      total_payment_fee
      total_use_point
      total_add_point
      total_payment_price
      total_delivery_fee
      total_company_delivery_fee
      total_use_coupon_price
      payment_type
      payment_idx
      coupon_idx
      order_status
      payment_status
      payment_confirm_datetime
      order_transaction_memo
      delivery_status
      auto_order_complete_sms
      auto_payment_complete_sms
      regist_datetime
      update_datetime
      create_user_idx
      update_user_idx
      manager_user_idx
      invoice_delivery_company_name
      invoice_num
      order_num
      device
      delivery_request_datetime
      delivery_regist_invoice_datetime
      ref_idx
      ref_table
      onelink_idx
      app_open_pid
      app_open_campaign
      app_open_adset
      arrival_source
      point_ready_idx
      order_transaction_pending_idx
      order_item_list {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        order_count
        item_option_text
        sell_price
        origin_price
        buy_price
        admin_plus_price
        user_pay_price
        external_plus_price
        platform_fee
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        invoice
        invoice_num
        invoice_delivery_company_name
        invoice_check_datetime
        order_num
        order_turn
        regist_datetime
        update_datetime
        create_user_idx
        update_user_idx
        manager_user_idx
        delivery_regist_invoice_datetime
        apply_stock
        adjustment_idx
        arrival_source
        app_open_pid
        app_open_campaign
        app_open_adset
        onelink_idx
        ref_idx
        ref_table
        point_ready_idx
        reword_check_datetime
        reword_status
        shop_cart_idx
        item {
          main_image_resource {
            item_resource_url
          }
        }
      }
    }
  }
`;

export const user_select_all_post_list = gql`
  query user_select_all_post_list($state: State!) {
    user_select_all_post_list(state: $state) {
      count
      rows {
        ...simplePostFragment
      }
    }
  }
  ${simplePostFragment}
`;

export const select_order_item_list = gql`
  query select_order_item_list($state: State!) {
    select_order_item_list(state: $state) {
      count
      rows {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        item_option_text
        sell_price
        user_pay_price
        external_plus_price
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_count
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        regist_datetime
        update_datetime
        item_option {
          item_option_name
        }
        item {
          main_image_resource {
            item_resource_url
          }
          item_image_url
          item_origin_price
          item_sell_price
          item_idx
          title
        }
        review {
          review_idx
          regist_datetime
        }
      }
    }
  }
`;

export const select_item_review_list = gql`
  query select_item_review_list($state: State!) {
    select_item_review_list(state: $state) {
      count
      average_rate
      review_count
      rows {
        review_idx
        user {
          user_idx
          user_name
          user_img_url
        }
        item_idx
        order_item {
          order_item_idx
          user_idx
          order_transaction_idx
          sales_channel_idx
          item_idx
          item_option_idx
          item_option_text
          sell_price
          user_pay_price
          external_plus_price
          payment_fee
          delivery_fee
          company_delivery_fee
          use_point
          add_point
          order_count
          order_item_status
          order_item_title
          order_item_delivery_status
          order_item_payment_status
          regist_datetime
          update_datetime
          item_option {
            item_option_name
          }
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
        post {
          post_idx
          title
          description
          post_resource_list {
            post_resource_idx
            post_resource_url
            post_resource_type
            width
            height
            color
          }
        }
        rate
        review_type
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const insert_item_review = gql`
  mutation insert_item_review($files: [Upload!]!, $post_data: InputPost!, $review_data: InputReview!, $file_type: String!) {
    insert_item_review(files: $files, post_data: $post_data, review_data: $review_data, file_type: $file_type) {
      post_idx
    }
  }
`;

// export const update_review = gql``;

// export const select_review = gql``;

export const file_upload = gql`
  mutation file_upload($file: Upload!, $container: String!, $file_type: String!) {
    file_upload(file: $file, container: $container, file_type: $file_type) {
      file_name
      file_type
      file_kind
      file_url
      width
      height
    }
  }
`;

export const point_select_point_rule = gql`
  query point_select_point_rule($point_rule_idx: Int!) {
    point_select_point_rule(point_rule_idx: $point_rule_idx) {
      point_rule_idx
      point_rule_title
      valid_day
      limit_count
      point_rule_onoff
      point_amount
      regist_datetime
      update_datetime
    }
  }
`;

export const select_order_item_can_review_list = gql`
  query select_order_item_can_review_list($state: State!) {
    select_order_item_can_review_list(state: $state) {
      count
      rows {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        item_option_text
        sell_price
        user_pay_price
        external_plus_price
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_count
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        regist_datetime
        update_datetime
        item_option {
          item_option_name
        }
        item {
          item_image_url
          item_origin_price
          item_sell_price
          item_idx
          title
        }
        review {
          review_idx
          regist_datetime
        }
      }
    }
  }
`;

export const select_order_item_can_review_list_v2 = gql`
  query select_order_item_can_review_list_v2($state: State!) {
    select_order_item_can_review_list_v2(state: $state) {
      count
      rows {
        order_item_idx
        user_idx
        order_transaction_idx
        sales_channel_idx
        item_idx
        item_option_idx
        item_option_text
        sell_price
        user_pay_price
        external_plus_price
        payment_fee
        delivery_fee
        company_delivery_fee
        use_point
        add_point
        order_count
        order_item_status
        order_item_title
        order_item_delivery_status
        order_item_payment_status
        regist_datetime
        update_datetime
        invoice_delivery_company_name
        order_item_complete_order_datetime
        write_review_datetime
        review_enable_datetime
        invoice_num
        item_option {
          item_option_name
        }
        item {
          item_image_url
          item_origin_price
          item_sell_price
          item_idx
          title
        }
        review {
          review_idx
          regist_datetime
        }
      }
    }
  }
`;

export const update_item_review = gql`
  mutation update_item_review($files: [Upload!]!, $delete_image_list: [Int!]!, $post_data: InputPost!, $review_data: InputReview!, $file_type: String!) {
    update_item_review(files: $files, delete_image_list: $delete_image_list, post_data: $post_data, review_data: $review_data, file_type: $file_type) {
      post_idx
    }
  }
`;

export const point_select_user_total = gql`
  query point_select_user_total {
    point_select_user_total
  }
`;

export const point_select_user_point_list = gql`
  query point_select_user_point_list {
    point_select_user_point_list {
      count
      rows {
        point_idx
        user_idx
        point_rule_idx
        order_transaction_idx
        title
        point_amount
        point_status
        expire_date
        regist_datetime
        update_datetime
      }
      point_balance
      point_to_be_expired
    }
  }
`;

export const user_select_point_ready_list = gql`
  query user_select_point_ready_list {
    user_select_point_ready_list {
      count
      rows {
        point_ready_idx
        user_idx
        point_rule_idx
        order_transaction_idx
        order_item_idx
        title
        description
        point_status
        expire_date
        point_amount
        create_user_idx
        add_ref_table_idx
        add_ref_table
        regist_datetime
        update_datetime
        predict_datetime
        complete_datetime
        point_idx
        memo
      }
    }
  }
`;
export const user_select_point_list = gql`
  query user_select_point_list {
    user_select_point_list {
      count
      rows {
        point_idx
        user_idx
        point_rule_idx
        order_transaction_idx
        title
        point_status
        expire_date
        point_amount
        create_user_idx
        add_ref_table_idx
        add_ref_table
        regist_datetime
        update_datetime
      }
    }
  }
`;
export const shop_select_shop_section_group = gql`
  query shop_select_shop_section_group($state: State!, $shop_section_group_type: String!) {
    shop_select_shop_section_group(state: $state, shop_section_group_type: $shop_section_group_type) {
      count
      rows {
        shop_section {
          shop_section_idx
          shop_section_title
          is_open
          display_order
          shop_section_type
          shop_section_items {
            shop_section_item_idx
            shop_section_idx
            item_idx
            post_idx
            display_order
            is_open
            img_url
            item {
              ...itemFragment
            }
            post {
              post_idx
              user_idx
              open_status
            }
          }
        }
      }
    }
  }
  ${itemFragment}
`;

export const post_select_fumi_tv_list = gql`
  query post_select_fumi_tv_list($state: State!) {
    post_select_fumi_tv_list(state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        video_url
        post_type
        regist_datetime
        update_datetime
        post_item_list {
          item_idx
          post_idx
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
      }
    }
  }
`;

export const post_select_fumi_live_tv_list = gql`
  query post_select_fumi_live_tv_list($state: State!) {
    post_select_fumi_live_tv_list(state: $state) {
      count
      rows {
        post_tv_idx
        post_idx
        item_origin_price
        item_sell_price
        post_tv_tags
        post {
          user_idx
          title
          description
          preview_img_url
          video_url
          post_type
          regist_datetime
          update_datetime
        }
      }
    }
  }
`;

export const post_select_fumi_live_tv_detail = gql`
  query post_select_fumi_live_tv_detail($post_idx: Int!) {
    post_select_fumi_live_tv_detail(post_idx: $post_idx) {
      post_tv_idx
      post_idx
      item_sell_price
      item_origin_price
      post_tv_banner_image_url
      post_tv_tags
      admin_check_status
      regist_datetime
      update_datetime
      video_url
      width
      height
      item_list_title
      post {
        post_idx
        user_idx
        device
        title
        description
        post_type
        video_url
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        regist_datetime
        update_datetime
        post_item_list {
          post_idx
          item_idx
          list_order
          regist_datetime
          update_datetime
          item {
            item_idx
            company_idx
            create_user_idx
            update_user_idx
            manager_user_idx
            delivery_company_idx
            category
            category_idx
            brand_idx
            item_status
            is_open
            is_recommend
            title
            title_internal
            description
            size
            origin
            item_sell_price
            item_buy_price
            item_origin_price
            item_profit_share_price
            platform_fee
            item_delivery_fee
            item_company_delivery_fee
            main_item_resource_idx
            main_video_resource_idx
            main_image_resource_idx
            total_stock
            store_idx
            crawling_url
            memo
            regist_datetime
            update_datetime
            main_image_resource {
              item_resource_idx
              item_idx
              display_order
              item_resource_type
              item_resource_url
              item_resource_content
              filename
              width
              height
              color
              regist_datetime
              update_datetime
            }
          }
        }
      }
    }
  }
`;

export const tag_select_fumi_tv_post_by_tag_string = gql`
  query tag_select_fumi_tv_post_by_tag_string($data: [String!]!, $state: State!) {
    tag_select_fumi_tv_post_by_tag_string(data: $data, state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        video_url
        post_type
        regist_datetime
        update_datetime
        post_item_list {
          item_idx
          post_idx
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
      }
    }
  }
`;

export const post_select_fumi_tv = gql`
  query post_select_fumi_tv($post_idx: Int!) {
    post_select_fumi_tv(post_idx: $post_idx) {
      title
      description
      preview_img_url
      regist_datetime
      update_datetime
      user_idx
      post_idx
      video_url
      next_videos_list {
        title
        description
        preview_img_url
        user_idx
        post_idx
        video_url
        regist_datetime
        update_datetime
      }
      post_item_list {
        item_idx
        post_idx
        item {
          item_idx
          item_origin_price
          item_sell_price
          title
          main_image_resource {
            item_resource_url
          }
          store {
            store_idx
            name
            type
          }
          item_category {
            category_name
          }
        }
      }
    }
  }
`;

export const post_select_best_list_detail = gql`
  query post_select_best_list_detail($post_idx: Int!, $state: State!) {
    post_select_best_list_detail(post_idx: $post_idx, state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_gender
          user_img_url
          user_profile
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        post_item_list {
          item_idx
          post_idx
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

export const post_select_best_list = gql`
  query post_select_best_list($state: State!) {
    post_select_best_list(state: $state) {
      count
      rows {
        ...postFragment
      }
    }
  }
  ${postFragment}
`;

export const post_select_featured_content = gql`
  query post_select_featured_content($idx: Int!) {
    post_select_featured_content(idx: $idx) {
      idx
      post_idx
      is_open
      type
      ref_table
      ref_table_idx
      button_text
      featured_img_url
      regist_datetime
      update_datetime
      post {
        post_idx
        user_idx
        title
        description
        post_type
        preview_img_url
        reply_count
        like_count
        view_count
        open_status
        regist_datetime
        update_datetime
        post_item_list {
          item_idx
          post_idx
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

export const post_select_featured_content_list = gql`
  query post_select_featured_content_list($state: State!) {
    post_select_featured_content_list(state: $state) {
      count
      rows {
        idx
        post_idx
        is_open
        type
        ref_table
        ref_table_idx
        button_text
        featured_img_url
        regist_datetime
        update_datetime
        post {
          ...simplePostFragment
        }
      }
    }
  }
  ${simplePostFragment}
`;

export const shop_select_category_list = gql`
  query shop_select_category_list($state: State!) {
    shop_select_category_list(state: $state) {
      count
      rows {
        category_idx
        category_name
        display_order
        parent_category_idx
        level
        img_url
      }
    }
  }
`;

export const shop_select_item_with_category = gql`
  query shop_select_item_with_category($category_idx: Int!, $state: State!) {
    shop_select_item_with_category(category_idx: $category_idx, state: $state) {
      count
      rows {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const shop_search_item = gql`
  query shop_search_item($state: State!) {
    shop_search_item(state: $state) {
      count
      rows {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const payment_select_card_list = gql`
  query payment_select_card_list {
    payment_select_card_list {
      count
      rows {
        subscribe_card_info_idx
        user_idx
        card_number
        card_code
        card_name
        customer_uid
        is_deleted
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const payment_issue_card = gql`
  mutation payment_issue_card($data: InputIssueBillingKeyInfo!) {
    payment_issue_card(data: $data)
  }
`;

export const payment_delete_card = gql`
  mutation payment_delete_card($data: InputSubscribeCardInfo!) {
    payment_delete_card(data: $data)
  }
`;

export const payment_pay_billkey = gql`
  mutation payment_pay_billkey($data: InputIamportRequestBill!) {
    payment_pay_billkey(data: $data) {
      success
      imp_uid
      merchant_uid
    }
  }
`;

export const local_select_item_recent = gql`
  query local_select_item_recent {
    recent_view_item @client
  }
`;

export const local_insert_item_recent = gql`
  mutation local_insert_item_recent($data: Item!) {
    recent_view_item(data: $data) @client
  }
`;

export const local_select_shop_wish = gql`
  query local_select_shop_wish {
    shop_wish_list @client
  }
`;

export const local_insert_shop_wish = gql`
  query local_insert_shop_wish {
    shop_wish_list @client
  }
`;

export const local_delete_shop_wish = gql`
  query local_delete_shop_wish {
    shop_wish_list @client
  }
`;

export const user_select_user_info = gql`
  query user_select_user_info {
    user_select_user_info {
      user_idx
      height
      top_size
      top_size_gender
      bottom_size
    }
  }
`;

export const user_update_user_info = gql`
  mutation user_update_user_info($data: InputUserInfo!) {
    user_update_user_info(data: $data) {
      user_idx
      height
      top_size
      top_size_gender
      bottom_size
    }
  }
`;

export const tag_count_post_by_tag_string = gql`
  query tag_count_post_by_tag_string($data: [String!]!) {
    tag_count_post_by_tag_string(data: $data) {
      count
    }
  }
`;

export const tag_select_post_by_tag_string = gql`
  query tag_select_post_by_tag_string($data: [String!]!, $state: State!) {
    tag_select_post_by_tag_string(data: $data, state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_gender
          user_img_url
          user_profile
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        post_item_list {
          item_idx
          post_idx
          item {
            ...itemFragment
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
  ${itemFragment}
`;

export const event_select_event_list_detail = gql`
  query event_select_event_list_detail($event_idx: Int!) {
    event_select_event_list_detail(event_idx: $event_idx) {
      event_idx
      title
      description
      banner_img_url
      full_img_url
      video_url
      is_open
      html
      status
      start_datetime
      end_datetime
      type
      badge_url
      update_user_idx
      update_datetime
      regist_datetime
      coupons {
        ...couponFragment
      }
    }
  }
  ${couponFragment}
`;

export const content_order_select_content_order_list = gql`
  query content_order_select_content_order_list($content_page: String!) {
    content_order_select_content_order_list(content_page: $content_page) {
      count
      rows {
        content_order_idx
        content_page
        content_name
        content_name_internal
        display_order
        is_open
      }
    }
  }
`;

export const notice_dialog_select_notice_dialog = gql`
  query notice_dialog_select_notice_dialog($state: State!) {
    notice_dialog_select_notice_dialog(state: $state) {
      count
      rows {
        notice_dialog_idx
        title
        img_url
        link_url
        button_text
        button_color
        link_url_two
        button_text_two
        button_color_two
        type
        display_order
        start_datetime
        end_datetime
      }
    }
  }
`;

export const coupon_select_coupon_list = gql`
  query coupon_select_coupon_list {
    coupon_select_coupon_list {
      valid_coupons {
        count
        rows {
          coupon_idx
          user_idx
          coupon_rule_idx
          coupon_rule {
            ...couponFragment
          }
          order_transaction_idx
          code
          status
          is_read
          use_datetime
          expire_datetime
          regist_datetime
          update_datetime
        }
      }
      invalid_coupons {
        count
        rows {
          coupon_idx
          user_idx
          coupon_rule_idx
          coupon_rule {
            ...couponFragment
          }
          order_transaction_idx
          code
          status
          is_read
          use_datetime
          expire_datetime
          regist_datetime
          update_datetime
        }
      }
    }
  }
  ${couponFragment}
`;

export const coupon_select_issuable_all_coupon_list = gql`
  query coupon_select_issuable_all_coupon_list {
    coupon_select_issuable_all_coupon_list {
      count
      rows {
        ...couponFragment
      }
    }
  }
  ${couponFragment}
`;

// item_option_list', () => [InputUserOrderItem]
export const coupon_select_valid_coupon_list = gql`
  query coupon_select_valid_coupon_list($item_option_list: [InputUserOrderItem!]!) {
    coupon_select_valid_coupon_list(item_option_list: $item_option_list) {
      valid_coupons {
        count
        rows {
          coupon_idx
          user_idx
          coupon_rule_idx
          coupon_rule {
            ...couponFragment
          }
          order_transaction_idx
          code
          status
          is_read
          use_datetime
          expire_datetime
          regist_datetime
          update_datetime
        }
      }
      invalid_coupons {
        count
        rows {
          coupon_idx
          user_idx
          coupon_rule_idx
          coupon_rule {
            ...couponFragment
          }
          order_transaction_idx
          code
          status
          is_read
          use_datetime
          expire_datetime
          regist_datetime
          update_datetime
        }
      }
    }
  }
  ${couponFragment}
`;

export const coupon_insert_coupon_list = gql`
  mutation coupon_insert_coupon_list($coupon_rule_idx_list: [Int!]!) {
    coupon_insert_coupon_list(coupon_rule_idx_list: $coupon_rule_idx_list) {
      message
      count
    }
  }
`;

export const coupon_insert_coupon_by_code = gql`
  mutation coupon_insert_coupon_by_code($coupon_code: String!) {
    coupon_insert_coupon_by_code(coupon_code: $coupon_code) {
      message
      count
    }
  }
`;

export const coupon_select_coupon_usable_item_list = gql`
  query coupon_select_coupon_usable_item_list($state: State, $coupon_rule_idx: Int!) {
    coupon_select_coupon_usable_item_list(state: $state, coupon_rule_idx: $coupon_rule_idx) {
      count
      rows {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const coupon_select_issuable_item_coupon_list = gql`
  query coupon_select_issuable_item_coupon_list($item_idx: Int!) {
    coupon_select_issuable_item_coupon_list(item_idx: $item_idx) {
      count
      rows {
        ...couponFragment
      }
    }
  }
  ${couponFragment}
`;

export const get_similar_items = gql`
  query get_similar_items($item_idx: Int!) {
    get_similar_items(item_idx: $item_idx) {
      count
      rows {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const get_stylist_items = gql`
  query get_stylist_items($item_idx: Int!) {
    get_stylist_items(item_idx: $item_idx) {
      count
      rows {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const post_select_post_detail_list = gql`
  query post_select_post_detail_list($post_idx: Int!, $state: State!) {
    post_select_post_detail_list(post_idx: $post_idx, state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_gender
          user_img_url
          user_profile
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        post_item_list {
          item_idx
          post_idx
          item {
            item_origin_price
            item_sell_price
            item_idx
            title
            item_image_url
          }
        }
        review {
          review_idx
          user_idx
          order_item_idx
          post_idx
          item_idx
          rate
          review_type
          item {
            item_image_url
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

export const post_select_post_detail_list_profile = gql`
  query post_select_post_detail_list_profile($post_idx: Int!, $state: State!) {
    post_select_post_detail_list_profile(post_idx: $post_idx, state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        view_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_gender
          user_img_url
          user_profile
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user {
            user_name
            user_idx
            user_img_url
          }
        }
        review {
          review_idx
          user_idx
          order_item_idx
          post_idx
          item_idx
          rate
          review_type
          item {
            item_image_url
            item_origin_price
            item_sell_price
            item_idx
            title
          }
        }
        post_item_list {
          item_idx
          post_idx
          item {
            item_image_url
            item_origin_price
            item_sell_price
            item_idx
            item_image_url
            title
          }
        }
        reply_list {
          content
          regist_datetime
          user_idx
          user {
            user_idx
            user_name
            user_img_url
          }
        }
      }
    }
  }
`;

export const get_post_similar_items = gql`
  query get_post_similar_items($post_idx_list: String!) {
    get_post_similar_items(post_idx_list: $post_idx_list) {
      post_recommend_items {
        post_idx
        items {
          item_idx
          title
          item_origin_price
          item_sell_price
          main_item_resource_idx
          main_image_resource {
            item_resource_idx
            item_idx
            item_resource_type
            item_resource_url
            item_resource_content
            filename
            width
            height
            color
          }
          shop_wish {
            shop_wish_idx
          }
          store {
            store_idx
            name
            type
          }
          item_category {
            category_name
          }
        }
      }
    }
  }
`;

export const select_category_item_list = gql`
  query select_category_item_list($category_idx: Int, $state: State!) {
    select_category_item_list(category_idx: $category_idx, state: $state) {
      ...itemFragment
    }
  }
  ${itemFragment}
`;

export const insert_shop_wish = gql`
  mutation insert_shop_wish($item_idx: Int!) {
    insert_shop_wish(item_idx: $item_idx) {
      shop_wish_idx
      item_idx
    }
  }
`;

export const delete_shop_wish = gql`
  mutation delete_shop_wish($item_idx: Int!) {
    delete_shop_wish(item_idx: $item_idx) {
      shop_wish_idx
      item_idx
    }
  }
`;

export const select_shop_wish_list = gql`
  query select_shop_wish_list($state: State!) {
    select_shop_wish_list(state: $state) {
      count
      rows {
        shop_wish_idx
        item_idx
        item {
          ...itemFragment
        }
      }
    }
  }
  ${itemFragment}
`;

export const get_brand_list = gql`
  query get_brand_list($state: State!) {
    get_brand_list(state: $state) {
      count
      rows {
        brand_idx
        name
        description
        thumbnail_url
        is_open
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_sorted_brand = gql`
  query select_sorted_brand {
    select_sorted_brand {
      count
      rows {
        brand_idx
        name
        description
        thumbnail_url
        is_open
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const select_brand_item_list = gql`
  query select_brand_item_list($brand_idx: Int!, $state: State!) {
    select_brand_item_list(brand_idx: $brand_idx, state: $state) {
      count
      rows {
        ...itemFragment
      }
    }
  }
  ${itemFragment}
`;

export const select_item_list_by_idx_list = gql`
  query select_item_list_by_idx_list($item_idx_list: [Int!]!) {
    select_item_list_by_idx_list(item_idx_list: $item_idx_list) {
      ...itemFragment
    }
  }
  ${itemFragment}
`;

export const insert_bulk_shop_wish = gql`
  mutation insert_bulk_shop_wish($item_idx_list: [Int!]!) {
    insert_bulk_shop_wish(item_idx_list: $item_idx_list)
  }
`;

export const shop_select_hotdeal_section = gql`
  query shop_select_hotdeal_section {
    shop_select_hotdeal_section {
      shop_section_idx
      shop_section_title
      shop_section_type
      start_datetime
      end_datetime
      shop_section_items {
        shop_section_item_idx
        shop_section_idx
        item_idx
        display_order
        img_url
        item {
          ...itemFragment
        }
      }
    }
  }
  ${itemFragment}
`;

export const upsert_user_refund_account = gql`
  mutation upsert_user_refund_account($data: InputUserRefundAccount!) {
    upsert_user_refund_account(data: $data) {
      user_refund_account_idx
      user_idx
      account_number
      bank_name
      account_holder
      regist_datetime
      update_datetime
    }
  }
`;

export const select_user_refund_account = gql`
  query select_user_refund_account {
    select_user_refund_account {
      user_refund_account_idx
      user_idx
      account_number
      bank_name
      account_holder
      regist_datetime
      update_datetime
    }
  }
`;

export const insert_post_item_view_history = gql`
  mutation insert_post_item_view_history($item_idx: Int!, $user_idx: Float!) {
    insert_post_item_view_history(item_idx: $item_idx, user_idx: $user_idx)
  }
`;

export const user_select_onesignal = gql`
  query user_select_onesignal {
    user_select_onesignal {
      user_name
      last_cart_update_time
      last_cart_update_item
      last_cart_update_item_info {
        title
        category_idx
        item_idx
        item_category {
          category_name
        }
      }
      last_order_time
      amount_spent
      birthdate
      birth_year
      age_range
      user_type
      last_wish {
        shop_wish_idx
        regist_datetime
        item {
          item_idx
          title
          item_category {
            category_name
            category_idx
          }
        }
      }
      last_coupon {
        coupon_idx
        coupon_rule_idx
        regist_datetime
        expire_datetime
        coupon_rule {
          coupon_rule_group_idx
          coupon_rule_idx
          title
          type
          amount
          min_order_amount
          use_ref_table
          use_ref_idx_string
          code
          valid_day
        }
      }
    }
  }
`;

export const insert_chat_room = gql`
  mutation insert_chat_room($data: InputChatRoom!) {
    insert_chat_room(data: $data) {
      chat_room_key
    }
  }
`;

export const insert_chat_message = gql`
  mutation insert_chat_message($data: InputChatMessage!) {
    insert_chat_message(data: $data) {
      chat_message_idx
    }
  }
`;

export const after_insert_chat_message = gql`
  mutation after_insert_chat_message($data: InputChatRoom!) {
    after_insert_chat_message(data: $data) {
      chat_room_key
    }
  }
`;

export const select_chat_room = gql`
  query select_chat_room($skip: Int!, $take: Int!, $sort: String!) {
    select_chat_room(skip: $skip, take: $take, sort: $sort) {
      count
      rows {
        chat_join_idx
        user_idx
        chat_room_idx
        regist_datetime
        update_datetime
        chat_room {
          chat_room_idx
          master_user_idx
          chat_room_type
          chat_room_title
          chat_room_img_url
          regist_datetime
          update_datetime
          post_idx
          user_idx
        }
      }
    }
  }
`;

export const select_chat_message = gql`
  query select_chat_message($chat_room_idx: Int!, $state: State!) {
    select_chat_message(chat_room_idx: $chat_room_idx, state: $state) {
      count
      rows {
        chat_message_idx
        chat_room_idx
        user_idx
        message_type
        message
        message_receiver_count
        message_unread_count
        noti_process_status
        noti_process_error
        link_type
        link_url
        link_message
        link_idx
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_img_url
        }
      }
    }
  }
`;

export const select_chat_join = gql`
  query select_chat_join($state: State!) {
    select_chat_join(state: $state) {
      count
      rows {
        ...chatJoinWithRoomFragment
      }
    }
  }
  ${chatJoinWithRoomFragment}
`;

export const get_chat_join = gql`
  query get_chat_join($chat_room_key: String!) {
    get_chat_join(chat_room_key: $chat_room_key) {
      ...chatJoinWithRoomFragment
    }
  }
  ${chatJoinWithRoomFragment}
`;

// export const get_chat_token = gql`
//   query get_chat_token() {
//     get_chat_token()
//   }
// `;

export const get_chat_token = gql`
  mutation get_chat_token {
    get_chat_token
  }
`;

export const insert_follow = gql`
  mutation insert_follow($data: InputFollow!) {
    insert_follow(data: $data)
  }
`;

export const delete_follow = gql`
  mutation delete_follow($data: InputFollow!) {
    delete_follow(data: $data)
  }
`;

export const insert_like = gql`
  mutation insert_like($data: InputLike!) {
    insert_like(data: $data)
  }
`;

export const delete_like = gql`
  mutation delete_like($data: InputLike!) {
    delete_like(data: $data)
  }
`;

export const select_user_like_list = gql`
  query select_user_like_list($state: State) {
    select_user_like_list(state: $state) {
      count
      rows {
        post_idx
      }
    }
  }
`;

export const select_shop_wish_list_all = gql`
  query select_shop_wish_list_all($state: State) {
    select_shop_wish_list_all(state: $state) {
      count
      rows {
        item_idx
        item {
          item_idx
          category_idx
          title
          item_sell_price
          item_origin_price
          item_image_url
        }
      }
    }
  }
`;

export const insert_post_tv_like = gql`
  mutation insert_post_tv_like($data: InputPostTvLike!) {
    insert_post_tv_like(data: $data)
  }
`;

export const delete_post_tv_like = gql`
  mutation delete_post_tv_like($data: InputPostTvLike!) {
    delete_post_tv_like(data: $data)
  }
`;

export const select_post_tv_like_list = gql`
  query select_post_tv_like_list {
    select_post_tv_like_list {
      count
      rows {
        post_tv_idx
      }
    }
  }
`;

export const insert_item_view = gql`
  mutation insert_item_view($data: InputUserItemView!) {
    insert_item_view(data: $data)
  }
`;

export const update_order_item_complete = gql`
  mutation update_order_item_complete($data: InputOrderItem!) {
    update_order_item_complete(data: $data)
  }
`;

export const refresh_order_item_can_review_list = gql`
  mutation refresh_order_item_can_review_list {
    refresh_order_item_can_review_list
  }
`;
export const refresh_order_transaction_list = gql`
  mutation refresh_order_transaction_list {
    refresh_order_transaction_list
  }
`;

export const delete_item_view = gql`
  mutation delete_item_view($data: InputUserItemView!) {
    delete_item_view(data: $data)
  }
`;

export const shop_insert_order_transaction_pending = gql`
  mutation shop_insert_order_transaction_pending($data: InputOrderTransactionPending!) {
    shop_insert_order_transaction_pending(data: $data) {
      order_transaction_pending_idx
    }
  }
`;

export const delete_all_item_view = gql`
  mutation delete_all_item_view {
    delete_all_item_view
  }
`;

export const select_user_item_view = gql`
  query select_user_item_view {
    select_user_item_view {
      count
      rows {
        user_item_view_idx
        item_idx
        item {
          item_idx
          category_idx
          title
          item_sell_price
          item_origin_price
          item_image_url
        }
      }
    }
  }
`;

export const get_onelink = gql`
  query get_onelink($onelink_idx: Int!) {
    get_onelink(onelink_idx: $onelink_idx) {
      onelink_idx
      onelink_url
      short_link
      push_web_url
      push_app_url
    }
  }
`;

export const insert_user_device = gql`
  mutation insert_user_device($data: InputUserDevice!) {
    insert_user_device(data: $data) {
      user_device_idx
      uuid
    }
  }
`;

export const insert_order_refer = gql`
  mutation insert_order_refer($data: InputOrderRefer!) {
    insert_order_refer(data: $data) {
      order_refer_idx
    }
  }
`;

export const insert_user_invite_history = gql`
  mutation insert_user_invite_history($data: InputUserInviteHistory!) {
    insert_user_invite_history(data: $data)
  }
`;

export const insert_onelink = gql`
  mutation insert_onelink($data: InputOnelink!) {
    insert_onelink(data: $data) {
      onelink_idx
      onelink_template_id
      landing_type
      app_open_pid
      app_open_campaign
      app_open_adset
      deep_link_value
      is_retargeting
      onelink_url
      short_link
      push_web_url
      push_app_url
      memo
      regist_datetime
      update_datetime
    }
  }
`;
export const insert_user_device_player_id = gql`
  mutation insert_user_device_player_id($data: InputUserDevice!) {
    insert_user_device_player_id(data: $data) {
      user_device_idx
      uuid
    }
  }
`;

export const insert_item_report = gql`
  mutation insert_item_report($data: InputItemReport!) {
    insert_item_report(data: $data)
  }
`;

export const update_class_room = gql`
  mutation update_class_room($data: InputClassRoom!) {
    update_class_room(data: $data)
  }
`;

export const insert_user_attendance = gql`
  mutation insert_user_attendance($data: InputUserAttendance!) {
    insert_user_attendance(data: $data)
  }
`;

export const select_item_list_not_viewed = gql`
  query select_item_list_not_viewed($state: State!) {
    select_item_list_not_viewed(state: $state) {
      count
      rows {
        ...itemThumFragment
      }
    }
  }
  ${itemThumFragment}
`;

export const get_fumist_idx = gql`
  query get_fumist_idx {
    get_fumist_idx
  }
`;

export const refresh_class_room = gql`
  mutation refresh_class_room {
    refresh_class_room
  }
`;

export const refresh_class_room_join = gql`
  mutation refresh_class_room_join {
    refresh_class_room_join
  }
`;

export const refresh_class_room_post = gql`
  mutation refresh_class_room_post {
    refresh_class_room_post
  }
`;

export const refresh_class_room_shop_section = gql`
  mutation refresh_class_room_shop_section {
    refresh_class_room_shop_section
  }
`;

export const refresh_class_room_shop_section_force = gql`
  mutation refresh_class_room_shop_section_force($data: InputClassRoomShopSection!) {
    refresh_class_room_shop_section_force(data: $data)
  }
`;

export const refresh_class_customer_order_item = gql`
  mutation refresh_class_customer_order_item {
    refresh_class_customer_order_item
  }
`;

export const refresh_class_room_point = gql`
  mutation refresh_class_room_point {
    refresh_class_room_point
  }
`;

export const refresh_class_join = gql`
  mutation refresh_class_join($data: InputClassJoin!) {
    refresh_class_join(data: $data)
  }
`;

export const refresh_user_info = gql`
  mutation refresh_user_info {
    refresh_user_info
  }
`;

export const update_class_room_post_list = gql`
  mutation update_class_room_post_list($data: InputClassRoomPostList!) {
    update_class_room_post_list(data: $data)
  }
`;

export const update_class_room_shop_section_list = gql`
  mutation update_class_room_shop_section_list($data: InputClassRoomShopSectionList!) {
    update_class_room_shop_section_list(data: $data)
  }
`;

export const update_shop_section = gql`
  mutation update_shop_section($data: InputShopSection!) {
    update_shop_section(data: $data)
  }
`;

export const update_class_room_shop_section = gql`
  mutation update_class_room_shop_section($data: InputShopSection!) {
    update_class_room_shop_section(data: $data) {
      shop_section_idx
    }
  }
`;

export const delete_class_room_shop_section = gql`
  mutation delete_class_room_shop_section($data: InputShopSection!) {
    delete_class_room_shop_section(data: $data)
  }
`;

export const insert_class_room_point_to_user = gql`
  mutation insert_class_room_point_to_user($data: InputClassRoomPoint!) {
    insert_class_room_point_to_user(data: $data)
  }
`;

export const refresh_user_point_list = gql`
  mutation refresh_user_point_list {
    refresh_user_point_list
  }
`;

export const insert_class_join = gql`
  mutation insert_class_join($data: InputClassJoin!) {
    insert_class_join(data: $data)
  }
`;
export const delete_class_join = gql`
  mutation delete_class_join($data: InputClassJoin!) {
    delete_class_join(data: $data)
  }
`;
