import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { AppService } from './app.service';
import { DataProviderService } from './graphql/data-provider.service';
import { State, ShopWish, Item } from './graphql/models';
import { makeVar, ReactiveVar } from '@apollo/client/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { LoginService } from './login.service';
import { Auth } from '@angular/fire/auth';
import {} from 'rxfire/firestore';
import { Firestore, collectionData, query, collection, orderBy, where } from '@angular/fire/firestore';
import { Storage } from '@ionic/storage-angular';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  constructor(private fbstore: Firestore, private storage: Storage, public loginService: LoginService) {
    const q = query(collection(this.fbstore, `notice_dialog`), orderBy('display_order', 'asc'));
    collectionData(q).subscribe(async (observer) => {
      if (observer) {
        await this.storage.set('notice_dialog', observer);
      }
    });
  }

  async getNewNoticeModal() {
    let result = await this.getNewNoticeModalProcess();
    if (!result) {
      await this.storage.remove(`notice_dialog_read_list`);
      result = await this.getNewNoticeModalProcess();
    }
    return result;
  }

  async getNewNoticeModalProcess() {
    const list: any = await this.storage.get('notice_dialog');

    if (list) {
      let result = null;
      for (let i = 0; i < list.length; i++) {
        const notice = list[i];
        const stamp = moment().format('YYYY_MM_DD_HH');
        const check_value = `notice_dialog_read_${notice.notice_dialog_idx}_${stamp}`;
        const check_key = `notice_dialog_read_list`;
        let need_show = false;
        let check = await this.storage.get(check_key);

        if (check && check.length > 30) {
          await this.storage.remove(check_key);
          check = null;
        }

        if (!check) {
          need_show = true;
          check = [check_value];
        } else {
          if (check.indexOf(check_value) == -1) {
            need_show = true;
            check.push(check_value);
          }
        }

        if (need_show) {
          result = notice;
          await this.storage.set(check_key, check);
          break;
        }
      }
      return result;
    } else {
      return null;
    }
  }
}
