import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { HttpClient } from '@angular/common/http';
import { AdId } from 'capacitor-adid-idfa';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

import { collectionData, doc, docData, Firestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { AppUpdate, AppUpdateAvailability } from '@robingenz/capacitor-app-update';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  // initialized = false;

  constructor(private platform: Platform, private http: HttpClient, private fbstore: Firestore, private router: Router) {}

  async getDeviceInfo(): Promise<{
    uuid: string;
    platform: string;
    os: string;
    name: string;
    app_build: string;
    app_version: string;
    idfa: string;
    adid: string;
  }> {
    return new Promise(async (resolve, reject) => {
      this.platform.ready().then(async () => {
        let device_info = await Device.getInfo();
        let app_info: any = {};
        if (device_info.platform != 'web') {
          app_info = await App.getInfo();
        }

        let uuid = (await Device.getId()).uuid;
        let adid = 'none';
        let idfa = 'none';

        try {
          if (device_info.platform == 'ios') {
            idfa = (await AdId.getAdId()).id;
          } else if (device_info.platform == 'android') {
            adid = (await AdId.getAdId()).id;
          }
        } catch (error) {
          console.error(error);
        }

        resolve({
          uuid,
          platform: device_info.platform,
          os: device_info.operatingSystem,
          name: device_info.name ? device_info.name.trim().substring(0, 20) : 'no_name',
          app_build: app_info.build,
          app_version: app_info.version,
          idfa,
          adid
        });
      });
    });
  }

  // async deviceInit() {
  //   if (this.initialized) {
  //     return true;
  //   }

  //   this.initialized = true;
  //   console.log('deviceInit!!');

  //   this.fbuser.getUserDataChangeListner().subscribe((observer) => {
  //     if (observer) {
  //       this.uploadDeviceInfo(observer);
  //     }
  //   });
  // }

  // //디바이스 정보 업로드 하기
  // private async uploadDeviceInfo(observer: any) {
  //   console.log('uploadDeviceInfo!!', observer.user_idx);
  //   let device_info = await Device.getInfo();
  //   let app_info: any = {};
  //   if (device_info.platform != 'web') {
  //     app_info = await App.getInfo();
  //   }
  //   const uuid = (await Device.getId()).uuid;
  //   const user_device = await this.db.insert_user_device({
  //     uuid,
  //     platform: device_info.platform,
  //     os: device_info.operatingSystem,
  //     name: device_info.name ? device_info.name.trim().substring(0, 20) : 'no_name',
  //     app_build: app_info.build,
  //     app_version: app_info.version
  //   });
  //   await this.storage.set('user_device_idx', user_device.user_device_idx);
  //   this.deviceSubject.next(user_device.user_device_idx);
  // }

  // public getUserDeivceIdxObjserver() {
  //   return this.deviceSubject.asObservable();
  // }

  // public getUserDeviceIdx(): Promise<number> {
  //   return new Promise(async (resolve, reject) => {
  //     let user_device_idx = await this.storage.get('user_device_idx');
  //     if (user_device_idx) {
  //       resolve(Number(user_device_idx));
  //     } else {
  //       const updateObserver = this.getUserDeivceIdxObjserver().subscribe((user_device_idx) => {
  //         if (user_device_idx) {
  //           updateObserver.unsubscribe();
  //           resolve(Number(user_device_idx));
  //         }
  //       });
  //     }
  //   });
  // }
}
