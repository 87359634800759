import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

import { LoginModalComponent } from '../components/fumi-common/login-modal/login-modal.component';
import { Storage } from '@ionic/storage-angular';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { Capacitor3KakaoLogin } from 'capacitor3-kakao-login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private storage: Storage, private loadingController: LoadingController, private modalController: ModalController) {
    Capacitor3KakaoLogin.initializeKakao(environment.kakao);
  }

  apple_login(): Promise<string> {
    const sample = {
      response: {
        email: 'foo@bar.com',
        identityToken: 'importantToken',
        familyName: 'Grimm',
        user: 'AppleUserId',
        givenName: 'Simon',
        authorizationCode: 'authCode'
      }
    };

    return new Promise((resolve, reject) => {
      console.log('apple login start');
      SignInWithApple.authorize()
        .then(async (res) => {
          if (res.response && res.response.identityToken) {
            console.log(res.response.identityToken);
            resolve(`${res.response.identityToken}`);
          } else {
            console.log(JSON.stringify(res));
            alert(JSON.stringify(res));
            resolve(null);
          }
        })
        .catch((response) => {
          alert(JSON.stringify(response));
          resolve(null);
        });
    });
  }

  async kakao_login() {
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.kakaoLogin();
    } catch (error) {
      console.log('kakao login error', JSON.stringify(error));
      if ('kakao_sdk_not_initialzed' == JSON.stringify(error)) {
        console.log('will initialze again!!!');
        Capacitor3KakaoLogin.initializeKakao(environment.kakao);
      }
    }

    return temp.value;
  }

  async login(login_type: string) {
    if ('kakao' == login_type) {
      return await this.kakao_login();
    } else if ('apple' == login_type) {
      return await this.apple_login();
    }
  }

  async logout() {
    // const loading = await this.loadingController.create({});
    // await loading.present();
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.kakaoLogout();
    } catch (error) {
      console.error(error);
      return null;
    }
    // loading.dismiss();
    return temp.value;
  }

  async unlink() {
    // const loading = await this.loadingController.create({});
    // await loading.present();
    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.kakaoUnlink();
    } catch (error) {
      console.error(error);
    }
    // loading.dismiss();
    return temp.value;
  }

  async share(
    title: string,
    description: string,
    image_url: string,
    image_link_url: string,
    button_title: string,
    user_idx: number,
    device_idx: number,
    ref_idx: number,
    ref_table: string,
    memo = 'none'
  ) {
    // const loading = await this.loadingController.create({});
    // await loading.present();

    let temp = { value: null };
    try {
      temp = await Capacitor3KakaoLogin.sendLinkFeed({
        title,
        description,
        image_url,
        image_link_url,
        button_title,
        memo,
        device_idx: `${device_idx}`,
        user_idx: `${user_idx}`,
        ref_idx: `${ref_idx}`,
        ref_table: `${ref_table}`
      });
    } catch (error) {
      alert(error.message);
    }
    // loading.dismiss();
    return temp.value;
  }

  async isLogin() {
    let token = await this.storage.get('token');
    if (token) {
      return true;
    } else {
      alert('no token!!');
      return false;
    }
  }

  public async checkLoginUser(from: 'item-detail' | 'upload' | 'post-read' | 'event-coupon' | 'function' = 'function') {
    if (!(await this.isLogin())) {
      return await this.showLoginDialog(from);
    } else {
      return true;
    }
  }

  public async showLoginDialog(from: 'item-detail' | 'upload' | 'post-read' | 'event-coupon' | 'function') {
    const modal = await this.modalController.create({
      component: LoginModalComponent,
      componentProps: {
        from
      },
      cssClass: 'option-modal',
      backdropDismiss: true
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data == true;
  }

  // currentUser() {
  //   const temp = window.localStorage.getItem('user');
  //   if (temp) {
  //     return JSON.parse(temp);
  //   }
  //   return null;
  // }
}
