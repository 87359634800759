import { Injectable, NgZone } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import { environment } from 'src/environments/environment';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';

import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class OnesignalService {
  initHolder = [];
  isInitialized = false;
  isInitializing = false;

  private pushAlaramSubject = new BehaviorSubject(null);

  constructor(private oneSignal: OneSignal, private platform: Platform, private ngZone: NgZone, private storage: Storage) {}

  public getPushAlarmObjserver() {
    return this.pushAlaramSubject.asObservable();
  }

  async setExternalUserId(userId: string) {
    if (Capacitor.getPlatform() !== 'web') {
      await this.onesignalInit();
      await this.oneSignal.setExternalUserId(userId);
    }
    return true;
  }

  onesignalInit() {
    if (environment.is_native_portal) {
      return new Promise((resolve) => {
        resolve(true);
      });
    } else {
      return new Promise(async (resolve, reject) => {
        await this.platform.ready();

        if (Capacitor.getPlatform() == 'web') {
          this.isInitializing = true;
          resolve(true);
        } else {
          if (this.isInitialized) {
            resolve(true);
            return;
          }

          this.initHolder.push(resolve);
          if (!this.isInitializing) {
            this.isInitializing = true;
            try {
              const self = this;
              if (environment.production) {
                this.oneSignal.setLogLevel({ logLevel: 0, visualLevel: 0 });
              } else {
                this.oneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });
              }

              //Push init
              this.oneSignal.startInit(environment.onesignal.app_id, environment.onesignal.sender_id);
              this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification); //notification center로 추가
              this.oneSignal.handleNotificationReceived().subscribe((result: any) => {
                try {
                  self.saveNotification(result.payload, false); //payload, isBackground,
                } catch (e) {
                  alert(e);
                }
              });

              this.oneSignal.handleNotificationOpened().subscribe((result: any) => {
                try {
                  self.saveNotification(result.notification.payload, true); //payload, isBackground,
                } catch (e) {
                  alert(e);
                }
              });

              this.oneSignal.endInit();

              const onesignal_id = await this.oneSignal.getIds();
              let player_id = 'none';
              if (onesignal_id && onesignal_id.userId) {
                player_id = onesignal_id.userId;
              }

              await this.storage.set('player_id', player_id);
            } catch (e) {
              console.error(e);
            }

            this.isInitialized = true;
            for (let i = 0; i < this.initHolder.length; i++) {
              this.initHolder[i](true);
            }
            this.initHolder = [];
            this.isInitializing = false;
          }
        }
      });
    }
  }

  // async uploadPlayerId() {
  //   console.log('uploadPlayerId');
  //   const user_device_idx = await this.device_service.getUserDeviceIdx();

  //   console.log('user_device_idx ', user_device_idx);
  //   if (user_device_idx) {
  //     const player_id = await this.getPlayerId();
  //     console.log('player_id ', player_id);

  //     if (user_device_idx && player_id) {
  //       console.log('will insert player_id', user_device_idx, player_id);
  //       await this.db.insert_user_device_player_id({ user_device_idx, player_id });
  //     }
  //   }
  //   console.log('uploadPlayerId end');
  // }

  async getPlayerId(): Promise<string> {
    console.log('getPlayerId');
    if (Capacitor.getPlatform() == 'web') {
      return 'none';
    } else {
      await this.onesignalInit();
      const player_id = await this.storage.get('player_id');
      return player_id;
    }
  }

  oneSignalSync(tags: any) {
    try {
      if (Capacitor.getPlatform() !== 'web') {
        this.oneSignal.sendTags(tags);
      }
    } catch (e) {
      console.error('oneSignal error', e);
    }
  }

  oneSignalLogout() {
    try {
      if (Capacitor.getPlatform() !== 'web') {
        let tags: Array<any> = ['push_alarm_onoff', 'user_idx'];
        this.oneSignal.deleteTags(tags);
        this.oneSignal.removeExternalUserId();
      }
    } catch (e) {
      console.error('oneSignal error', e);
    }
  }

  saveNotification(payload, isBackground) {
    if (isBackground) {
      this.pushAlaramSubject.next({ ...payload, isBackground });
    }

    //alert( payload.title + '|' + payload.body + '|' + payload.notificationID );
    //background면 바로 이동기능 제공

    // try {
    //   const self = this;
    //   let launchURL = payload.launchURL;
    //   if (launchURL) {
    //     setTimeout(() => {
    //       self.ngZone.run(() => {
    //         self.openAppUrl(launchURL);
    //       });
    //     }, 500);
    //   } else {
    //     if (payload.hasOwnProperty('additionalData')) {
    //       let additionalData = payload.additionalData;
    //       if (additionalData) {
    //         if (additionalData.hasOwnProperty('link')) {
    //           setTimeout(() => {
    //             self.ngZone.run(() => {
    //               self.openAppUrl(additionalData.link);
    //             });
    //           }, 500);
    //         }
    //       }
    //     }
    //   }

    //   if (isBackground) {
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  }

  async openAppUrl(url: string) {
    // const result = this.app.decodeDeeplink(url);
    // if (result && result.query) {
    //   this.logAppOpen(result.query.app_open_pid, result.query.app_open_campaign, result.query.app_open_adset);
    //   this.fbuser.setCampaignData({
    //     app_open_pid: result.query.app_open_pid,
    //     app_open_campaign: result.query.app_open_campaign,
    //     app_open_adset: result.query.app_open_adset,
    //     regist_datetime: new Date()
    //   });
    // }
    // if (result && result.url) {
    //   this.app.justGo(result.url, result.query);
    // }
  }

  //app open tracking
  async logAppOpen(app_open_pid: string = 'none', app_open_campaign: string = 'none', app_open_adset = 'none') {
    // const user_idx = await this.fbuser.getUserIdx();
    // const device_idx = await this.fbuser.getUserDeviceIdx();
    // await this.tracker.appOpen({
    //   platform: Capacitor.getPlatform(),
    //   app_open_pid,
    //   app_open_campaign,
    //   app_open_adset,
    //   user_idx,
    //   device_idx
    // });
  }
}
