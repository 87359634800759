import { gql } from 'apollo-angular';

export const select_store_list = gql`
  query select_store_list($state: State!, $type: String) {
    select_store_list(state: $state, type: $type) {
      count
      rows {
        store_idx
        user_idx
        name
        title
        description
        thumbnail_url
        bookmark_count
        update_user_idx
        is_open
        regist_datetime
        update_datetime
        type
        item_list {
          title
          item_idx
          item_origin_price
          item_sell_price
          main_image_resource {
            item_resource_url
          }
          item_category {
            category_name
          }
        }
        store_bookmark {
          store_bookmark_idx
          user_idx
          store_idx
          regist_datetime
        }
        tag_list
      }
    }
  }
`;

export const select_store = gql`
  query select_store($store_idx: Int!) {
    select_store(store_idx: $store_idx) {
      store_idx
      user_idx
      name
      title
      size
      type
      description
      thumbnail_url
      bookmark_count
      update_user_idx
      regist_datetime
      item_list {
        item_idx
        main_image_resource {
          item_resource_url
        }
        store {
          store_idx
          name
        }
      }
      store_bookmark {
        store_bookmark_idx
      }
      tag_list
      item_count
      post_count
      review_count
    }
  }
`;

export const insert_store_bookmark = gql`
  mutation insert_store_bookmark($store_idx: Int!) {
    insert_store_bookmark(store_idx: $store_idx) {
      store_bookmark_idx
      user_idx
      store_idx
      regist_datetime
    }
  }
`;

export const insert_bulk_store_bookmark = gql`
  mutation insert_bulk_store_bookmark($store_idx_list: [Int!]!) {
    insert_bulk_store_bookmark(store_idx_list: $store_idx_list)
  }
`;

export const delete_store_bookmark = gql`
  mutation delete_store_bookmark($store_idx: Int!) {
    delete_store_bookmark(store_idx: $store_idx)
  }
`;

export const select_store_bookmark_list = gql`
  query select_store_bookmark_list {
    select_store_bookmark_list {
      count
      rows {
        store_bookmark_idx
        user_idx
        store_idx
        regist_datetime
        store {
          store_idx
          name
          thumbnail_url
          tag_list
        }
      }
    }
  }
`;

export const select_store_list_by_idx_list = gql`
  query select_store_list_by_idx_list($store_idx_list: [Int!]!) {
    select_store_list_by_idx_list(store_idx_list: $store_idx_list) {
      count
      rows {
        store_idx
        user_idx
        name
        title
        size
        description
        thumbnail_url
        bookmark_count
        update_user_idx
        regist_datetime
        item_list {
          item_idx
          main_image_resource {
            item_resource_url
          }
          store {
            store_idx
            name
          }
        }
        tag_list
      }
    }
  }
`;

export const select_store_item_list = gql`
  query select_store_item_list($store_idx: Int!, $state: State!) {
    select_store_item_list(store_idx: $store_idx, state: $state) {
      item_idx
      title
      item_origin_price
      item_sell_price
      main_image_resource {
        item_resource_url
      }
      store {
        store_idx
        name
        type
      }
      item_category {
        category_name
      }
      shop_wish {
        shop_wish_idx
      }
    }
  }
`;

export const select_store_post_list = gql`
  query select_store_post_list($store_idx: Int!, $state: State!) {
    select_store_post_list(store_idx: $store_idx, state: $state) {
      count
      rows {
        post_idx
        user_idx
        title
        description
        preview_img_url
        reply_count
        like_count
        admin_check_status
        open_status
        post_type
        regist_datetime
        update_datetime
        user {
          user_idx
          user_name
          user_gender
          user_img_url
          user_profile
        }
        post_resource_list {
          post_resource_idx
          post_resource_url
          post_resource_type
          width
          height
          color
        }
        like_list {
          user_idx
        }
        reply_list {
          content
          regist_datetime
          user_idx
        }
      }
    }
  }
`;

export const select_store_review_list = gql`
  query select_store_review_list($store_idx: Int!, $state: State!) {
    select_store_review_list(store_idx: $store_idx, state: $state) {
      count
      rows {
        review_idx
        user {
          user_idx
          user_name
          user_img_url
        }
        item_idx
        order_item {
          order_item_idx
          user_idx
          regist_datetime
          update_datetime
          item_option {
            item_option_name
          }
          item {
            main_image_resource {
              item_resource_url
            }
            item_origin_price
            item_sell_price
            item_idx
            store {
              store_idx
              name
              type
            }
            item_category {
              category_name
            }
            title
          }
        }
        post {
          post_idx
          title
          description
          post_resource_list {
            post_resource_idx
            post_resource_url
            post_resource_type
            width
            height
            color
          }
        }
        rate
        review_type
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const local_select_store_bookmark = gql`
  query local_select_store_bookmark {
    store_bookmark_list @client
  }
`;

export const local_insert_store_bookmark = gql`
  query local_insert_store_bookmark {
    store_bookmark_list @client
  }
`;

export const local_delete_store_bookmark = gql`
  query local_delete_store_bookmark {
    store_bookmark_list @client
  }
`;

export const select_sorted_store_list = gql`
  query select_sorted_store_list {
    select_sorted_store_list {
      count
      rows {
        store_idx
        name
        description
        thumbnail_url
        is_open
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;

export const search_brand_store_list = gql`
  query search_brand_store_list($state: State!) {
    search_brand_store_list(state: $state) {
      count
      rows {
        store_idx
        name
        description
        thumbnail_url
        is_open
        display_order
        regist_datetime
        update_datetime
      }
    }
  }
`;
