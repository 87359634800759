import { gql } from 'apollo-angular';

export const get_ab_test = gql`
  query get_ab_test(
    $experiment_name: String!
    $temp_user_idx: Float!
  ) {
    get_ab_test(
      experiment_name: $experiment_name
      temp_user_idx: $temp_user_idx
    ) {
      ab_test_idx
      user_idx
      ab_experiment_idx
      value
      regist_datetime
      update_datetime
    }
  }
`;
