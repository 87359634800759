import { Injectable, NgZone } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { addDoc, collection, collectionData, doc, Firestore, orderBy, query } from '@angular/fire/firestore';
import { Capacitor } from '@capacitor/core';
import { DataProviderService } from '../graphql/data-provider.service';
import { UtilService } from '../util.service';

import { TrakingBase } from './tracker-base';

@Injectable({
  providedIn: 'root'
})
export class FumiTrackerService extends TrakingBase {
  user_idx: number;
  user_device_idx: number;

  constructor(private fbstore: Firestore, private utils: UtilService) {
    super();
  }

  public async appInit(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await addDoc(collection(this.fbstore, `tracker`), { name: 'app_init', params: data });
    return true;
  }

  public async initialize(): Promise<boolean> {
    return true;
  }

  public async setUserIdx(user_idx: number): Promise<boolean> {
    this.user_idx = user_idx;
    return true;
  }

  async setDeviceIdx(user_device_idx: number) {
    this.user_device_idx = user_device_idx;
    return true;
  }

  async appInstall(data: any) {
    const params = { ...this.utils.filterFbObject(data) };
    await this.addDoc(`tracker`, { name: 'app_install', params });
    return true;
  }

  public async logCompleteOrderTransaction(data: any): Promise<boolean> {
    return true;
  }

  public async addDoc(ref: string, data: { name: string; params: any }) {
    data.params = this.utils.filterObject(data.params);
    return await addDoc(collection(this.fbstore, ref), data);
  }

  public async appOpen(data: any): Promise<boolean> {
    console.log('appOpen!!');
    const temp = this.utils.filterFbObject(data);
    await this.addDoc(`tracker`, {
      name: 'app_open',
      params: { ...temp }
    });
    return true;
  }

  public async logPageView(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'page_view',
      params: { ...data }
    });
    return true;
  }

  public async logItemView(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'item_view',
      params: { ...data }
    });
    return true;
  }

  public async logItemClick(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'item_click',
      params: { ...data }
    });
    return true;
  }

  public async logItemImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logCompleteOrderItem(data: any): Promise<boolean> {
    return true;
  }
  public async setUserProperty(data: any): Promise<boolean> {
    return true;
  }

  public async logBannerView(data: any): Promise<boolean> {
    return true;
  }
  public async logBannerImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logShopSectionView(data: any): Promise<boolean> {
    return true;
  }
  public async logShopSectionImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logNoticeDialogView(data: any): Promise<boolean> {
    return true;
  }
  public async logNoticeDialogImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logEvent(data: any): Promise<boolean> {
    return true;
  }
  public async login(data: any): Promise<boolean> {
    return true;
  }
  public async logPurchase(data: any): Promise<boolean> {
    return true;
  }
  public async logAddToCart(data: any): Promise<boolean> {
    return true;
  }

  public async logPostImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logPostClick(data: any): Promise<boolean> {
    return true;
  }
  public async logPostView(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'post_view',
      params: { ...data }
    });
    return true;
  }
  public async logPostLike(data: any): Promise<boolean> {
    return true;
  }
  public async logPostReply(data: any): Promise<boolean> {
    return true;
  }
  public async logWishItem(data: any): Promise<boolean> {
    return true;
  }
  public async logSearchItem(data: any): Promise<boolean> {
    return true;
  }
  public async logSearchPost(data: any): Promise<boolean> {
    return true;
  }

  public async logUserCheckIn(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'user_check_in',
      params: { ...data }
    });

    return true;
  }
  public async logUserCheckOut(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'user_check_out',
      params: { ...data }
    });

    return true;
  }

  async logFumistView(data: any) {
    await this.addDoc(`tracker`, {
      name: 'class_room_view',
      params: { ...data }
    });
    return true;
  }

  async logFumistImpression(class_room: any) {
    return true;
  }

  async logFumistJoin(class_room: any) {
    return true;
  }

  async logFumistClick(data: any): Promise<boolean> {
    await this.addDoc(`tracker`, {
      name: 'class_room_click',
      params: { ...data }
    });
    return true;
  }
}
