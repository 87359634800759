<ion-content>
  <div class="center-container">
    <div>
      <div class="center-content">
        <ng-lottie
          [options]="options"
          (animationCreated)="animationCreated($event)"
        ></ng-lottie>
      </div>

      <div class="center-content">
        <ion-spinner></ion-spinner>
      </div>
      <div class="center-content">
        <h1>잠시만 기다려주세요</h1>
      </div>
    </div>
  </div>
</ion-content>
