import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor.service';

import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { GraphQLModule } from './graphql.module';

import { environment } from 'src/environments/environment';
import { ClipboardModule } from 'ngx-clipboard';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore, enableIndexedDbPersistence, CACHE_SIZE_UNLIMITED } from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { CommonModalModule } from './components/common-modal/common-modal.module';
import { IonicStorageModule } from '@ionic/storage-angular';

import { DeviceAppVersionModule } from './components/device-app-version/device-app-version.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppUpdateService } from './services/app-update.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    GraphQLModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
      animated: true,
      backButtonText: ''
    }),
    DeviceAppVersionModule,
    IonicStorageModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => {
      const firestore = getFirestore();
      // enableIndexedDbPersistence(firestore, { forceOwnership: true });
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'asia-northeast3');

      //개발중일 때만 에뮬레이터 사용
      if (!environment.production) {
        connectFunctionsEmulator(functions, `${environment.function_url}`, 5001);
      }

      return functions;
    }),
    provideStorage(() => getStorage()),
    ClipboardModule,
    AppRoutingModule,
    CommonModalModule
  ],
  providers: [
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false
    },
    OneSignal,
    Deeplinks,
    AppUpdateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
