import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AFConstants, AppsFlyer } from 'appsflyer-capacitor-plugin';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { environment } from 'src/environments/environment';

import { async, BehaviorSubject } from 'rxjs';

import { Platform, IonRouterOutlet, ModalController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { debounceTime, observeOn, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppsflyerService {
  initHolder = [];
  isInitialized = false;
  isInitializing = false;
  lastEvent = 'none';

  private appsflyerAlaramSubject = new BehaviorSubject(null);

  constructor(private platform: Platform) {}

  appsflyerInit() {
    if (environment.is_native_portal) {
      return new Promise((resolve) => {
        resolve(true);
      });
    } else {
      return new Promise(async (resolve, reject) => {
        await this.platform.ready();

        if (Capacitor.getPlatform() == 'web') {
          this.isInitializing = true;
          resolve(true);
          return;
        } else {
          if (this.isInitialized) {
            resolve(true);
            return;
          }
          this.initHolder.push(resolve);
          if (!this.isInitializing) {
            this.isInitializing = true;

            try {
              this.setConversions();
              this.setOAOA();
              this.setUDL();
              await AppsFlyer.initSDK(environment.appsflyer_options);
              //초기화 성공

              //app transparancy ios only
              if (Capacitor.getPlatform() == 'ios') {
                AppTrackingTransparency.requestPermission().then((res) => {
                  // alert('ATT status: ' + res.status);
                });
              }
            } catch (error) {
              console.log('!!!!!appsflyerInit error' + error);
            }

            this.isInitialized = true;
            for (let i = 0; i < this.initHolder.length; i++) {
              this.initHolder[i](true);
            }
            this.initHolder = [];
            this.isInitializing = false;
          }
        }
      });
    }
  }

  public getPushAlarmObjserver() {
    return this.appsflyerAlaramSubject.asObservable();
  }

  setConversions() {
    AppsFlyer.addListener(AFConstants.CONVERSION_CALLBACK, (event) => {
      // this.tracker.logAppInit()
      if (event.callbackName === AFConstants.onConversionDataSuccess) {
        // if (event.data.af_status === 'Non-organic') {
        //   if (event.data.is_first_launch === true) {
        //     const deepLinkValue = event.data.deep_link_value;
        //     this.app.checkDeeplink(deepLinkValue);
        //   }
        // }

        //app install tracking
        // if (event.data.is_first_launch === true) {
        //   this.appsflyerAlaramSubject.next({
        //     event: 'app_install',
        //     data: event.data
        //   });
        // }

        this.appsflyerAlaramSubject.next({
          event: 'app_install',
          data: event.data
        });
      }
    });
  }

  setOAOA() {
    App.addListener('appUrlOpen', (data: any) => {
      if (JSON.stringify(data).indexOf('deep_link_value=') !== -1) {
        this.appsflyerAlaramSubject.next({
          event: 'app_open',
          data: data
        });
      } else if (data.url) {
        this.appsflyerAlaramSubject.next({
          event: 'app_open_url',
          data: data.url
        });
      } else {
        //ad 프록시에서 처리하도록 페이지만 이동 시킨다
      }
    });

    // AppsFlyer.addListener(AFConstants.OAOA_CALLBACK, (res) => {
    //   alert('OAOA_CALLBACK ~~>' + JSON.stringify(res));

    // });
  }

  setUDL() {
    AppsFlyer.addListener(AFConstants.UDL_CALLBACK, (res) => {
      // if (res.status === 'FOUND') {
      //   alert('found');
      //   const deepLinkValue = res.deepLink.deep_link_value;
      //   if (deepLinkValue) {
      //     alert('deepLinkValue' + JSON.stringify(deepLinkValue));
      //     this.appsflyerAlaramSubject.next({
      //       event: 'app_open',
      //       data: res
      //     });
      //   } else {
      //     alert('pass');
      //   }
      // } else if (res.status === 'ERROR') {
      //   console.log('udl error: ' + res.error);
      // }
    });
  }

  //app install tracking
  // async logAppInstall(af_status: string, af_message: string, deep_link_value: string) {
  //   const user_idx = await this.fbuser.getUserIdx();
  //   const device_idx = await this.device_service.getUserDeviceIdx();
  //   await this.tracker.appInstall({
  //     platform: Capacitor.getPlatform(),
  //     af_status,
  //     af_message,
  //     deep_link_value,
  //     user_idx,
  //     device_idx
  //   });
  // }

  // //app open tracking
  // async logAppOpen(app_open_pid: string = 'none', app_open_campaign: string = 'none', app_open_adset = 'none') {
  //   const user_idx = await this.fbuser.getUserIdx();
  //   const device_idx = await this.device_service.getUserDeviceIdx();
  //   await this.tracker.appOpen({
  //     platform: Capacitor.getPlatform(),
  //     app_open_pid,
  //     app_open_campaign,
  //     app_open_adset,
  //     user_idx,
  //     device_idx
  //   });
  // }
}
