import { Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AppsFlyer, AFConstants } from 'appsflyer-capacitor-plugin';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { environment } from 'src/environments/environment';
import { TrakingBase } from './tracker-base';

@Injectable({
  providedIn: 'root'
})
export class AppsflyerTrackerService extends TrakingBase {
  constructor(private router: Router, public route: ActivatedRoute, private zone: NgZone) {
    super();
  }

  public async initialize(): Promise<boolean> {
    return true;
  }

  public async appInit(data: { user_idx: number; user_device_idx: number; platform: string }): Promise<boolean> {
    return true;
  }

  async appInstall(data: { platform: string; af_status: string; af_message: string; deep_link_value: string; user_idx: number; user_device_idx: number }) {
    console.log('AppsflyerTrackerService appInstall!!');
    return true;
  }

  public async appOpen(data: { platform: string; app_open_pid: string; app_open_campaign: string; app_open_adset: string; user_idx: number; user_device_idx: number }): Promise<boolean> {
    return true;
  }

  public async setUserIdx(user_idx: number): Promise<boolean> {
    await AppsFlyer.setCustomerUserId({ cuid: `${user_idx}` });
    return true;
  }

  public async logItemClick(data: any): Promise<boolean> {
    return true;
  }

  public async setUserProperty(data: any): Promise<boolean> {
    // AppsFlyer.setAdditionalData()

    return true;
  }

  public async logCompleteOrderTransaction(order_transaction: any): Promise<boolean> {
    const eventName = 'af_purchase';
    const eventValue = {
      af_revenue: order_transaction.total_sell_price,
      af_content_id: order_transaction.order_transaction_idx,
      af_currency: 'KRW',
      payment_type: order_transaction.payment_type
    };
    AppsFlyer.logEvent({ eventName, eventValue });
    return true;
  }
  public async logCompleteOrderItem(data: any): Promise<boolean> {
    return true;
  }
  public async logPageView(data: any): Promise<boolean> {
    return true;
  }
  public async logItemView(data: any): Promise<boolean> {
    return true;
  }

  public async logItemImpression(data: any): Promise<boolean> {
    return true;
  }

  public async logBannerView(data: any): Promise<boolean> {
    return true;
  }
  public async logBannerImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logShopSectionView(data: any): Promise<boolean> {
    return true;
  }
  public async logShopSectionImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logNoticeDialogView(data: any): Promise<boolean> {
    return true;
  }
  public async logNoticeDialogImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logEvent(data: any): Promise<boolean> {
    return true;
  }
  public async login(data: any): Promise<boolean> {
    return true;
  }
  public async logPurchase(data: any): Promise<boolean> {
    return true;
  }
  public async logAddToCart(data: any): Promise<boolean> {
    return true;
  }

  public async logPostImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logPostClick(data: any): Promise<boolean> {
    return true;
  }
  public async logPostView(data: any): Promise<boolean> {
    return true;
  }
  public async logPostLike(data: any): Promise<boolean> {
    return true;
  }
  public async logPostReply(data: any): Promise<boolean> {
    return true;
  }

  public async logSearchItem(data: any): Promise<boolean> {
    return true;
  }
  public async logSearchPost(data: any): Promise<boolean> {
    return true;
  }

  public async logWishItem(data: any): Promise<boolean> {
    return true;
  }

  public async logUserCheckIn(data: any): Promise<boolean> {
    return true;
  }
  public async logUserCheckOut(data: any): Promise<boolean> {
    return true;
  }

  async logFumistView(class_room: any) {
    return true;
  }
  async logFumistImpression(class_room: any) {
    return true;
  }
  async logFumistJoin(class_room: any) {
    return true;
  }

  async logFumistClick(data: any): Promise<boolean> {
    return true;
  }
}
