import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  amplitude = {
    enter_coupon: 'enter_coupon',
    enter_post: 'enter_post',
    enter_shop_menu: 'enter_shop_menu',
    enter_item_detail: 'enter_item_detail',
    enter_review: 'enter_review',
    enter_notice_item: 'enter_notice_item',
    enter_pay_callback: 'enter_pay_callback',
    enter_payment_page: 'enter_payment_page',
    enter_post_detail: 'enter_post_detail',
    enter_shop_feed_tab: 'enter_shop_feed_tab',
    enter_brand_home: 'enter_brand_home',
    enter_store_home: 'enter_store_home',
    enter_store_menu: 'enter_store_menu',
    click_banner: 'click_banner',
    click_more_brand: 'click_more_brand',
    click_brand_button: 'click_brand_button',
    click_notification_history: 'click_notification_history',
    click_notification_notice: 'click_notification_notice',
    click_notification_item: 'click_notification_item',
    click_order_cart_item: 'click_order_cart_item',
    click_coupon_download: 'click_coupon_download',
    click_coupon_download_all: 'click_coupon_download_all',
    click_post_style_finder: 'click_post_style_finder',
    click_share: 'click_share',
    click_tv_shop_item: 'click_tv_shop_item',
    click_shop_section: 'click_shop_section',
    click_home_best_shop_section: 'click_home_best_shop_section',
    click_home_best_shop_item: 'click_home_best_shop_item',
    click_item_order: 'click_item_order',
    click_store: 'click_store',
    click_add_to_cart: 'click_add_to_cart',
    click_like: 'click_like',
    click_setting_button: 'click_setting_button',
    click_edit_profile: 'click_edit_profile',
    click_order_list: 'click_order_list',
    click_post: 'click_post',
    click_order_detail: 'click_order_detail',
    click_search_bar: 'click_search_bar',
    click_phone_order: 'click_phone_order',
    click_post_style_finder_search: 'click_post_style_finder_search',
    click_home_best_community_section: 'click_home_best_community_section',
    click_home_best_community_post: 'click_home_best_community_post',
    click_tag: 'click_tag',
    click_shop_category: 'click_shop_category',
    click_address_manage: 'click_address_manage',
    click_agreement: 'click_agreement',
    click_withdrawal: 'click_withdrawal',
    click_privacy_policy: 'click_privacy_policy',
    click_contact: 'click_contact',
    click_sign_out: 'click_sign_out',
    click_recommended_item: 'click_recommended_item',
    click_best_item: 'click_best_item',
    click_item_card: 'click_item_card',
    click_store_item: 'click_store_item',
    click_topic_upload: 'click_topic_upload',
    click_follow: 'click_follow',
    click_unfollow: 'click_unfollow',
    click_tv_post: 'click_tv_post',
    click_channel_talk: 'click_channel_talk',

    save_store_bookmark: 'save_store_bookmark',

    delete_cart_item: 'delete_cart_item',
    delete_store_bookmark: 'delete_store_bookmark',

    update_cart_item: 'update_cart_item',

    complete_reply: 'complete_reply',
    complete_withdrawal: 'complete_withdrawal',
    complete_writing_post: 'complete_writing_post',
    complete_editing_post: 'complete_editing_post',
    complete_share: 'complete_share',

    play_tv_post: 'play_tv_post',

    post_detail: 'post_detail',

    share_store_home: 'share_store_home',

    load_more_data: 'load_more_data'
  };

  amplitude_new = {
    app_open: 'app_open',

    request_login: 'request_login',
    request_order_call: 'request_order_call',

    add_to_wish: 'add_to_wish',

    remove_wish: 'remove_wish',

    select_detail_category: 'select_detail_category',
    select_filter: 'select_filter',
    select_item_option: 'select_item_option',
    select_pay_method: 'select_pay_method',

    open_filter_list: 'open_filter_list',

    share_item: 'share_item',
    share_post: 'share_post',
    share_store: 'share_store',

    acquire_coupon: 'acquire_coupon',
    acquire_coupon_all: 'acquire_coupon_all',

    use_coupon: 'use_coupon',
    use_point: 'use_point',

    complete_signup: 'complete_signup',
    complete_login: 'complete_login',
    complete_search: 'complete_search',
    complete_share: 'complete_share',
    complete_pay: 'complete_pay',
    complete_pay_item: 'complete_pay_item',

    enter_signup: 'enter_signup',
    enter_app_download: 'enter_app_download',
    enter_wish_list: 'enter_wish_list',
    enter_home_tab: 'enter_home_tab',
    enter_brand_tab: 'enter_brand_tab',
    enter_brand_list: 'enter_brand_list',
    enter_best_tab: 'enter_best_tab',
    enter_hotdeal_tab: 'enter_hotdeal_tab',
    enter_cart: 'enter_cart',
    enter_search: 'enter_search',
    enter_category_home: 'enter_category',
    enter_category: 'enter_category',
    enter_store_home: 'enter_store_home',
    enter_search_tab: 'enter_search_tab',
    enter_coupon_list: 'enter_coupon_list',
    enter_review_list: 'enter_review_list',
    enter_item_option_list: 'enter_item_option_list',
    enter_payment: 'enter_payment',

    close_signup: 'close_signup',
    close_app_download: 'close_app_download',
    close_filter_list: 'close_filter_list',
    close_item_option_list: 'close_item_option_list',

    click_app_download_button: 'click_app_download_button',
    click_banner: 'click_banner',
    click_item: 'click_item',
    click_menu: 'click_menu',
    click_search: 'click_search',
    click_category: 'click_category',
    click_store_tab: 'click_store_tab',
    click_user_profile: 'click_user_profile',
    click_tag: 'click_tag',
    click_review_all: 'click_review_all',
    click_order_call: 'click_order_call',
    click_order: 'click_order',
    click_add_to_cart: 'click_add_to_cart',
    click_purchase: 'click_purchase',
    click_select_coupon: 'click_select_coupon',
    click_use_all_point: 'click_use_all_point',
    click_pay: 'click_pay',
    click_notice_dialog: 'click_notice_dialog',

    where: {
      top_banner: 'top_banner',
      bottom_modal: 'bottom_modal',
      like_button: 'like_button'
    },

    from: {
      bottom_modal: 'bottom_modal',
      featured_content_detail: 'featured_content_detail',
      fumi_tv_detail: 'fumi_tv_detail',
      style_menu: 'style_menu',
      store_profile: 'store-profile'
    },
    pay_method: {
      nice: 'easy_pay',
      html5_inicis: 'card',
      vbank: 'remit',
      card: 'card',
      account: 'virtual_account',
      accountLegacy: 'remit',
      kakaopay: 'kakaopay',
      call: 'call'
    },
    component_type: {
      banner: 'banner',
      top_banner: 'top_banner',
      bottom_banner: 'bottom_banner',
      center_banner: 'center_banner',
      modal: 'modal',
      top_modal: 'top_modal',
      bottom_modal: 'bottom_modal',
      center_modal: 'center_modal',
      button: 'button',
      icon: 'icon'
    }
  };

  modal_type = {
    sign_up: 'sign_up',
    download: 'download',
    notice_dialog: 'notice_dialog'
  };

  section_type = {
    section: 'section',
    hotdeal: 'hotdeal',
    similar_section: 'similar_section',
    stylist_section: 'stylist_section'
  };

  item_card_type = {
    main_normal: 'main_normal',
    main_user: 'main_user',
    page_normal: 'page_normal',
    home_hotdeal: 'home_hotdeal',
    home_section: 'home_section',
    hotdeal: 'hotdeal',
    recommend_item: 'recommend_item',
    similar_item: 'similar_item',
    skeleton: 'skeleton',
    home_recommend_item: 'home_recommend_item',
    thum_img_title_only: 'thum_img_title_only',
    app_exit: 'app_exit'
  };

  post_list_type = {
    similar_item: 'similar_item'
  };

  bank_list = [
    'KB국민은행',
    'SC제일은행',
    '경남은행',
    '광주은행',
    '기업은행',
    '농협',
    '대구은행',
    '부산은행',
    '산업은행',
    '새마을금고',
    '수협',
    '신한은행',
    '신협',
    '외환은행',
    '우리은행',
    '우체국',
    '전북은행',
    '축협',
    '카카오뱅크',
    '케이뱅크',
    '하나은행',
    '한국씨티은행'
  ];

  custom_content_type = {
    html: 'html',
    section: 'section',
    item: 'item'
  };

  log_filer = {
    item: ['item_idx', 'title', 'item_sell_price', 'item_origin_price'],
    class_room: ['class_room_idx', 'title'],
    banner: ['banner_idx', 'banner_title', 'banner_location', 'banner_link_type', 'banner_img_url', 'banner_link_url', 'display_order', 'banner_type', 'regist_datetime'],
    shop_section: ['shop_section_idx', 'shop_section_title', 'shop_section_type', 'shop_section_mode', 'shop_section_value'],
    cart: ['shop_cart_idx', 'item_idx', 'item_option_idx', 'total_count', 'arrival_source', 'app_open_pid', 'app_open_campaign', 'app_open_adset', 'onelink_idx', 'ref_idx', 'ref_table'],
    order_transaction: [
      'order_transaction_idx',
      'user_idx',
      'sales_channel_idx',
      'sales_channel_order_number',
      'post_code',
      'order_transaction_title',
      'total_count',
      'total_sell_price',
      'total_user_pay_price',
      'payment_type',
      'coupon_idx',
      'total_use_point'
    ],
    order_item: [
      'order_item_idx',
      'user_idx',
      'order_transaction_idx',
      'item_idx',
      'item_option_idx',
      'item_option_text',
      'sell_price',
      'origin_price',
      'buy_price',
      'user_pay_price',
      'use_point',
      'arrival_source',
      'app_open_pid',
      'app_open_campaign',
      'app_open_adset',
      'onelink_idx',
      'ref_idx',
      'ref_table',
      'shop_cart_idx'
    ],
    notice_dialog: ['notice_dialog_idx', 'title', 'img_url', 'link_url', 'button_text', 'button_color', 'link_url_two', 'button_text_two', 'button_color_two', 'type', 'is_open', 'display_order']
  };

  constructor() {}
}
