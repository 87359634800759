import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { UtilService } from '../util.service';
import { TrakingBase } from './tracker-base';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Capacitor3KakaoAd } from 'capacitor3-kakao-ad';
import { Capacitor } from '@capacitor/core';

declare var kakaoPixel;
@Injectable({
  providedIn: 'root'
})
export class PixelTrackerService extends TrakingBase {
  track_id: string;

  have_plugin = false;
  checked_have_plugin = false;

  constructor(private utils: UtilService) {
    super();
    this.track_id = environment.kakao_pixel;

    Capacitor3KakaoAd.initializePixel({ track_id: this.track_id });
  }

  async checkPlugin() {
    if (!this.checked_have_plugin) {
      this.checked_have_plugin = true;

      const platform = Capacitor.getPlatform();
      if (platform !== 'web') {
        const info = await App.getInfo();
        if (info && info.build) {
          const current_version = Number(info.build);
          if (current_version >= 285) {
            this.have_plugin = true;
          }
        }
      } else {
        this.have_plugin = true;
      }
    }

    return this.have_plugin;
  }

  public async appInit(data: any): Promise<boolean> {
    if (await this.checkPlugin()) {
    } else {
    }
    // await this.logEvent({ name: 'app_init', params: data });
    return true;
  }

  public async initialize(): Promise<boolean> {
    return true;
  }

  public async setUserIdx(user_idx: number): Promise<boolean> {
    // await FirebaseAnalytics.setUserId({ userId: `${user_idx}` });
    return true;
  }

  public async logPageView(data: any): Promise<boolean> {
    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.pageView({});
    } else {
      await kakaoPixel(this.track_id).pageView();
    }

    return true;
  }

  async appInstall(data: any) {
    data = this.utils.filterFbObject(data);
    // await FirebaseAnalytics.logEvent({ name: 'app_install', params: data });
    return true;
  }

  public async appOpen(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await FirebaseAnalytics.logEvent({ name: 'app_open', params: data });
    return true;
  }

  public async setUserProperty(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const aData = { name: keys[i], value: `${data[keys[i]]}` };
      // await FirebaseAnalytics.setUserProperty(aData);
    }
    return true;
  }

  public async logItemImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await FirebaseAnalytics.logEvent({
    //   name: 'item_impression',
    //   params: data
    // });
    return true;
  }

  public async logItemView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);

    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.viewContent({ id: `${data.item_idx}` });
    } else {
      await kakaoPixel(this.track_id).viewContent({
        id: `${data.item_idx}`
      });
    }

    // await FirebaseAnalytics.logEvent({
    //   name: 'item_view',
    //   params: data
    // });
    return true;
  }

  public async logItemClick(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await FirebaseAnalytics.logEvent({
    //   name: 'item_click',
    //   params: data
    // });
    return true;
  }

  public async logCompleteOrderTransaction(data: any): Promise<boolean> {
    let products: any = [];
    if (data.order_item_list) {
      for (let i = 0; i < data.order_item_list.length; i++) {
        const item = data.order_item_list[i];
        products.push({
          id: `${item.item_idx}`,
          name: `${item.order_item_title}`,
          quantity: `${item.order_count}`,
          price: `${item.sell_price}`
        });
      }
    }

    const k_data = {
      total_quantity: `${data.total_count}`, // 주문 내 상품 개수(optional)
      total_price: `${data.total_sell_price}`, // 주문 총 가격(optional)
      currency: 'KRW', // 주문 가격의 화폐 단위(optional, 기본 값은 KRW)
      products
    };

    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.purchase(k_data);
    } else {
      await kakaoPixel(this.track_id).purchase(k_data);
    }

    return true;
  }

  public async logCompleteOrderItem(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await FirebaseAnalytics.logEvent({
    //   name: 'complete_order_item',
    //   params: data
    // });
    return true;
  }

  public async logBannerView(banner: any): Promise<boolean> {
    banner = this.utils.filterFbObject(banner);
    await this.logEvent({
      name: 'banner_view',
      params: banner
    });
    return true;
  }

  public async logBannerImpression(banner: any): Promise<boolean> {
    banner = this.utils.filterFbObject(banner);
    // await this.logEvent({
    //   name: 'banner_impression',
    //   params: banner
    // });
    return true;
  }

  public async logShopSectionView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await this.logEvent({
    //   name: 'shop_section_view',
    //   params: data
    // });
    return true;
  }
  public async logShopSectionImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await this.logEvent({
    //   name: 'shop_section_impression',
    //   params: data
    // });
    return true;
  }
  public async logNoticeDialogView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await this.logEvent({
    //   name: 'notice_dialog_view',
    //   params: data
    // });
    return true;
  }
  public async logNoticeDialogImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await this.logEvent({
    //   name: 'notice_dialog_impression',
    //   params: data
    // });
    return true;
  }
  public async login(data: any): Promise<boolean> {
    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.completeRegistration();
    } else {
      await kakaoPixel(this.track_id).completeRegistration();
    }

    // await this.logEvent({
    //   name: 'login',
    //   params: data
    // });

    return true;
  }
  public async logPurchase(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    // await this.logEvent({
    //   name: 'purchase',
    //   params: data
    // });
    return true;
  }
  public async logAddToCart(data: any): Promise<boolean> {
    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.addToCart({
        id: `${data.item_idx}`,
        tag: 'native'
      });
    } else {
      await kakaoPixel(this.track_id).addToCart({
        id: `${data.item_idx}`
      });
    }

    return true;
  }

  public async logWishItem(data: any): Promise<boolean> {
    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.addToWishList({
        id: `${data.item_idx}`
      });
    } else {
      await kakaoPixel(this.track_id).addToWishList({
        id: `${data.item_idx}`
      });
    }

    return true;
  }

  public async logSearchItem(data: any): Promise<boolean> {
    if (await this.checkPlugin()) {
      await Capacitor3KakaoAd.search({
        keyword: `${data.keyword}`
      });
    } else {
      await kakaoPixel(this.track_id).search({
        keyword: `${data.keyword}`
      });
    }

    return true;
  }
  public async logSearchPost(data: any): Promise<boolean> {
    return true;
  }

  public async logEvent(data: { name: string; params: any }): Promise<boolean> {
    // await FirebaseAnalytics.logEvent(data);
    return true;
  }

  public async logPostImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logPostClick(data: any): Promise<boolean> {
    return true;
  }
  public async logPostView(data: any): Promise<boolean> {
    return true;
  }
  public async logPostLike(data: any): Promise<boolean> {
    return true;
  }
  public async logPostReply(data: any): Promise<boolean> {
    return true;
  }

  public async logUserCheckIn(data: any): Promise<boolean> {
    return true;
  }
  public async logUserCheckOut(data: any): Promise<boolean> {
    return true;
  }

  async logFumistView(class_room: any) {
    return true;
  }
  async logFumistImpression(class_room: any) {
    return true;
  }
  async logFumistJoin(class_room: any) {
    return true;
  }
  async logFumistClick(data: any): Promise<boolean> {
    return true;
  }
}
