export const routes = {
  main: {
    home: {
      home: 'home',
      'item-detail': 'item-detail',
      'shop-item-page': 'shop-item-page',
      search: 'search',
      'search-text': 'search-text',
      cart: 'cart',
      profile: 'profile',
      'brand-list': 'brand-list',
      wish: 'wish',
      'profile-follow': 'profile-follow',
      'pay-order': 'pay-order',
      'pay-callback': 'pay-callback',
      'item-review-list': 'item-review-list',
      'review-detail': 'review-detail',
      'pay-card-add': 'pay-card-add',
      'pay-card-edit': 'pay-card-edit',
      policy: 'policy',
      'address-list': 'address-list',
      alarm: 'alarm',
      notice: 'notice',
      'address-add': 'address-add',
      'coupon-add': 'coupon-add',
      'coupon-select': 'coupon-select',
      'store-profile': 'store-profile',
    },
    shop: {
      shop: 'shop',
      'shop-item-page': 'shop-item-page',
      'item-detail': 'item-detail',
      wish: 'wish',
      cart: 'cart',
      'pay-order': 'pay-order',
      'pay-callback': 'pay-callback',
      'item-review-list': 'item-review-list',
      'review-detail': 'review-detail',
      profile: 'profile',
      search: 'search',
      'search-text': 'search-text',
      'search-tag-post': 'search-tag-post',
      'post-detail': 'post-detail',
      'reply-detail': 'reply-detail',
      'pay-card-add': 'pay-card-add',
      'pay-card-edit': 'pay-card-edit',
      policy: 'policy',
      'address-list': 'address-list',
      'store-profile': 'store-profile',
      notice: 'notice',
      'address-add': 'address-add',
      'coupon-add': 'coupon-add',
      'coupon-select': 'coupon-select',
    },
    'fumi-tv': {
      'fumi-tv': 'fumi-tv',
      wish: 'wish',
      alarm: 'alarm',
      notice: 'notice',
      'item-detail': 'item-detail',
      cart: 'cart',
      'point-list': 'point-list',
      coupon: 'coupon',
      'order-list': 'order-list',
      'review-list': 'review-list',
      'review-detail': 'review-detail',
      'pay-order': 'pay-order',
      'pay-callback': 'pay-callback',
      'review-upload': 'review-upload',
      'review-edit': 'review-edit',
      'user-info': 'user-info',
      'address-add': 'address-add',
      search: 'search',
      'search-text': 'search-text',
      'search-tag-post': 'search-tag-post',
      'post-detail': 'post-detail',
    },
    style: {
      style: 'style',
      'post-detail': 'post-detail',
      upload: 'upload',
      'upload-content': 'upload-content',
      reply: 'reply',
      'reply-detail': 'reply-detail',
      'event-detail': 'event-detail',
      'featured-content-detail': 'featured-content-detail',
      search: 'search',
      'search-text': 'search-text',
      'search-tag-post': 'search-tag-post',
      'shop-item-page': 'shop-item-page',
      profile: 'profile',
      'profile-follow': 'profile-follow',
      'pay-card-add': 'pay-card-add',
      'item-review-list': 'item-review-list',
      'review-detail': 'review-detail',
      cart: 'cart',
      'pay-order': 'pay-order',
      'pay-callback': 'pay-callback',
      'address-list': 'address-list',
      alarm: 'alarm',
      notice: 'notice',
      'address-add': 'address-add',
      'coupon-add': 'coupon-add',
      'coupon-select': 'coupon-select',
      'item-detail': 'item-detail',
      'store-profile': 'store-profile',
    },
    my: {
      my: 'my',
      setting: 'setting',
      'profile-edit': 'profile-edit',
      cart: 'cart',
      'point-list': 'point-list',
      coupon: 'coupon',
      'coupon-add': 'coupon-add',
      'coupon-select': 'coupon-select',
      'coupon-available': 'coupon-available',
      'order-list': 'order-list',
      'review-list': 'review-list',
      'review-upload': 'review-upload',
      'review-edit': 'review-edit',
      'user-info': 'user-info',
      'address-add': 'address-add',
      'order-detail': 'order-detail',
      'app-version': 'app-version',
      'delete-user': 'delete-user',
      'pay-order': 'pay-order',
      'pay-callback': 'pay-callback',
      'item-review-list': 'item-review-list',
      'review-detail': 'review-detail',
      'pay-card-add': 'pay-card-add',
      'pay-card-edit': 'pay-card-edit',
      policy: 'policy',
      'address-list': 'address-list',
      'post-thum-list': 'post-thum-list',
      'post-detail': 'post-detail',
      'item-detail': 'item-detail',
      alarm: 'alarm',
      notice: 'notice',
      'store-profile': 'store-profile',
      'shop-item-page': 'shop-item-page',
    },
    recent: {
      recent: 'recent',
    },
  },
};
