import { Injectable } from '@angular/core';

import { DataProviderService } from 'src/app/services/graphql/data-provider.service';

import { doc, Firestore } from '@angular/fire/firestore';

import { Storage } from '@angular/fire/storage';
import { Subject } from 'rxjs';
import { Database } from '@angular/fire/database';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { Functions } from '@angular/fire/functions';
import { getDatabase, ref, onDisconnect, serverTimestamp } from 'firebase/database';
import { serverTimestamp as fsserverTimestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  uid = null;
  // currentUserData = null;
  logout$: Subject<boolean> = new Subject<boolean>();
  initialized = false;

  constructor(
    private fbdb: Database,
    private fbstore: Firestore,
    private fbauth: Auth,
    private graphqldb: DataProviderService,
    // private db: AngularFirestore,
    private storage: Storage,
    private fns: Functions
  ) {
    // this.fbauth.onAuthStateChanged((user) => {
    //   if (user) {
    //     this.uid = user.uid;
    //     this.registOnlineStatus();
    //   } else {
    //     this.uid = null;
    //   }
    //   this.initialized = true;
    // });
  }

  async logout() {
    // await this.fbauth.signOut();
    // this.logout$.next(true);
  }

  async login() {
    console.log('login');
    // try {
    //   const token = await this.graphqldb.get_chat_token();
    //   console.log('token', token);
    //   const result: any = await signInWithCustomToken(this.fbauth, token);

    //   console.log('result', result.user.uid);
    //   return result.user.uid;
    // } catch (error) {
    //   console.log('error login', error);
    //   return null;
    // }
  }

  async getFirebaseToken() {
    const token = await this.graphqldb.get_chat_token();
    return token;
  }

  async registOnlineStatus() {
    // var userStatusDatabaseRef = this.fbdb.database.ref('/status/' + this.uid);
    // var userStatusFirestoreRef = doc(this.fbstore, `/status/${this.uid}`);
    // var isOfflineForFirestore = {
    //   status: 'offline',
    //   last_changed: fsserverTimestamp()
    // };
    // var isOnlineForFirestore = {
    //   status: 'online',
    //   last_changed: fsserverTimestamp()
    // };
    // var isOfflineForDatabase = {
    //   status: 'offline',
    //   last_changed: serverTimestamp
    // };
    // var isOnlineForDatabase = {
    //   status: 'online',
    //   last_changed: serverTimestamp
    // };
    // // Create a reference to the special '.info/connected' path in
    // // Realtime Database. This path returns `true` when connected
    // // and `false` when disconnected.
    // this.fbdb.database.ref('.info/connected').on('value', function (snapshot) {
    //   // If we're not currently connected, don't do anything.
    //   if (snapshot.val() == false) {
    //     userStatusFirestoreRef.set(isOfflineForFirestore);
    //     return;
    //   }
    //   userStatusDatabaseRef
    //     .onDisconnect()
    //     .set(isOfflineForDatabase)
    //     .then(function () {
    //       userStatusDatabaseRef.set(isOnlineForDatabase);
    //       userStatusFirestoreRef.set(isOnlineForFirestore);
    //     });
    // });
  }

  //채팅방입장
  enterChatRoom(chat_room_key: string) {
    //update user data
    // if (!this.uid) {
    //   throw Error('로그인 안됨');
    // }
    // // /chat_room/manager_303952_1/chat_join_user/fumi_303952
    // const ref = `chat_room/${chat_room_key}/chat_join_user/${this.uid}`;
    // console.log('ref', ref);
    // return this.fbstore.doc(ref).set(
    //   {
    //     user_key: `${this.uid}`,
    //     status: 'online',
    //     last_changed: fsserverTimestamp()
    //   },
    //   { merge: true }
    // );
  }

  //채팅방 나가기
  exitChatRoom(chat_room_key: string) {
    // const ref = `chat_room/${chat_room_key}/chat_join_user/${this.uid}`;
    // return this.fbstore.doc(ref).set(
    //   {
    //     status: 'offline',
    //     last_changed: fsserverTimestamp()
    //   },
    //   { merge: true }
    // );
  }

  getGroups() {
    if (!this.uid) {
      throw Error('로그인 안됨');
    }

    // return this.fbstore.collection(`user/${this.uid}/chat_room`).valueChanges();
  }

  getOneGroup(chat_room_key: string) {
    // console.log('getOneGroup', chat_room_key);
    // return this.fbstore.doc(`chat_room/${chat_room_key}`).valueChanges();
  }

  insertChatRoom(data: any) {
    // console.log('insert_chat_room start', data);
    // const callable = this.fns.httpsCallable('insert_chat_room');
    // return callable(data);
  }

  join_store_chat_room(data: any) {
    // console.log('join_store_chat_room start', data);
    // const callable = this.fns.httpsCallable('join_store_chat_room');
    // return callable(data);
  }

  getChatMessages(chat_room_key: string) {
    // return this.fbstore.collection(`chat_room/${chat_room_key}/messages`).valueChanges({ idField: 'message_id' });
  }

  getChatJoin(chat_room_key: string) {
    // return this.fbstore.collection(`chat_room/${chat_room_key}/chat_join_user`).valueChanges();
  }

  addChatMessage(msg, chat_room_key: string) {
    // if (!this.uid) {
    //   throw Error('로그인 안됨');
    // }
    // return this.fbstore.doc(`chat_room/${chat_room_key}/messages/${msg.message_id}`).set(msg);
  }

  async getUserKey() {
    if (this.uid) {
      return this.uid;
    } else {
      await this.login();
      this.uid = await this.getUserKeyFromAuth();
      return this.uid;
    }
  }

  getUserKeyFromAuth() {
    return new Promise((resolve, reject) => {
      this.fbauth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user.uid);
        } else {
          resolve(null);
        }
      });
    });
  }

  getChatMessageId() {
    // return this.fbstore.createId();
    return 'abcd';
  }

  insertChatReadHistory(chat_room_key: string, message_id: string) {
    // if (!this.uid) {
    //   throw Error('로그인 안됨');
    // }
    // const now = new Date();
    // return this.fbstore.doc(`chat_room/${chat_room_key}/message_read_history/${this.uid}/history/${message_id}`).set({
    //   message_id,
    //   user_key: this.uid,
    //   status: 'ready',
    //   regist_datetime: now,
    //   update_datetime: now
    // });
  }
}
