import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor() {}

  filterObject(data: any, key_list: any = null) {
    let keys = key_list;
    if (!key_list) {
      keys = Object.keys(data);
    }
    const result: any = {};
    keys.forEach((key) => {
      const value = data[key];
      if (value == undefined || value == null) {
      } else {
        result[key] = value;
      }
    });
    return result;
  }

  filterFbObject(data: any, key_list: any = null) {
    let keys = key_list;
    if (!key_list) {
      keys = Object.keys(data);
    }
    const result: any = {};
    keys.forEach((key) => {
      let value = data[key];
      if (value == undefined || value == null) {
      } else {
        if (typeof value == 'boolean') {
          value = value ? 1 : 0;
        }
        result[key] = value;
      }
    });
    return result;
  }

  convertToUnixTimestamp(date: string | Date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }

  async create_meta_tag(name: string, content: string) {
    let meta = document.createElement('meta');
    meta.name = name;
    meta.content = content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  getFullHost() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  getBirthdayTimestamp(birthdate: string | Date, type: string) {
    const birthdayDate = new Date(birthdate);
    const birthdayMonth = birthdayDate.getMonth();
    const birthdayDay = birthdayDate.getDate();

    const currentDate = new Date();
    const currentDateUnixTimestamp = Math.round(currentDate.getTime() / 1000);
    const currentYear = currentDate.getFullYear();
    let birthdayMonthDay;
    if (type == 'day') {
      birthdayMonthDay = new Date(currentYear, birthdayMonth, birthdayDay);
    } else {
      birthdayMonthDay = new Date(currentYear, birthdayMonth);
    }
    let birthdayUnixTimestamp = Math.round(birthdayMonthDay.getTime() / 1000);
    const currentBirthdayPassed = Math.sign(birthdayUnixTimestamp - currentDateUnixTimestamp);

    let birthdayTimestamp = 0;

    if (currentBirthdayPassed === 1) {
      birthdayTimestamp = birthdayUnixTimestamp;
    } else if (currentBirthdayPassed === -1) {
      if (type == 'day') {
        birthdayMonthDay = new Date(currentYear + 1, birthdayMonth, birthdayDay);
      } else {
        birthdayMonthDay = new Date(currentYear + 1, birthdayMonth);
      }
      birthdayUnixTimestamp = Math.round(birthdayMonthDay.getTime() / 1000);
      birthdayTimestamp = birthdayUnixTimestamp;
    } else {
      console.log('birthdate time not set properly');
    }
    return birthdayTimestamp;
  }

  trimString(str: string, max = 100) {
    return this.chunkFromString(str, max)[0];
  }

  chunkFromString(str, chunkSize) {
    var arr = [];
    while (str !== '') {
      arr.push(str.substring(0, chunkSize));
      str = str.substring(chunkSize);
    }
    return arr;
  }
}
