import { Inject, Injectable } from '@angular/core';
// import * as amplitude from 'amplitude-js';
// import { environment } from 'src/environments/environment';
import {
  Banner,
  CouponRule,
  Item,
  ItemOption,
  OrderItem,
  OrderTransaction,
  Store,
  User,
} from './graphql/models';
import { Router } from '@angular/router';

import { routes } from 'src/app/routes';
import { ConstantService } from './constants.service';
@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  user: User;

  item_base_props: any;
  category_props: any;
  store_props: any;
  coupon_props: any;
  current_where: any;
  last_where: any;

  constructor(
    private router: Router,
    public constant: ConstantService,
  ) {
    // amplitude.getInstance().init(environment.amplitude);
  }

  async setUserId(user: User) {
    this.user = user;
    // amplitude.getInstance().setUserId(`${this.user.user_idx}`);
  }

  async setTempUserId(temp_user_idx: number) {
    const properties = {
      temp_user_idx: temp_user_idx,
    };
    // amplitude.getInstance().setUserProperties(properties);
  }

  async setUserProperties(properties: any) {
    // amplitude.getInstance().setUserProperties(properties);
  }

  // *주의* 프로퍼티에 tilde 문자(~)가 들어가있으면 크롬 익스텐션에서 이벤트가 잡히지 않습니다! (실제 Amplitude서버에는 정상적으로 이벤트가 발생합니다.)
  async sendEvent(type, properties = {}) {
    try {
      // amplitude.getInstance().logEvent(type, {
      //   event_type: type,
      //   user_id: this.user ? `${this.user.user_idx}` : null,
      //   event_properties: properties,
      // });
    } catch (error) {
      console.log('error event ' + type);
      console.log(error);
    }
  }

  async sendRevenueEvent(
    order_id,
    total_sell_price,
    quantity,
    revenueType,
  ) {
    try {
      // const revenue: amplitude.Revenue = new amplitude.Revenue()
      //   .setProductId(order_id.toString())
      //   .setQuantity(quantity)
      //   .setPrice(total_sell_price)
      //   .setRevenueType(revenueType);
      // amplitude.getInstance().logRevenueV2(revenue);
    } catch (error) {
      console.log(error);
    }
  }

  async setProperties(identify) {
    // try {
    //   amplitude.getInstance().identify(identify);
    // } catch (error) {
    //   console.log('error event ');
    //   console.log(error);
    // }
  }

  appOpen(user) {
    if (user) {
      this.sendEvent(this.constant.amplitude_new.app_open, {
        user_type: 'user',
      });
    } else {
      this.sendEvent(this.constant.amplitude_new.app_open, {
        user_type: 'guest',
      });
    }
  }

  appOpenSetUserProperties(user, isManager) {
    // if (!user) return;
    // const identify = new amplitude.Identify();
    // identify.setOnce('user_id', user.user_idx);
    // identify.setOnce('gender', user.user_gender);
    // identify.setOnce(
    //   'birth_year',
    //   new Date(user.user_birthday).getFullYear(),
    // );
    // identify.set('is_manager', isManager);
    // this.setProperties(identify);
  }

  private get10KRange(number) {
    const floor_value = Math.floor(number / 10000) * 10000;
    return floor_value + ' - ' + (floor_value + 9999);
  }

  private getRateRange(rate) {
    const floor_value = Math.floor(rate / 5) * 5;
    return floor_value + ' - ' + (floor_value + 4);
  }

  private getCountRange(count) {
    if (count == 0) {
      return '0';
    } else {
      const first_digit = count % 10;
      let range = Math.floor(count / 10);
      if (first_digit == 0) range -= 1;
      range *= 10;
      return range + 1 + ' - ' + (range + 10);
    }
  }

  public updateWhere(url, queryParams) {
    const where = {
      path: this.getWherePathFromUrl(url),
      query: queryParams,
    };
    this.last_where = this.current_where;
    this.current_where = where;
  }

  private getWherePathFromUrl(url) {
    let path = 'invalid';
    try {
      const url_tree = this.router.parseUrl(url);
      const segments = url_tree.root.children.primary.segments;
      if (segments.length > 2) {
        const route_1 = routes[segments[0].path];
        if (route_1) {
          const route_2 = route_1[segments[1].path];
          if (route_2) {
            path = route_2[segments[2].path];
          }
        }
      }
    } catch (err) {
      return 'invalid';
    }

    return path;
  }

  public getItemBaseProps(item: Item) {
    const discount_rate = Math.round(
      (1 - item.item_sell_price / item.item_origin_price) * 100,
    );
    const discount_amount =
      item.item_origin_price - item.item_sell_price;

    let store_props = {};
    if (item.store) {
      store_props = {
        store_idx: item.store.store_idx,
        store_name: item.store.name,
        store_type: item.store.type,
      };
    }

    let category_props = {};
    if (item.item_category) {
      category_props = {
        category_name: item.item_category.category_name,
      };
    }

    const item_base_props = {
      item_name: item.title,
      item_idx: item.item_idx,
      sell_price: item.item_sell_price,
      sell_price_range: this.get10KRange(item.item_sell_price),
      original_price: item.item_origin_price,
      original_price_range: this.get10KRange(item.item_origin_price),
      discount_rate: discount_rate,
      discount_rate_range: this.getRateRange(discount_rate),
      discount_amount: discount_amount,
      discount_amount_rage: this.get10KRange(discount_amount),
      ...store_props,
      ...category_props,
    };
    this.item_base_props = item_base_props;
    return item_base_props;
  }

  public getCategoryProps(category) {
    const category_props = {
      category_name: category.category_name,
      category_idx: category.category_idx,
      category_depth: category.level,
    };
    this.category_props = category_props;

    return category_props;
  }

  public getStoreProps(store: Store) {
    const store_props = {
      store_type: store.type,
      store_idx: store.store_idx,
      store_name: store.name,
      item_count: store.item_count,
      item_count_range: this.getCountRange(store.item_count),
      review_count: store.review_count,
      review_count_range: this.getCountRange(store.review_count),
      bookmark_count: store.bookmark_count,
      bookmark_count_range: this.getCountRange(store.bookmark_count),
    };
    this.store_props = store_props;

    return store_props;
  }

  public getPayProps(order_transaction: OrderTransaction) {
    let pay_method;
    if (order_transaction.payment_type) {
      pay_method =
        this.constant.amplitude_new.pay_method[
          order_transaction.payment_type
        ];
    }

    const order_transaction_props = {
      pay_method: pay_method,
      quantity: order_transaction.total_count,
      total_sell_price: order_transaction.total_sell_price,
      total_sell_price_range: this.get10KRange(
        order_transaction.total_sell_price,
      ),
      total_user_pay_price: order_transaction.total_user_pay_price,
      total_uesr_pay_price_range: this.get10KRange(
        order_transaction.total_user_pay_price,
      ),
      total_use_point: order_transaction.total_use_point
        ? order_transaction.total_use_point
        : 0,
      total_use_coupon_price: order_transaction.total_use_coupon_price
        ? order_transaction.total_use_coupon_price
        : 0,
    };

    return order_transaction_props;
  }

  public getPayItemProps(order_item: OrderItem) {
    let item: Item = {};
    if (order_item.item) {
      item = order_item.item;
    }

    let store: Store = {};
    if (item && item.store) {
      store = item.store;
    }

    let item_option: ItemOption = {};
    if (order_item.item_option) {
      item_option = order_item.item_option;
    }

    const sell_price = Math.trunc(
      order_item.sell_price / order_item.order_count,
    );

    const pay_item_props = {
      order_transaction_idx: order_item.order_transaction_idx,
      order_item_idx: order_item.order_item_idx,
      item_idx: order_item.order_item_idx,
      item_name: order_item.order_item_title,
      item_option: item_option.item_option_name,
      sell_price: sell_price,
      sell_price_range: this.get10KRange(sell_price),
      category: item.category ? item.category : 'none',
      category_idx: item.category_idx ? item.category_idx : 'none',
      size: item.size ? item.size : 'none',
      is_recommanded_item: item.is_recommend,
      is_open_item: item.is_open,
      store_idx: store.store_idx ? store.store_idx : 'none',
      store_name: store.name ? store.name : 'none',
      store_type: store.type ? store.type : 'none',
    };

    return pay_item_props;
  }

  public getCouponRuleProps(coupon_rule: CouponRule) {
    const coupon_rule_props = {
      coupon_rule_group_idx: coupon_rule.coupon_rule_group_idx
        ? coupon_rule.coupon_rule_group_idx
        : 'none',
      coupon_rule_idx: coupon_rule.coupon_rule_idx,
      coupon_name: coupon_rule.title,
      type: coupon_rule.type,
      amount: coupon_rule.amount,
      amount_range: this.get10KRange(coupon_rule.amount),
      min_order_amount: coupon_rule.min_order_amount,
      min_order_amount_range: this.get10KRange(
        coupon_rule.min_order_amount,
      ),
      use_ref_table: coupon_rule.use_ref_table
        ? coupon_rule.use_ref_table
        : 'none',
      use_ref_idx_string: coupon_rule.use_ref_idx_string
        ? coupon_rule.use_ref_idx_string
        : 'none',
      code: coupon_rule.code ? coupon_rule.code : 'none',
      valid_day: coupon_rule.valid_day
        ? coupon_rule.valid_day
        : 'none',
    };

    return coupon_rule_props;
  }

  public getBannerProps(banner: Banner) {
    const banner_props = {
      banner_idx: banner.banner_idx,
      banner_title: banner.banner_title,
      banner_location: banner.banner_location,
      banner_link_type: banner.banner_link_type,
      banner_link_url: banner.banner_link_url,
      regist_datetime: banner.regist_datetime,
    };

    return banner_props;
  }
}
