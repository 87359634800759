import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from 'src/environments/environment';
import { TrakingBase } from './tracker-base';

@Injectable({
  providedIn: 'root'
})
export class OnesignalTrackerService extends TrakingBase {
  user_idx: number;
  constructor(private router: Router, public route: ActivatedRoute, private zone: NgZone, private oneSignal: OneSignal) {
    super();
  }

  public async appInit(data: any): Promise<boolean> {
    // throw new Error('Method not implemented.');
    return true;
  }

  public async initialize(): Promise<boolean> {
    return true;
  }

  public async setUserIdx(user_idx: number): Promise<boolean> {
    this.user_idx = user_idx;
    return true;
  }

  async setDeviceIdx(user_device_idx: number) {
    return true;
  }

  async appInstall(data: { platform: string; af_status: string; af_message: string; deep_link_value: string; user_idx: number; user_device_idx: number }) {
    return true;
  }
  public async appOpen(data: { platform: string; app_open_pid: string; app_open_campaign: string; app_open_adset: string; user_idx: number; user_device_idx: number }): Promise<boolean> {
    return true;
  }

  public async logPageView(data: any): Promise<boolean> {
    return true;
  }
  public async logItemClick(data: any): Promise<boolean> {
    return true;
  }
  public async logCompleteOrderTransaction(data: any): Promise<boolean> {
    return true;
  }
  public async logCompleteOrderItem(data: any): Promise<boolean> {
    return true;
  }
  public async logItemView(data: any): Promise<boolean> {
    return true;
  }

  public async logItemImpression(data: any): Promise<boolean> {
    return true;
  }
  public async setUserProperty(data: any): Promise<boolean> {
    return true;
  }

  public async logBannerView(data: any): Promise<boolean> {
    return true;
  }
  public async logBannerImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logShopSectionView(data: any): Promise<boolean> {
    return true;
  }
  public async logShopSectionImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logNoticeDialogView(data: any): Promise<boolean> {
    return true;
  }
  public async logNoticeDialogImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logEvent(data: any): Promise<boolean> {
    return true;
  }
  public async login(data: any): Promise<boolean> {
    return true;
  }
  public async logPurchase(data: any): Promise<boolean> {
    return true;
  }
  public async logAddToCart(data: any): Promise<boolean> {
    return true;
  }

  public async logPostImpression(data: any): Promise<boolean> {
    return true;
  }
  public async logPostClick(data: any): Promise<boolean> {
    return true;
  }
  public async logPostView(data: any): Promise<boolean> {
    return true;
  }
  public async logPostLike(data: any): Promise<boolean> {
    return true;
  }
  public async logPostReply(data: any): Promise<boolean> {
    return true;
  }

  public async logSearchItem(data: any): Promise<boolean> {
    return true;
  }
  public async logSearchPost(data: any): Promise<boolean> {
    return true;
  }

  public async logWishItem(data: any): Promise<boolean> {
    return true;
  }
  public async logUserCheckIn(data: any): Promise<boolean> {
    return true;
  }
  public async logUserCheckOut(data: any): Promise<boolean> {
    return true;
  }

  async logFumistView(class_room: any) {
    return true;
  }
  async logFumistImpression(class_room: any) {
    return true;
  }
  async logFumistJoin(class_room: any) {
    return true;
  }

  async logFumistClick(data: any): Promise<boolean> {
    return true;
  }
}
