import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import {
  select_home_post_list,
  select_home_post_list_v3,
  search_post_list,
  update_post_open_status,
  select_tag_post_list,
  get_contest_join,
  get_contest,
  delete_contest_join,
  get_config_service,
  select_push_alarm_list,
  select_like_list,
  is_like,
  insert_report,
  select_tag_list,
  select_user_list,
  delete_user,
  insert_access_history,
  shop_select_item,
  select_notice_list,
  add_address,
  delete_address,
  update_address,
  select_follow_list,
  is_follow,
  insert_order_call,
  select_reply_list,
  insert_reply,
  update_reply,
  select_reply_list_all,
  select_search_recommend_list,
  insert_ad_proxy,
  select_payment_type_list,
  select_user_address_book_list,
  insert_user_order_transaction_v2,
  check_pay_result,
  select_user_order_transaction_list,
  select_banner_list_by_type,
  shop_select_shop_section,
  select_recent_topic,
  shop_select_user_cart_list,
  shop_insert_user_cart,
  shop_update_user_cart,
  shop_delete_user_cart,
  shop_select_count_user_cart_list,
  login_social,
  login_social_with_status_v3,
  user_insert_error_log,
  user_select_my_data_v2,
  user_select_order_transaction_list,
  user_select_order_transaction,
  user_select_all_post_list,
  get_post,
  update_post,
  select_item_review_list,
  select_order_item_list,
  insert_item_review,
  file_upload,
  point_select_point_rule,
  select_order_item_can_review_list,
  update_item_review,
  point_select_user_total,
  point_select_user_point_list,
  shop_select_all_shop_section,
  shop_select_shop_section_group,
  post_select_fumi_tv_list,
  post_select_fumi_tv,
  post_select_best_list,
  post_select_best_list_detail,
  shop_select_category_list,
  shop_select_item_with_category,
  shop_search_item,
  payment_select_card_list,
  payment_issue_card,
  payment_delete_card,
  payment_pay_billkey,
  local_select_item_recent,
  local_insert_item_recent,
  user_select_user_info,
  user_update_user_info,
  event_select_event_list_all,
  tag_select_fumi_tv_post_by_tag_string,
  tag_count_post_by_tag_string,
  tag_select_post_by_tag_string,
  post_select_featured_content,
  post_select_featured_content_list,
  event_select_event_list_detail,
  content_order_select_content_order_list,
  notice_dialog_select_notice_dialog,
  order_select_order_transaction,
  coupon_select_issuable_all_coupon_list,
  coupon_select_valid_coupon_list,
  coupon_select_coupon_list,
  coupon_insert_coupon_list,
  coupon_insert_coupon_by_code,
  coupon_select_coupon_usable_item_list,
  coupon_select_issuable_item_coupon_list,
  get_similar_items,
  post_select_post_detail_list,
  post_select_post_detail_list_profile,
  get_post_similar_items,
  insert_shop_wish,
  delete_shop_wish,
  select_shop_wish_list,
  select_category_item_list,
  select_brand_item_list,
  get_brand_list,
  select_sorted_brand,
  local_select_shop_wish,
  select_item_list_by_idx_list,
  insert_bulk_shop_wish,
  shop_select_hotdeal_section,
  get_stylist_items,
  upsert_user_refund_account,
  select_user_refund_account,
  insert_post_item_view_history,
  user_select_onesignal,
  insert_chat_room,
  select_chat_room,
  select_chat_message,
  insert_chat_message,
  select_chat_join,
  get_chat_join,
  post_select_fumi_live_tv_list,
  post_select_fumi_live_tv_detail,
  get_item,
  get_chat_token,
  after_insert_chat_message,
  insert_follow,
  delete_follow,
  delete_like,
  insert_like,
  select_user_like_list,
  insert_post_tv_like,
  delete_post_tv_like,
  select_post_tv_like_list,
  select_shop_wish_list_all,
  select_home_post_list_v4,
  login_temp_user,
  login_temp_user_to_user,
  get_available_item_coupon_max_amount,
  insert_item_view,
  delete_item_view,
  select_user_item_view,
  insert_user_device,
  insert_user_device_player_id,
  login_legacy_user_to_user,
  delete_all_item_view,
  insert_onelink,
  insert_item_report,
  shop_insert_user_cart_v2,
  shop_delete_user_cart_v2,
  shop_insert_order_transaction_pending,
  shop_select_user_cart_list_v2,
  insert_user_order_transaction_v3,
  order_select_pending_order_transaction,
  select_order_transaction_pending,
  select_notice_list_title_only,
  get_onelink,
  insert_user_attendance,
  user_select_point_ready_list,
  user_select_point_list,
  insert_user_invite_history,
  select_item_list_not_viewed,
  insert_order_refer,
  select_order_item_can_review_list_v2,
  update_order_item_complete,
  refresh_order_item_can_review_list,
  refresh_order_transaction_list,
  refresh_user_info,
  get_fumist_idx,
  refresh_class_room,
  update_class_room,
  update_class_room_post_list,
  update_class_room_shop_section_list,
  update_shop_section,
  update_class_room_shop_section,
  refresh_class_room_join,
  refresh_class_customer_order_item,
  insert_class_room_point_to_user,
  refresh_class_join,
  refresh_class_room_point,
  refresh_class_room_post,
  refresh_class_room_shop_section,
  delete_class_room_shop_section,
  refresh_user_point_list,
  insert_class_join,
  insert_order_cancel,
  insert_order_cancel_all,
  delete_class_join,
  refresh_class_room_shop_section_force
} from './queries';
import {
  State,
  Contest,
  ContestJoin,
  InputAccessHistory,
  InputReport,
  InputPost,
  SelectBannerListReturn,
  SelectPostListReturn,
  SelectTagListReturn,
  SelectNoticeListReturn,
  InputAddressBook,
  InputReply,
  SearchRecommendListReturn,
  InputAdProxy,
  SelectPaymentListReturn,
  SelectAddressBookListReturn,
  OrderTransaction,
  InputUserOrder,
  InputUserPaymentCallback,
  SelectOrderTransactionListReturn,
  SelectShopSectionListReturn,
  ShopSection,
  Event,
  SelectShopCartListReturn,
  InputShopCart,
  InputPostResource,
  InputReview,
  Upload,
  UploadFile,
  Post,
  Review,
  SelectCategoryListReturn,
  SelectItemListReturn,
  InputIssueBillingKeyInfo,
  InputSubscribeCardInfo,
  SelectSubscribeCardInfoListReturn,
  InputIamportRequestBill,
  Item,
  Topic,
  InputUserInfo,
  PostRecommendItems,
  SelectBrandListReturn,
  ShopWish,
  SelectStoreListReturn,
  Store,
  InputUserRefundAccount,
  UserRefundAccount,
  InputChatRoom,
  SelectChatRoomListReturn,
  SelectChatJoinListReturn,
  SelectChatMessageListReturn,
  InputChatMessage,
  ChatJoin,
  PostTv,
  SelectPostTvListReturn,
  InputFollow,
  InputLike,
  InputPostTvLike,
  InputUserItemView,
  InputUserDevice,
  InputOnelink,
  InputItemReport,
  InputOrderTransactionPending,
  SelectOrderTransactionPendingListReturn,
  InputUserAttendance,
  InputUserInviteHistory,
  InputOrderRefer,
  InputOrderItem,
  InputClassRoom,
  InputClassRoomPostList,
  InputClassRoomShopSectionList,
  InputShopSection,
  InputClassRoomPoint,
  InputClassJoin,
  InputOrderCancel,
  InputClassRoomShopSection
} from './models';
import { select_store_list, select_store } from './store_queries';
import { get_ab_test } from './abtest_queries';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
  constructor(private fns: Functions, private apollo: Apollo, private storage: Storage) {}

  //컨테스트 정보가져오기
  public async get_contest(contest_idx: number): Promise<Contest> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_contest,
        variables: { contest_idx }
      })
      .toPromise();
    console.log('get value = ', result);
    return result.data.get_contest;
  }

  //컨테스트 참여 정보가져오기
  public async get_contest_join(contest_idx: number): Promise<ContestJoin> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_contest_join,
        variables: { contest_idx }
      })
      .toPromise();
    return result.data.get_contest_join;
  }

  //컨테스트 참여 정보가져오기
  public async delete_contest_join(contest_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_contest_join,
        variables: { contest_idx }
      })
      .toPromise();
    return result.data.delete_contest_join;
  }

  // 포스트 데이터 가져오기
  public async get_post(post_idx: number) {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_post,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.get_post;
  }

  //포스트 업데이트
  public async update_post(data: InputPost, post_resource_list: Array<InputPostResource>) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_post,
        variables: { data, post_resource_list }
      })
      .toPromise();
    return result.data.update_post;
  }

  //홈 포스팅 리스트 가져오기
  public async select_home_post_list(state: State, type: string): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_home_post_list,
        variables: { state, type }
      })
      .toPromise();
    console.log(result.data);
    return result.data.select_home_post_list;
  }

  //홈 포스팅 리스트 가져오기
  public async select_home_post_list_v3(state: State, type: string): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_home_post_list_v3,
        variables: { state, type }
      })
      .toPromise();
    return result.data.select_home_post_list_v3;
  }

  public async select_home_post_list_v4(state: State, type: string, last_post_idx: number): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_home_post_list_v4,
        variables: { state, type, last_post_idx }
      })
      .toPromise();
    return result.data.select_home_post_list_v4;
  }

  //포스팅 리스트 검색하기
  //this.db.search_post_list(state: {sort:[{field:"post_idx",dir:"asc"}] skip: 0, take: 10, filter:{logic:"and",filters:[{field:"post_type",operator:"eq",value:"vote"}]}})
  public async search_post_list(state: State): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: search_post_list,
        variables: { state }
      })
      .toPromise();
    return result.data.search_post_list;
  }

  //좋아요 리스트 가져오기
  public async select_like_list(post_idx: number, state: State) {
    const result: any = await this.apollo
      .query({
        query: select_like_list,
        variables: { state, post_idx }
      })
      .toPromise();
    return result.data.select_like_list;
  }

  //댓글 목록 가져오기
  public async select_reply_list(post_idx: number, state: State) {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_reply_list,
        variables: { state, post_idx }
      })
      .toPromise();
    return result.data.select_reply_list;
  }

  //댓글 목록 모두 가져오기(대댓글 순서 고려)
  public async select_reply_list_all(post_idx: number) {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_reply_list_all,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.select_reply_list_all;
  }

  //댓글 추가하기
  public async insert_reply(data: InputReply) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_reply,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_reply;
  }

  //댓글 수정하기
  public async update_reply(data: InputReply) {
    const result = await this.apollo
      .mutate({
        mutation: update_reply,
        variables: { data }
      })
      .toPromise();
    return result;
  }

  //시스템 컨피그 가져오기
  public async get_config_service(key_name: string) {
    const result: any = await this.apollo
      .query({
        query: get_config_service,
        variables: { key_name }
      })
      .toPromise();
    return result.data.get_config_service;
  }

  //알림 목록 가져오기
  public async select_push_alarm_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_push_alarm_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_push_alarm_list;
  }

  //접속 기록
  public async insert_access_history(data: InputAccessHistory) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_access_history,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_access_history;
  }

  //신고하기
  public async insert_report(data: InputReport) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_report,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_report;
  }

  //탈퇴하기
  public async delete_user() {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_user
      })
      .toPromise();
    return result.data.delete_user;
  }

  //공개 상태 변경
  public async update_post_open_status(data: InputPost) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_post_open_status,
        variables: { data }
      })
      .toPromise();
    return result.data.update_post_open_status;
  }

  //배너 리스트 가져오기
  public async select_banner_list_by_type(state: State, type: string): Promise<SelectBannerListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_banner_list_by_type,
        variables: { state, banner_type: type }
      })
      .toPromise();
    return result.data.select_banner_list_by_type;
  }

  //공지사항 리스트 가져오기
  public async select_notice_list(state: State): Promise<SelectNoticeListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_notice_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_notice_list;
  }

  public async select_notice_list_title_only(state: State): Promise<SelectNoticeListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_notice_list_title_only,
        variables: { state }
      })
      .toPromise();
    return result.data.select_notice_list_title_only;
  }

  //검색 추천 리스트 가져오기
  public async select_search_recommend_list(state: State): Promise<SearchRecommendListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_search_recommend_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_search_recommend_list;
  }

  //태그 리스트 가져오기
  public async select_tag_list(state: State): Promise<SelectTagListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_tag_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_tag_list;
  }

  //태그 리스트 포스트 가져오기
  public async select_tag_post_list(state: State, tag_string: string): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_tag_post_list,
        variables: { state, tag_string }
      })
      .toPromise();
    return result.data.select_tag_post_list;
  }

  //사용자 리스트
  public async select_user_list(state: State): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_user_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_user_list;
  }

  //팔로워/팔로잉 리스트
  public async select_follow_list(user_idx: number, type: string, state: State) {
    const result: any = await this.apollo
      .query({
        query: select_follow_list,
        variables: { type, user_idx, state }
      })
      .toPromise();

    return result.data.select_follow_list;
  }

  //배송지 주소 저장하기
  public async add_address(data: InputAddressBook) {
    const result: any = await this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: add_address,
        variables: { data }
      })
      .toPromise();
    return result.data.add_address;
  }

  //배송지 주소 삭제하기
  public async delete_address(address_book_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_address,
        variables: { address_book_idx }
      })
      .toPromise();
    return result.data.delete_address;
  }

  //배송지 주소 업데이트
  public async update_address(data: InputAddressBook) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_address,
        variables: { data }
      })
      .toPromise();
    return result.data.update_address;
  }

  //상품 정보 가져오기
  public async shop_select_item(item_idx: number, ref_post_idx: number) {
    const result: any = await this.apollo
      .query({
        query: shop_select_item,
        variables: { item_idx, ref_post_idx }
      })
      .toPromise();
    return result.data.shop_select_item;
  }

  public async get_available_item_coupon_max_amount(item_idx: number, item_sell_price: number) {
    const result: any = await this.apollo
      .query({
        query: get_available_item_coupon_max_amount,
        variables: { item_idx, item_sell_price }
      })
      .toPromise();
    return result.data.get_available_item_coupon_max_amount;
  }

  //상품 정보 가져오기
  public async get_item(item_idx: number) {
    const result: any = await this.apollo
      .query({
        query: get_item,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.get_item;
  }

  //비슷한 상품 정보 가져오기
  public async get_similar_items(item_idx: number) {
    const result: any = await this.apollo
      .query({
        query: get_similar_items,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.get_similar_items;
  }

  public async get_stylist_items(item_idx: number) {
    const result: any = await this.apollo
      .query({
        query: get_stylist_items,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.get_stylist_items;
  }

  //전화주문 요청
  public async insert_order_call(item_idx: number, ref_post_idx: number) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_order_call,
        variables: { item_idx, ref_post_idx }
      })
      .toPromise();
    return result.data.insert_order_call;
  }

  //좋아요 리스트 가져오기
  public async is_follow(to_user_idx: number): Promise<boolean> {
    const result: any = await this.apollo
      .query({
        query: is_follow,
        variables: { to_user_idx }
      })
      .toPromise();
    return result.data.is_follow;
  }

  //좋아요 리스트 가져오기
  public async is_like(post_idx: number): Promise<boolean> {
    const result: any = await this.apollo
      .query({
        query: is_like,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.is_like;
  }

  //광고 프록시
  public async insert_ad_proxy(data: InputAdProxy) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_ad_proxy,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_ad_proxy;
  }

  //결제 방법 목록 가져오기
  public async select_payment_type_list(state: State): Promise<SelectPaymentListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_payment_type_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_payment_type_list;
  }

  //사용자 주소록 가져오기
  public async select_user_address_book_list(state: State): Promise<SelectAddressBookListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_user_address_book_list,
        variables: { state }
      })
      .toPromise();

    return result.data.select_user_address_book_list;
  }

  //주문등록
  public async insert_user_order_transaction_v2(data: InputUserOrder): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_user_order_transaction_v2,
        variables: { data }
      })
      .toPromise();

    return result.data.insert_user_order_transaction_v2;
  }

  public async insert_user_order_transaction_v3(data: InputUserOrder): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_user_order_transaction_v3,
        variables: { data }
      })
      .toPromise();

    return result.data.insert_user_order_transaction_v3;
  }

  public async check_pay_result(data: InputUserPaymentCallback): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .mutate({
        mutation: check_pay_result,
        variables: { data }
      })
      .toPromise();

    return result.data.check_pay_result;
  }

  public async order_select_order_transaction(data: InputUserPaymentCallback): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .query({
        query: order_select_order_transaction,
        variables: { data }
      })
      .toPromise();

    return result.data.order_select_order_transaction;
  }

  public async select_user_order_transaction_list(state: State): Promise<SelectOrderTransactionListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_user_order_transaction_list,
        variables: { state }
      })
      .toPromise();

    return result.data.select_user_order_transaction_list;
  }

  //아이템 정보 가져오기
  public async shop_select_all_shop_section(state): Promise<SelectShopSectionListReturn> {
    const result: any = await this.apollo
      .query({
        query: shop_select_all_shop_section,
        variables: { state }
      })
      .toPromise();
    return result.data.shop_select_all_shop_section;
  }

  public async shop_select_shop_section(section_idx: number): Promise<ShopSection> {
    const result: any = await this.apollo
      .query({
        query: shop_select_shop_section,
        variables: { shop_section_idx: section_idx }
      })
      .toPromise();
    return result.data.shop_select_shop_section;
  }

  public async select_main_topic(): Promise<Topic> {
    const result: any = await this.apollo
      .query({
        query: select_recent_topic
      })
      .toPromise();
    return result.data.select_recent_topic;
  }

  //장바구니 리스트 조회
  public async shop_select_user_cart_list(state: State): Promise<SelectShopCartListReturn> {
    const result: any = await this.apollo
      .query({
        query: shop_select_user_cart_list,
        variables: { state }
      })
      .toPromise();
    return result.data.shop_select_user_cart_list;
  }

  //장바구니 리스트 조회
  public async shop_select_user_cart_list_v2(state: State): Promise<SelectShopCartListReturn> {
    const result: any = await this.apollo
      .query({
        query: shop_select_user_cart_list_v2,
        variables: { state }
      })
      .toPromise();
    return result.data.shop_select_user_cart_list_v2;
  }

  public async select_order_transaction_pending(state: State): Promise<SelectOrderTransactionPendingListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_order_transaction_pending,
        variables: { state }
      })
      .toPromise();
    return result.data.select_order_transaction_pending;
  }

  public async shop_insert_user_cart(data: InputShopCart[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_user_cart,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_user_cart;
  }

  public async shop_insert_user_cart_v2(data: InputShopCart[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_user_cart_v2,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_user_cart_v2;
  }

  public async shop_update_user_cart(data: InputShopCart) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_update_user_cart,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_update_user_cart;
  }

  public async shop_delete_user_cart(data: InputShopCart[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_delete_user_cart,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_delete_user_cart;
  }

  public async shop_delete_user_cart_v2(data: InputShopCart[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_delete_user_cart_v2,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_delete_user_cart_v2;
  }

  //장바구니 리스트 조회
  public async shop_select_count_user_cart_list() {
    const result: any = await this.apollo
      .query({
        query: shop_select_count_user_cart_list,
        variables: {}
      })
      .toPromise();
    return result.data.shop_select_count_user_cart_list;
  }

  //login social

  //로그인하기
  public async login_social(login_type: string, social_access_token: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: login_social,
        variables: { login_type, social_access_token }
      })
      .toPromise();
    return result.data.login_social;
  }

  //로그인하기
  public async login_temp_user(login_type: string, social_access_token: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: login_temp_user,
        variables: { login_type, social_access_token }
      })
      .toPromise();
    return result.data.login_temp_user;
  }
  //로그인하기
  public async login_temp_user_to_user(login_type: string, social_access_token: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: login_temp_user_to_user,
        variables: { login_type, social_access_token }
      })
      .toPromise();
    return result.data.login_temp_user_to_user;
  }

  public async login_legacy_user_to_user() {
    const result: any = await this.apollo
      .mutate({
        mutation: login_legacy_user_to_user,
        variables: {}
      })
      .toPromise();
    return result.data.login_legacy_user_to_user;
  }

  //로그인하기
  public async login_social_with_status(login_type: string, is_app: boolean, temp_user_idx: number, social_access_token: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: login_social_with_status_v3,
        variables: {
          login_type,
          is_app,
          temp_user_idx,
          social_access_token
        }
      })
      .toPromise();
    return result.data.login_social_with_status_v3;
  }

  public async user_insert_error_log(data: string, type: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: user_insert_error_log,
        variables: { data, type }
      })
      .toPromise();
    return result.data.user_insert_error_log;
  }

  // user_select_my_data_v2
  public async user_select_my_data_v2() {
    const result: any = await this.apollo
      .query({
        query: user_select_my_data_v2,
        variables: {}
      })
      .toPromise();
    return result.data.user_select_my_data_v2;
  }

  public async user_select_order_transaction_list(state) {
    const result: any = await this.apollo
      .query({
        query: user_select_order_transaction_list,
        variables: { state }
      })
      .toPromise();
    return result.data.user_select_order_transaction_list;
  }

  public async user_select_order_transaction(order_transaction_idx): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .query({
        query: user_select_order_transaction,
        variables: { order_transaction_idx }
      })
      .toPromise();
    return result.data.user_select_order_transaction;
  }

  public async order_select_pending_order_transaction(order_transaction_idx): Promise<OrderTransaction> {
    const result: any = await this.apollo
      .query({
        query: order_select_pending_order_transaction,
        variables: { order_transaction_idx }
      })
      .toPromise();
    return result.data.order_select_pending_order_transaction;
  }

  public async user_select_all_post_list(state): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: user_select_all_post_list,
        variables: { state }
      })
      .toPromise();
    return result.data.user_select_all_post_list;
  }

  public async select_order_item_list(state): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: select_order_item_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_order_item_list;
  }

  public async select_item_review_list(state): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: select_item_review_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_item_review_list;
  }

  public async insert_review_old(post_data: InputPost, review_data: InputReview, post_resource_list: [InputPostResource]) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_review,
        variables: { post_data, review_data, post_resource_list }
      })
      .toPromise();
    return result.data.insert_review;
  }

  //파일 업로드
  public async insert_item_review(files: any[], post_data: InputPost, review_data: InputReview, file_type: string): Promise<Review> {
    const token = await this.storage.get('token');

    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_review,
        variables: { files, post_data, review_data, file_type },
        context: {
          hasUpload: true,
          headers: {
            token
          }
        }
      })
      .toPromise();
    return result.data.insert_item_review;
  }

  //파일 업로드
  public async update_item_review(files: any[], delete_image_list: any[], post_data: InputPost, review_data: InputReview, file_type: string): Promise<Review> {
    const token = await this.storage.get('token');

    const result: any = await this.apollo
      .mutate({
        mutation: update_item_review,
        variables: {
          files,
          delete_image_list,
          post_data,
          review_data,
          file_type
        },
        context: {
          hasUpload: true,
          headers: {
            token
          }
        }
      })
      .toPromise();
    return result.data.insert_item_review;
  }

  //파일 업로드 테스트
  public async file_upload(file: Upload, container: string, file_type: string): Promise<UploadFile> {
    console.log(file);
    const result: any = await this.apollo
      .mutate({
        mutation: file_upload,
        variables: { file, container, file_type },
        context: {
          hasUpload: true
        }
      })
      .toPromise();
    return result.data.file_upload;
  }

  public async point_select_point_rule(point_rule_idx): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: point_select_point_rule,
        variables: { point_rule_idx }
      })
      .toPromise();
    return result.data.point_select_point_rule;
  }

  public async select_order_item_can_review_list(state): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: select_order_item_can_review_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_order_item_can_review_list;
  }
  public async select_order_item_can_review_list_v2(state): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: select_order_item_can_review_list_v2,
        variables: { state }
      })
      .toPromise();
    return result.data.select_order_item_can_review_list_v2;
  }
  public async point_select_user_total(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: point_select_user_total,
        variables: {}
      })
      .toPromise();
    return result.data.point_select_user_total;
  }

  public async point_select_user_point_list(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: point_select_user_point_list,
        variables: {}
      })
      .toPromise();
    return result.data.point_select_user_point_list;
  }

  public async user_select_point_ready_list(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: user_select_point_ready_list,
        variables: {}
      })
      .toPromise();
    return result.data.user_select_point_ready_list;
  }
  public async user_select_point_list(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: user_select_point_list,
        variables: {}
      })
      .toPromise();
    return result.data.user_select_point_list;
  }
  // shop_select_shop_section_group
  public shop_select_shop_section_group(state: State, shop_section_group_type: string) {
    return this.apollo
      .query({
        query: shop_select_shop_section_group,
        variables: { state, shop_section_group_type }
      })
      .pipe(
        map((result: any) => {
          return result.data.shop_select_shop_section_group;
        })
      );
  }

  public async post_select_fumi_tv_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_fumi_tv_list,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_fumi_tv_list;
  }

  public async post_select_fumi_live_tv_list(state: State): Promise<SelectPostTvListReturn> {
    const result: any = await this.apollo
      .query({
        query: post_select_fumi_live_tv_list,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_fumi_live_tv_list;
  }

  public async post_select_fumi_live_tv_detail(post_idx: number): Promise<PostTv> {
    const result: any = await this.apollo
      .query({
        query: post_select_fumi_live_tv_detail,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.post_select_fumi_live_tv_detail;
  }

  public async tag_select_fumi_tv_post_by_tag_string(data: string[], state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: tag_select_fumi_tv_post_by_tag_string,
        variables: { data, state }
      })
      .toPromise();
    return result.data.tag_select_fumi_tv_post_by_tag_string;
  }

  public async tag_select_post_by_tag_string(data: string[], state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: tag_select_post_by_tag_string,
        variables: { data, state }
      })
      .toPromise();
    return result.data.tag_select_post_by_tag_string;
  }

  public async tag_count_post_by_tag_string(data: string[]): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: tag_count_post_by_tag_string,
        variables: { data }
      })
      .toPromise();
    return result.data.tag_count_post_by_tag_string;
  }

  public async post_select_fumi_tv(post_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_fumi_tv,
        variables: { post_idx }
      })
      .toPromise();
    return result.data.post_select_fumi_tv;
  }

  public async post_select_best_list_detail(post_idx: number, state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_best_list_detail,
        variables: { post_idx, state }
      })
      .toPromise();
    return result.data.post_select_best_list_detail;
  }

  public async post_select_best_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_best_list,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_best_list;
  }

  public async post_select_featured_content(idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_featured_content,
        variables: { idx }
      })
      .toPromise();
    return result.data.post_select_featured_content;
  }

  public async post_select_featured_content_list(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: post_select_featured_content_list,
        variables: { state }
      })
      .toPromise();
    return result.data.post_select_featured_content_list;
  }

  public async shop_select_category_list(state: State): Promise<SelectCategoryListReturn> {
    const result: any = await this.apollo
      .query({
        query: shop_select_category_list,
        variables: { state }
      })
      .toPromise();
    return result.data.shop_select_category_list;
  }

  public async shop_select_item_with_category(state: State, category_idx: number): Promise<SelectItemListReturn> {
    const result: any = await this.apollo
      .query({
        query: shop_select_item_with_category,
        variables: { category_idx, state }
      })
      .toPromise();
    return result.data.shop_select_item_with_category;
  }

  public async shop_search_item(state: State): Promise<SelectItemListReturn> {
    const result: any = await this.apollo
      .query({
        query: shop_search_item,
        variables: { state }
      })
      .toPromise();
    return result.data.shop_search_item;
  }

  public async payment_select_card_list(): Promise<SelectSubscribeCardInfoListReturn> {
    const result: any = await this.apollo
      .query({
        query: payment_select_card_list,
        variables: {}
      })
      .toPromise();
    return result.data.payment_select_card_list;
  }

  public async payment_issue_card(data: InputIssueBillingKeyInfo) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_issue_card,
        variables: { data }
      })
      .toPromise()
      .catch((error) => {
        return error;
      });

    return result;
  }

  public async payment_delete_card(data: InputSubscribeCardInfo) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_delete_card,
        variables: { data }
      })
      .toPromise();
    return result.data.payment_delete_card;
  }

  public async refresh_user_info() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_user_info,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_user_info;
  }

  public async payment_pay_billkey(data: InputIamportRequestBill) {
    const result: any = await this.apollo
      .mutate({
        mutation: payment_pay_billkey,
        variables: { data }
      })
      .toPromise()
      .catch((error) => {
        return error;
      });

    return result;
  }

  public async local_select_item_recent() {
    const result: any = await this.apollo
      .query({
        query: local_select_item_recent,
        variables: {},
        fetchPolicy: 'cache-first'
      })
      .toPromise();
    return result;
  }

  public async local_insert_item_recent(data: Item) {
    const result: any = await this.apollo
      .mutate({
        mutation: local_insert_item_recent,
        variables: { data }
      })
      .toPromise();

    return result;
  }

  public async user_select_user_info() {
    const result: any = await this.apollo
      .query({
        query: user_select_user_info,
        variables: {}
      })
      .toPromise();

    return result.data.user_select_user_info;
  }

  public async user_update_user_info(data: InputUserInfo) {
    const result: any = await this.apollo
      .mutate({
        mutation: user_update_user_info,
        variables: { data }
      })
      .toPromise();

    return result.data.user_update_user_info;
  }

  // 이벤트
  public async event_select_event_list_all(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: event_select_event_list_all,
        variables: { state }
      })
      .toPromise();
    return result.data.event_select_event_list_all;
  }

  // 이벤트
  public async event_select_event_list_detail(event_idx: number): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: event_select_event_list_detail,
        variables: { event_idx }
      })
      .toPromise();
    return result.data.event_select_event_list_detail;
  }

  // 컨텐츠 순서
  public async content_order_select_content_order_list(content_page: string): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: content_order_select_content_order_list,
        variables: { content_page }
      })
      .toPromise();
    return result.data.content_order_select_content_order_list;
  }

  // 컨텐츠 순서
  public async notice_dialog_select_notice_dialog(state: State): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: notice_dialog_select_notice_dialog,
        variables: { state }
      })
      .toPromise();
    return result.data.notice_dialog_select_notice_dialog;
  }

  public async coupon_select_coupon_list(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_list,
        variables: {}
      })
      .toPromise();
    return result.data.coupon_select_coupon_list;
  }

  public async coupon_select_issuable_all_coupon_list(): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_issuable_all_coupon_list,
        variables: {}
      })
      .toPromise();
    return result.data.coupon_select_issuable_all_coupon_list;
  }

  public async coupon_select_valid_coupon_list(item_option_list): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_valid_coupon_list,
        variables: { item_option_list }
      })
      .toPromise();
    return result.data.coupon_select_valid_coupon_list;
  }

  public async coupon_insert_coupon_list(coupon_rule_idx_list: any[]) {
    const result: any = await this.apollo
      .mutate({
        mutation: coupon_insert_coupon_list,
        variables: { coupon_rule_idx_list }
      })
      .toPromise();

    return result.data.coupon_insert_coupon_list;
  }

  public async coupon_insert_coupon_by_code(coupon_code: string) {
    const result: any = await this.apollo
      .mutate({
        mutation: coupon_insert_coupon_by_code,
        variables: { coupon_code }
      })
      .toPromise();

    return result.data.coupon_insert_coupon_by_code;
  }

  public async coupon_select_coupon_usable_item_list(state, coupon_rule_idx): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_coupon_usable_item_list,
        variables: { state, coupon_rule_idx }
      })
      .toPromise();
    return result.data.coupon_select_coupon_usable_item_list;
  }

  public async coupon_select_issuable_item_coupon_list(item_idx): Promise<any> {
    const result: any = await this.apollo
      .query({
        query: coupon_select_issuable_item_coupon_list,
        variables: { item_idx }
      })
      .toPromise();
    return result.data.coupon_select_issuable_item_coupon_list;
  }

  public async post_select_post_detail_list(post_idx: number, state: State): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: post_select_post_detail_list,
        variables: { post_idx, state }
      })
      .toPromise();
    return result.data.post_select_post_detail_list;
  }

  public async post_select_post_detail_list_profile(post_idx: number, state: State): Promise<SelectPostListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: post_select_post_detail_list_profile,
        variables: { post_idx, state }
      })
      .toPromise();
    return result.data.post_select_post_detail_list_profile;
  }

  public async get_post_similar_items(post_idx_list: string): Promise<PostRecommendItems> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_post_similar_items,
        variables: { post_idx_list }
      })
      .toPromise();
    return result.data.get_post_similar_items;
  }

  public async insert_shop_wish(item_idx: number): Promise<ShopWish> {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_shop_wish,
        variables: { item_idx }
      })
      .toPromise();

    return result.data.insert_shop_wish;
  }

  public async delete_shop_wish(item_idx: number): Promise<ShopWish> {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_shop_wish,
        variables: { item_idx }
      })
      .toPromise();

    return result.data.delete_shop_wish;
  }

  public async select_shop_wish_list(state: State) {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_shop_wish_list,
        variables: { state }
      })
      .toPromise();
    return result.data.select_shop_wish_list;
  }

  public async select_category_item_list(category_idx: number, state: State): Promise<Item[]> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_category_item_list,
        variables: { category_idx, state }
      })
      .toPromise();
    return result.data.select_category_item_list;
  }

  public async get_brand_list(state: State): Promise<SelectBrandListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_brand_list,
        variables: { state }
      })
      .toPromise();
    return result.data.get_brand_list;
  }

  public async select_sorted_brand(): Promise<SelectBrandListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_sorted_brand,
        variables: {}
      })
      .toPromise();
    return result.data.select_sorted_brand;
  }

  public async select_brand_item_list(brand_idx: number, state: State): Promise<SelectItemListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_brand_item_list,
        variables: { brand_idx, state }
      })
      .toPromise();
    return result.data.select_brand_item_list;
  }

  public async local_select_shop_wish() {
    const result: any = await this.apollo
      .query({
        query: local_select_shop_wish,
        variables: {},
        fetchPolicy: 'cache-first'
      })
      .toPromise();
    return result.data.shop_wish_list;
  }
  public async select_item_list_by_idx_list(item_idx_list: number[]): Promise<Item[]> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_item_list_by_idx_list,
        variables: { item_idx_list }
      })
      .toPromise();
    return result.data.select_item_list_by_idx_list;
  }

  public async insert_bulk_shop_wish(item_idx_list: number[]): Promise<boolean> {
    const result: any = await this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: insert_bulk_shop_wish,
        variables: { item_idx_list }
      })
      .toPromise();
    return result.data.insert_bulk_shop_wish;
  }

  public async select_store_list(state: State, type: string): Promise<SelectStoreListReturn> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_store_list,
        variables: { state, type }
      })
      .toPromise();
    return result.data.select_store_list;
  }

  public async select_store(store_idx: number): Promise<Store> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_store,
        variables: { store_idx }
      })
      .toPromise();
    return result.data.select_store;
  }

  public async get_ab_test(experiment_name: string, temp_user_idx: number): Promise<Store> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_ab_test,
        variables: { experiment_name, temp_user_idx }
      })
      .toPromise();
    return result.data.get_ab_test;
  }

  public async shop_select_hotdeal_section(): Promise<ShopSection> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: shop_select_hotdeal_section
      })
      .toPromise();
    return result.data.shop_select_hotdeal_section;
  }

  public async upsert_user_refund_account(data: InputUserRefundAccount): Promise<UserRefundAccount> {
    const result: any = await this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: upsert_user_refund_account,
        variables: { data }
      })
      .toPromise();
    return result.data.upsert_user_refund_account;
  }

  public async select_user_refund_account(): Promise<UserRefundAccount> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_user_refund_account,
        variables: {}
      })
      .toPromise();
    return result.data.select_user_refund_account;
  }

  public async insert_post_item_view_history(item_idx: number, user_idx: number): Promise<any> {
    const result: any = await this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: insert_post_item_view_history,
        variables: { item_idx, user_idx }
      })
      .toPromise();
    return result;
  }

  public user_select_onesignal() {
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: user_select_onesignal,
        variables: {}
      })
      .pipe(
        map((result: any) => {
          return result.data.user_select_onesignal;
        })
      );
  }

  public async insert_chat_room(data: InputChatRoom) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_chat_room,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_chat_room;
  }

  public async insert_chat_message(data: InputChatMessage) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_chat_message,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_chat_message;
  }

  public async after_insert_chat_message(data: InputChatRoom) {
    const result: any = await this.apollo
      .mutate({
        mutation: after_insert_chat_message,
        variables: { data }
      })
      .toPromise();
    return result.data.after_insert_chat_message;
  }

  public async get_chat_join(chat_room_key: string): Promise<ChatJoin> {
    const result: any = await this.apollo
      .query({
        query: get_chat_join,
        variables: { chat_room_key }
      })
      .toPromise();
    return result.data.get_chat_join;
  }

  public async get_chat_token(): Promise<string> {
    const result: any = await this.apollo
      .mutate({
        mutation: get_chat_token
      })
      .toPromise();
    return result.data.get_chat_token;
  }

  public async select_chat_join(state: State): Promise<SelectChatJoinListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_chat_join,
        variables: { state }
      })
      .toPromise();
    return result.data.select_chat_join;
  }

  public async select_chat_message(chat_room_idx: number, state: State): Promise<SelectChatMessageListReturn> {
    const result: any = await this.apollo
      .query({
        query: select_chat_message,
        variables: { chat_room_idx, state }
      })
      .toPromise();
    return result.data.select_chat_message;
  }

  public async insert_follow(data: InputFollow) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_follow,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_follow;
  }

  public async delete_follow(data: InputFollow) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_follow,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_follow;
  }

  public async insert_like(data: InputLike) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_like,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_like;
  }

  public async delete_like(data: InputLike) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_like,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_like;
  }

  public async select_user_like_list(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_user_like_list,
        variables: { state }
      })
      .toPromise();

    return result.data.select_user_like_list;
  }

  public async select_shop_wish_list_all(state: State) {
    const result: any = await this.apollo
      .query({
        query: select_shop_wish_list_all,
        variables: { state }
      })
      .toPromise();
    return result.data.select_shop_wish_list_all;
  }

  public async insert_post_tv_like(data: InputPostTvLike) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_post_tv_like,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_post_tv_like;
  }

  public async delete_post_tv_like(data: InputPostTvLike) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_post_tv_like,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_post_tv_like;
  }

  public async select_post_tv_like_list() {
    const result: any = await this.apollo
      .query({
        query: select_post_tv_like_list,
        variables: {}
      })
      .toPromise();

    return result.data.select_post_tv_like_list;
  }

  //댓글 추가하기
  public async insert_item_view(data: InputUserItemView) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_view,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_item_view;
  }
  public async delete_item_view(data: InputUserItemView) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_item_view,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_item_view;
  }

  public async shop_insert_order_transaction_pending(data: InputOrderTransactionPending) {
    const result: any = await this.apollo
      .mutate({
        mutation: shop_insert_order_transaction_pending,
        variables: { data }
      })
      .toPromise();
    return result.data.shop_insert_order_transaction_pending;
  }

  public async delete_all_item_view() {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_all_item_view,
        variables: {}
      })
      .toPromise();
    return result.data.delete_all_item_view;
  }

  public async select_user_item_view() {
    const result: any = await this.apollo
      .query({
        query: select_user_item_view
      })
      .toPromise();

    return result.data.select_user_item_view;
  }

  public async insert_user_device(data: InputUserDevice) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_user_device,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_user_device;
  }

  public async insert_order_refer(data: InputOrderRefer) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_order_refer,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_order_refer;
  }

  public async insert_user_invite_history(data: InputUserInviteHistory) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_user_invite_history,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_user_invite_history;
  }

  public async insert_onelink(data: InputOnelink) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_onelink,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_onelink;
  }

  public async insert_user_device_player_id(data: InputUserDevice) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_user_device_player_id,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_user_device_player_id;
  }

  public async insert_item_report(data: InputItemReport) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_item_report,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_item_report;
  }

  //상품 정보 가져오기
  public async get_onelink(onelink_idx: number) {
    const result: any = await this.apollo
      .query({
        query: get_onelink,
        variables: { onelink_idx }
      })
      .toPromise();
    return result.data.get_onelink;
  }

  public async insert_user_attendance(data: InputUserAttendance) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_user_attendance,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_user_attendance;
  }

  //좋아요 리스트 가져오기
  public async select_item_list_not_viewed(state: State) {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: select_item_list_not_viewed,
        variables: { state }
      })
      .toPromise();

    return result.data.select_item_list_not_viewed;
  }

  public async update_order_item_complete(data: InputOrderItem) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_order_item_complete,
        variables: { data }
      })
      .toPromise();
    return result.data.update_order_item_complete;
  }

  //리뷰 항목 리프레시
  public async refresh_order_item_can_review_list() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_order_item_can_review_list,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_order_item_can_review_list;
  }

  public async refresh_order_transaction_list() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_order_transaction_list,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_order_transaction_list;
  }

  public async get_fumist_idx(): Promise<number> {
    const result: any = await this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: get_fumist_idx,
        variables: {}
      })
      .toPromise();
    console.log('get value = ', result);
    return result.data.get_fumist_idx;
  }

  public async refresh_class_room() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_room,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_class_room;
  }

  public async refresh_class_room_join() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_room_join,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_class_room_join;
  }

  public async refresh_class_room_post() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_room_post,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_class_room_post;
  }

  public async refresh_class_room_shop_section() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_room_shop_section,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_class_room_shop_section;
  }

  public async refresh_class_room_shop_section_force(data: InputClassRoomShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_room_shop_section_force,
        variables: { data }
      })
      .toPromise();
    return result.data.refresh_class_room_shop_section_force;
  }

  public async refresh_class_customer_order_item() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_customer_order_item,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_class_customer_order_item;
  }

  public async refresh_class_room_point() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_room_point,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_class_room_point;
  }

  public async refresh_class_join(data: InputClassJoin) {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_class_join,
        variables: { data }
      })
      .toPromise();
    return result.data.refresh_class_join;
  }

  //댓글 추가하기
  public async update_class_room(data: InputClassRoom) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_class_room,
        variables: { data }
      })
      .toPromise();
    return result.data.update_class_room;
  }

  public async update_class_room_post_list(data: InputClassRoomPostList) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_class_room_post_list,
        variables: { data }
      })
      .toPromise();
    return result.data.update_class_room_post_list;
  }

  public async update_class_room_shop_section_list(data: InputClassRoomShopSectionList) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_class_room_shop_section_list,
        variables: { data }
      })
      .toPromise();
    return result.data.update_class_room_shop_section_list;
  }

  public async update_class_room_shop_section(data: InputShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_class_room_shop_section,
        variables: { data }
      })
      .toPromise();
    return result.data.update_class_room_shop_section;
  }

  public async delete_class_room_shop_section(data: InputShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_class_room_shop_section,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_class_room_shop_section;
  }

  public async update_shop_section(data: InputShopSection) {
    const result: any = await this.apollo
      .mutate({
        mutation: update_shop_section,
        variables: { data }
      })
      .toPromise();
    return result.data.update_shop_section;
  }

  public async insert_class_room_point_to_user(data: InputClassRoomPoint) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_class_room_point_to_user,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_class_room_point_to_user;
  }

  public async refresh_user_point_list() {
    const result: any = await this.apollo
      .mutate({
        mutation: refresh_user_point_list,
        variables: {}
      })
      .toPromise();
    return result.data.refresh_user_point_list;
  }

  public async insert_class_join(data: InputClassJoin) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_class_join,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_class_join;
  }

  public async delete_class_join(data: InputClassJoin) {
    const result: any = await this.apollo
      .mutate({
        mutation: delete_class_join,
        variables: { data }
      })
      .toPromise();
    return result.data.delete_class_join;
  }

  //전체 주문 취소 요청
  public async insert_order_cancel_all(data: InputOrderCancel) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_order_cancel_all,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_order_cancel_all;
  }

  //주문 취소 요청
  public async insert_order_cancel(data: InputOrderCancel) {
    const result: any = await this.apollo
      .mutate({
        mutation: insert_order_cancel,
        variables: { data }
      })
      .toPromise();
    return result.data.insert_order_call;
  }

  /**
   * api 프록시 함수
   * @param data
   * @returns
   */
  public async api(api: string, param: any) {
    const callable = httpsCallable(this.fns, 'api_proxy');
    const result: any = await callable({ api, param });
    return result.data;
  }

  public async nest(api: string, param: any) {
    const callable = httpsCallable(this.fns, 'api_nest_proxy');
    const result: any = await callable({ api: `/api/v1${api}`, param });
    return result.data;
  }
}
