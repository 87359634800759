import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ModalController } from '@ionic/angular';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { ConstantService } from 'src/app/services/constants.service';
import { environment } from 'src/environments/environment';

import { Capacitor } from '@capacitor/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginModalComponent implements OnInit {
  @Input() from: string;
  processing = false;
  showAppleSignIn = false;
  return_url: string;
  loading = false;
  modal_text = {
    'item-detail': '상품 구매는 회원가입이 필요합니다',
    upload: '게시물 등록은 회원가입이 필요합니다',
    'post-read': '게시물 조회는 회원가입이 필요합니다',
    'event-coupon': '쿠폰 받기는 회원가입이 필요합니다',
    function: '회원가입이 필요합니다'
  };
  current_modal_text: string;
  point_amount = 200000;
  show_login_proxy = false;

  constructor(
    public app: AppService,
    private login: LoginService,

    public modalCtrl: ModalController,
    private amplitude: AmplitudeService,
    public constant: ConstantService
  ) {}

  ngOnInit() {
    this.showAppleSignIn = Capacitor.getPlatform() == 'ios';
    this.current_modal_text = this.modal_text[this.from];
    // this.point_amount = this.app.isApp() ? '2,000' : '1,000';

    this.amplitude.sendEvent(this.constant.amplitude_new.enter_signup, {
      from: this.from
    });
  }

  async clickLogin(login_type: string) {
    let social_access_token = null;
    try {
      social_access_token = await this.login.login(login_type);
    } catch (error) {
      alert('로그인 실패');
    }

    let login_result = false;
    if (social_access_token) {
      const temp = await this.app.showLoading('로그인중입니다');
      try {
        login_result = await this.app.loginSocial(login_type, social_access_token);
        if (login_result) {
          // this.app.broadcast.next({
          //   type: this.app.broadcast_message.REFRESH_USER_DATA,
          //   payload: null
          // });
          // this.app.getCartCount();
        }
      } catch (error) {
        alert('푸미 로그인 실패');
      }
      temp.dismiss();
    }

    this.processing = false;
    this.modalCtrl.dismiss(login_result);
  }

  clickCancel() {
    this.modalCtrl.dismiss(false);
  }
}
