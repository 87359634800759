import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanLoad, Route, UrlSegment } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate, CanLoad {
  constructor(public app: AppService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveCheck(state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveCheck(null);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveCheck(state.url);
  }

  // async canActiveCheck(return_url) {
  //   const user = await this.app.getCurrentUser();
  //   if (user && user.login_type != 'temp') {
  //     return true;
  //   } else {
  //     await this.app.routingGoLogin(return_url);
  //     return false;
  //   }
  // }

  async canActiveCheck(return_url) {
    const user = await this.app.getCurrentUser();

    if (user && (user.login_type == 'kakao' || user.login_type == 'apple')) {
      return true;
    } else {
      await this.app.routingGoLogin(return_url);
      return false;
    }
  }
}
