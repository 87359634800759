import { Component, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/graphql/data-provider.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { ConstantService } from 'src/app/services/constants.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-linkify',
  templateUrl: './linkify.component.html',
  styleUrls: ['./linkify.component.scss']
})
export class LinkifyComponent implements OnInit {
  keyword: string;
  kind: string;

  sub_list: Subscription[] = [];
  constructor(public db: DataProviderService, public app: AppService, public route: ActivatedRoute, public router: Router, private amplitude: AmplitudeService, public constant: ConstantService) {}

  async ngOnInit() {
    this.sub_list.push(
      this.route.queryParamMap.subscribe(async (data: any) => {
        this.kind = data.params['kind'];
        this.keyword = data.params['keyword'];
        this.serachAndGoUser();
      })
    );
  }
  options: AnimationOptions = {
    path: '/assets/animation/loading.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  ngOnDestroy() {
    this.sub_list.forEach((item) => {
      item.unsubscribe();
    });
    this.sub_list = [];
  }
  async serachAndGoUser() {
    alert('serachAndGoUser:' + this.keyword + ', kind:' + this.kind);
    if (this.kind == 'user') {
      const temp = await this.db.select_user_list({
        skip: 0,
        take: 1,
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'user_name',
              operator: 'eq',
              value: this.keyword.trim()
            },
            {
              field: 'login_type',
              operator: 'eq',
              value: 'kakao'
            }
          ]
        }
      });

      if (temp.count == 0) {
        alert('사용자를 찾을 수 없습니다');
        this.app.goBack();
      } else {
        this.goDetailUser(temp.rows[0].user_idx);
      }
    } else {
      this.goDetailTag(this.keyword);
    }
  }

  goDetailUser(user_idx: number) {
    this.amplitude.sendEvent(this.constant.amplitude_new.click_tag, {
      from: this.amplitude.current_where,
      tag: 'user'
    });
    this.app.justGo(`user/profile/${user_idx}`);
  }
  goDetailTag(tag_string: string) {
    this.amplitude.sendEvent(this.constant.amplitude_new.click_tag, {
      from: this.amplitude.current_where,
      tag: tag_string
    });

    alert('will go:' + tag_string);
    setTimeout(() => {
      this.app.justGo('style/search-tag-post', {
        tag_string
      });
    }, 500);
  }
}
