<!-- <ion-header [translucent]="false">
  <ion-toolbar>
    <ion-title> 품위있는 쇼핑-FUMI</ion-title>
  </ion-toolbar>
</ion-header> -->
<ion-content>
  <div class="parent login-bg">
    <div class="child">
      <div class="center-text" (click)="clickTest()">
        <span class="fm-h3-b fm-color-grey-7"
          >쇼핑에<br />
          품위를 더하면</span
        >
      </div>
      <div class="center-text">
        <br />
        <img style="width: 80px" src="https://fumi-cdn.fumi.co.kr/resource/logo_black.png/360x" />
        <!-- <span class="fm-h1-b fm-color-grey-9">FUMI</span> -->
      </div>
    </div>
  </div>

  <!-- <br /><br />
  <br /><br />
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title>FUMI</ion-title>
    </ion-toolbar>
  </ion-header>

  <br /> -->
  <!-- <ion-item lines="none">
    <ion-label size="large">
     
    </ion-label>
  </ion-item> -->

  <!-- <div>
    <h1>언제나 품위있는 쇼핑</h1>
    <br />
    <p>푸미에서<br />새로운 <span style="font-weight: 600; color: black">네트워크쇼핑</span>을 경험하세요</p>
  </div> -->

  <!-- <div [style.width.px]="app.size.max_width" [style.height.px]="app.size.height">
    <user-network></user-network>
  </div> -->

  <!-- <div [style.width.px]="app.size.max_width" [style.height.px]="app.size.max_width">
    <ion-img [src]="'https://fumi-cdn.fumi.co.kr/upload/2022_01_08_124846840_98441fb857b84cef8eae12266ecf32be.jpg/' + app.size.max_width + 'x' + app.size.max_width"></ion-img>
  </div> -->

  <!-- <ion-item lines="full">
    <ion-label size="large">
      <p>2022.01.07 베스트리뷰 (배동환님)</p>
    </ion-label>
  </ion-item> -->

  <!-- <ion-item lines="none" class="ion-margin-top">
    <ion-label size="large">
      <h1>푸미 네트워크</h1>
      <p>푸미에서만 연결되는 푸미네트워크에 연결해보세요</p>
    </ion-label>
  </ion-item> -->

  <!-- <div class="content-container scroll-content">
    <div class="content-login-container">
      <div class="content-login-container-content">
        <ion-text>
          <h3>품위있는 네트워크 쇼핑<br />FUMI</h3>
        </ion-text>
      </div>
    </div>
  </div> -->
</ion-content>
<ion-footer no-border transparent class="login-bg">
  <div class="login-container">
    <div class="login-container-content ion-margin-bottom">
      <div class="login-button-container child-animation">
        <div class="arrow_box" style="padding-top: 6px">
          <span>
            <ion-text color="primary" style="margin-right: 10px">가입 즉시</ion-text>
            <ion-text color="secondary" style="margin-right: 10px">20만원 쇼핑 쿠폰팩</ion-text>
            <ion-text color="primary">지급!</ion-text>
          </span>
        </div>
      </div>
      <ion-button
        [disabled]="processing"
        style="--background: transparent; height: 48px; --border-radius: 10px; margin-bottom: 10px"
        class="ion-margin"
        color="warning"
        expand="block"
        size="large"
        (click)="clickLogin('kakao')"
      >
        <ion-icon slot="start" class="login-button-icon" src="assets/img/login/kakaotalk_logo.svg"></ion-icon>
        <span style="font-weight: bold; letter-spacing: -0.012em; font-size: var(--font-base-1)">카카오로 1초만에 시작</span>
      </ion-button>

      <ion-button
        [disabled]="processing"
        [hidden]="!showAppleSignIn"
        style="--background: black; height: 48px; --border-radius: 10px; margin-top: 10px"
        class="ion-margin"
        expand="block"
        size="large"
        (click)="clickLogin('apple')"
      >
        <ion-icon slot="start" class="login-button-icon" src="assets/img/login/apple_logo.svg"></ion-icon>
        <span style="font-weight: bold; font-size: var(--font-base-1)">Apple로 계속하기</span>
      </ion-button>

      <ion-button
        [hidden]="!showNoLogin"
        style="--background: transparent; height: 30px; --border-radius: 15px; margin-bottom: 10px"
        class="ion-margin-bottom"
        color="primary"
        size="small"
        (click)="clickNotLogin()"
      >
        <span style="letter-spacing: -0.012em; font-size: 15px">혜택없이 둘러보기</span>
      </ion-button>
    </div>
  </div>
  <!-- <div style="height: 10vh">
    <h1 style="opacity: 0">1</h1>
  </div> -->

  <!-- <div class="ion-margin">
    <info-footer class="ion-margin-top"></info-footer>
  </div> -->

  <div style="height: 2vh">
    <h1 style="opacity: 0">1</h1>
  </div>
</ion-footer>
